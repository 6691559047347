import { extendTheme } from '@chakra-ui/react';
import Button from './components/button';
import Input from './components/input';
import colors from './colors';

import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

const theme = extendTheme({
  colors,
  fonts: {
    heading: 'Inter',
    body: 'Inter',
    mono: 'Inter',
  },
  components: {
    Input,
    Button,
    Steps,
  },
});

export default theme;
