import React from 'react';
import * as yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Stack, Button, Text } from '@chakra-ui/react';
import ExternalWrapper from './ExternalWrapper';
import VerificationFormSection, {
  verificationFormFieldsValidation,
} from './VerificationFormSection';
import { EXTERNAL_DATA_URL, LOG_IN_URL } from '../../constants/routerUrl';
import { useYupValidationResolver } from '../../util/yupUtils';
import { requestAgentVerification } from '../../api/external';

const VerificationRequestForm = () => {
  const history = useHistory();

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...verificationFormFieldsValidation,
    }),
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    const res = await requestAgentVerification(data);
    if (res.data?.status === 200) {
      history.replace({ pathname: EXTERNAL_DATA_URL, state: res.data });
    }
  };

  return (
    <ExternalWrapper>
      <Stack
        as="form"
        minW="380px"
        flexDir="column"
        spacing={4}
        p={12}
        boxShadow="md"
        borderRadius="4px"
        backgroundColor="white"
        onSubmit={handleSubmit(onSubmit)}>
        <VerificationFormSection register={register} control={control} errors={errors} />
        <Stack flexDir="column" alignItems="center" px={3} pt={3}>
          <Button
            h="5vh"
            minH="35px"
            maxH="45px"
            width="full"
            type="submit"
            variant="solid"
            borderRadius={2}
            isLoading={isSubmitting}>
            SUBMIT
          </Button>
          <Link to={LOG_IN_URL}>
            <Text color="primary.700" mt={2}>
              Back to Login
            </Text>
          </Link>
        </Stack>
      </Stack>
    </ExternalWrapper>
  );
};

export default VerificationRequestForm;
