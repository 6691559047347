import React from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react';
import Select from 'react-select';

const SelectCerProcSection = ({ setCertificate, error }) => {
  const certificates = useSelector((state) => state.certificate.unusedProcCertificates);

  const handleChange = (data) => {
    if (data) {
      setCertificate(data);
    } else {
      setCertificate();
    }
  };

  return (
    <Box>
      <HStack spacing={3} mb={4}>
        <FormControl isInvalid={error}>
          <FormLabel mb={1.5} fontSize="md">
            <b style={{ color: '#E53E3E' }}>* </b>Certificate No.
          </FormLabel>
          {/* <Select
            colorScheme="primary"
            focusBorderColor="primary.300"
            h="50px"
            w="sm"
            borderRadius="4px"
            bg="formInputBG"
            placeholder="Select an option"
            onChange={(e) => handleChange(e.target.value)}>
            {certificates?.map((item) => (
              <option key={item?.certID} value={item?.certID}>
                {item?.certNum}
              </option>
            ))}
          </Select> */}
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? '#674dfe' : 'gray.300',
                height: '50px',
                borderRadius: '4px',
                backgroundColor: '#F8F8F8',
                width: '24rem',
              }),
            }}
            placeholder="Select an option"
            onChange={(value) => handleChange(value)}
            options={certificates}
            getOptionLabel={(option) => `${option?.certNum}`}
            getOptionValue={(option) => option?.certID}
          />
        </FormControl>
      </HStack>
      <Text color="invalid" fontSize="15px" mt="6px">
        {error}
      </Text>
    </Box>
  );
};

export default SelectCerProcSection;
