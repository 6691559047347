import { Box } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import DataTable from '../../../common/certificateComponents/DataTable';
import CertificateBill from '../../../common/certificateComponents/CertificateBill';

import { useDispatch, useSelector } from 'react-redux';
import { renderDate } from '../../../../util/formatUtils';
import { getDutyCertHistory } from '../../../../api/certificate';
import { LOGS_TABLE_DISPLAY_SIZE } from '../../../../constants/certificateConstant';
import { BADGE_STATE_COLORS } from '../../../../constants/dataTableConstants';
import { FETCH_DUTY_CERTIFICATE_ALL } from '../../../../store/actions/actionTypes';

//configure columns
const columns = [
  {
    header: 'Invoice',
    accessor: 'orderId',
  },
  {
    header: 'Date',
    accessor: 'createdAt',
    isDate: true,
  },
  {
    header: 'Certificate No',
    accessor: 'certificateNum',
  },
  {
    header: 'Quantity',
    accessor: 'noOfCopies',
  },
  {
    header: 'Total',
    accessor: 'total',
    currency: '৳',
  },
  {
    header: 'Status',
    accessor: 'status',
    isBadge: true,
    colorScheme: BADGE_STATE_COLORS,
    hoverInfo: ['paymentMethod', 'chequeNo as ID', 'chequeDated as Date', 'remarks'],
  },
  {
    header: 'Bill',
    accessor: 'status',
    isButton: true,
    actionScheme: [
      { group: 'PAID', variant: 'print', action: 'PRINT' },
      { group: 'UNPAID', variant: 'print', action: 'PRINT' },
    ],
  },
];

const PurchaseHistoryTable = () => {
  const dispatch = useDispatch();
  const certificateBillRef = useRef();
  const [billData, setBillData] = useState();
  const [costData, setCostData] = useState();
  const mill = useSelector((state) => state.mill.mill);
  const data = useSelector((state) => state.certificate.dutyCertData);

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_DUTY_CERTIFICATE_ALL, payload: null });
    const params = { page, pageSize, searchKey, startDate, endDate };
    const res = await getDutyCertHistory(params);
    if (res.data) {
      dispatch({ type: FETCH_DUTY_CERTIFICATE_ALL, payload: res.data });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_DUTY_CERTIFICATE_ALL, payload: null });
    const params = { searchKey, startDate, endDate };
    const res = await getDutyCertHistory(params);
    if (res.data) {
      dispatch({ type: FETCH_DUTY_CERTIFICATE_ALL, payload: res.data });
    }
  };

  const printBill = useReactToPrint({
    documentTitle: `BTMA-Bill-Processing-Certificate-${billData?.billNo}-${renderDate(new Date())}`,
    content: () => certificateBillRef.current,
  });

  const handlePrint = ({ rowObject }) => {
    setBillData({
      billNo: rowObject?.orderId,
      millName: mill?.millName,
      millAddress: mill?.hoInfo?.hoName,
    });
    setCostData({
      certOf: 'duty',
      noOfCopies: rowObject?.noOfCopies,
      unitCost: rowObject?.unitCost,
      total: rowObject?.total,
      range: rowObject?.certificateNum,
    });
    setTimeout(() => {
      printBill();
    }, 500);
  };

  const actionObject = { PRINT: handlePrint };

  return (
    <Box mt={6}>
      <DataTable
        caption="Application Fees"
        columns={columns}
        data={data?.purchaseHistory}
        actions={actionObject}
        topPanel={true}
        isPaginated
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        entries={data?.totalEntries}
        currentPage={data?.page}
        currentPageSize={data?.pageSize}
        keySearch
        dateRange
        paginate={handlePagination}
        search={handleSearch}
      />
      <Box display="none">
        <CertificateBill
          ref={certificateBillRef}
          title="Certificate"
          data={billData}
          costs={costData}
          isFromBlankCert={true}
        />
      </Box>
    </Box>
  );
};

export default PurchaseHistoryTable;
