import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HStack, Stack, PinInputField, PinInput, Text, Button } from '@chakra-ui/react';

import AuthWrapper from './AuthWrapper';
import { useDispatch } from 'react-redux';
import { LOG_IN_URL } from '../../constants/routerUrl';
import { SUCCESS } from '../../constants/toastConstants';
import { notify } from '../../store/actions/globalActions';
import { OTP_VALIDITY_TIME_IN_SECONDS } from '../../constants/authConstant';
import { accountVerificationOtp, resendVerificationOtp } from '../../api/auth';

const AccountVerficationOTP = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  const handleChange = (value) => {
    setValue(value);
  };

  const handleComplete = async (otp) => {
    const data = {
      username: location.state.username,
      phone: location.state.phone,
      otp: otp,
    };
    try {
      const res = await accountVerificationOtp(data);
      if (res.status === 200) {
        dispatch(
          notify({
            title: 'Success!',
            status: SUCCESS,
            description: 'Account Verified! Please Log In.',
          }),
        );
        history.replace(LOG_IN_URL);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // timer stuff start
  const [[minutes, seconds], setTime] = useState([
    parseInt(OTP_VALIDITY_TIME_IN_SECONDS / 60),
    OTP_VALIDITY_TIME_IN_SECONDS % 60,
  ]);
  const [otpValidity, setOtpValidity] = useState(true);

  const tickTimer = () => {
    if (minutes === 0 && seconds === 0) {
      setOtpValidity(false);
    } else if (seconds === 0) {
      setTime([minutes - 1, 59]);
    } else {
      setTime([minutes, seconds - 1]);
    }
  };

  const resetTimer = () =>
    setTime([parseInt(OTP_VALIDITY_TIME_IN_SECONDS / 60), OTP_VALIDITY_TIME_IN_SECONDS % 60]);

  useEffect(() => {
    const timerId = setInterval(() => tickTimer(), 1000);
    return () => clearInterval(timerId);
  });
  // timer stuff end

  const handleResendOTP = async () => {
    const data = {
      username: location.state.username,
      phone: location.state.phone,
    };
    try {
      const res = await resendVerificationOtp(data);
      if (res.status === 200) {
        resetTimer();
        setOtpValidity(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthWrapper>
      <Stack spacing={4} p={10} backgroundColor="white" boxShadow="md" borderRadius="md">
        {otpValidity && (
          <>
            <Text fontSize="xl" textAlign="center">
              Enter the OTP sent to {location.state.phone}
            </Text>

            <HStack justify="center">
              <PinInput
                otp
                autoFocus
                value={value}
                onChange={handleChange}
                onComplete={handleComplete}
                focusBorderColor="primary.300"
                type="alphanumeric"
                size="lg">
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
              </PinInput>
            </HStack>
          </>
        )}
        <HStack justify="center">
          {otpValidity ? (
            <Text fontSize="2xl">
              {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
            </Text>
          ) : (
            <Stack>
              <Text fontSize="xl" textAlign="center">
                OTP Expired!
              </Text>
              <Button onClick={handleResendOTP}>Resend OTP</Button>
            </Stack>
          )}
        </HStack>
      </Stack>
    </AuthWrapper>
  );
};

export default AccountVerficationOTP;
