import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'masterLc',
    type: 'text',
    isRequired: true,
    label: 'Export L/C No.',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'dateOfLc',
    isRequired: true,
    label: 'Date of L/C',
  },
  {
    colSpan: 1,
    name: 'totalQuantity',
    type: 'text',
    isRequired: true,
    placeholder: 'As per LC/Contract',
    label: 'Total Quantity & Item',
  },
  {
    colSpan: 1,
    name: 'valueInUSD',
    type: 'text',
    isRequired: true,
    label: 'Value (In US$)',
  },
  {
    colSpan: 1,
    name: 'btbLc',
    type: 'text',
    isRequired: true,
    label: 'Back to Back L/C No.',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'btbLcDate',
    isRequired: true,
    label: 'Date of Back to Back L/C',
  },
  {
    colSpan: 1,
    name: 'exciseGatePassNum',
    type: 'text',
    isRequired: true,
    label: 'Vat Challan No.',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'exciseGatePassDate',
    isRequired: true,
    label: 'Vat Challan Date',
  },
  {
    colSpan: 1,
    name: 'bank',
    type: 'text',
    isRequired: true,
    label: 'Name of L/C Opening Bank',
  },
  {
    colSpan: 1,
    name: 'bankBranch',
    type: 'text',
    isRequired: true,
    label: 'Bank Branch',
  },
];

export const generalInformationValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: dateValidation.required().label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
  [fields[3].name]: yup
    .number()
    .typeError(`${fields[3].label} must be a number`)
    .required()
    .label(fields[3].label),
  [fields[4].name]: yup.string().required().label(fields[4].label),
  [fields[5].name]: dateValidation.required().label(fields[5].label),
  [fields[6].name]: yup.string().required().label(fields[6].label),
  [fields[7].name]: dateValidation.required().label(fields[7].label),
  [fields[8].name]: yup.string().required().label(fields[8].label),
  [fields[9].name]: yup.string().required().label(fields[9].label),
};

const CerGeneralInformation = (props) => {
  return (
    <FormSection
      sectionName="General Information"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default CerGeneralInformation;
