import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'masterLc',
    type: 'text',
    label: 'Export L/C No.',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'dateOfLc',
    label: 'Date of L/C',
    isRequired: true,
  },

  {
    colSpan: 1,
    name: 'totalQuantity',
    type: 'text',
    placeholder: 'As per LC/Contract',
    label: 'Total Quantity & Items',
    isRequired: true,
  },
];

export const cerGenInfoSectionValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: dateValidation.required().label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
};

const CerGeneralInformation = (props) => {
  return (
    <FormSection
      secondary
      sectionName="General Information"
      sectionColumn={3}
      formFields={fields}
      {...props}
    />
  );
};

export default CerGeneralInformation;
