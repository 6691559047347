import React, { useState, useEffect } from 'react';

import { Box, Stack, Grid, Text, Button } from '@chakra-ui/react';

import { getCurrentUser } from '../../util/localStorageUtils';

import { useHistory } from 'react-router-dom';
import { PASSWORD_RESET_URL } from '../../constants/routerUrl';

function Settings() {
  const [userInfo, setUserInfo] = useState();
  const [startTime, setStartTime] = useState();
  const history = useHistory();
  const [elapsedTime, setElapsedTime] = useState('00:00');

  const getElapsedTime = (startTime) => {
    let endTime = new Date();
    let timeDiff = endTime.getTime() - startTime.getTime();
    timeDiff = timeDiff / 1000;
    let seconds = Math.floor(timeDiff % 60);
    let secondsAsString = seconds < 10 ? '0' + seconds : seconds;
    timeDiff = Math.floor(timeDiff / 60);
    let minutes = timeDiff % 60;
    let minutesAsString = minutes < 10 ? '0' + minutes : minutes;
    timeDiff = Math.floor(timeDiff / 60);
    let hours = timeDiff % 24;
    timeDiff = Math.floor(timeDiff / 24);
    let days = timeDiff;
    let totalHours = hours + days * 24;
    let totalHoursAsString = totalHours < 10 ? '0' + totalHours : totalHours;
    if (totalHoursAsString === '00') {
      setElapsedTime(minutesAsString + ':' + secondsAsString);
    } else {
      setElapsedTime(totalHoursAsString + ':' + minutesAsString + ':' + secondsAsString);
    }
  };

  const startCounter = () => {
    if (startTime) getElapsedTime(startTime);
  };

  setInterval(startCounter, 1000);

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  useEffect(() => {
    const data = getCurrentUser();
    if (data) {
      setStartTime(new Date(data.lastLogin));
      const basicInformationFields = [
        { header: 'User Name', value: data.fullName },
        { header: 'Last Login', value: formatTime(new Date(data.lastLogin)) },
        { header: 'Time Elapsed This Session', value: elapsedTime },
      ];
      setUserInfo(basicInformationFields);
    }
  }, []);

  return (
    <Box p={6} bg="secondary">
      <Box>
        <Box p="6" borderWidth="1px" borderRadius="md" bg="white" minW="70vw">
          {userInfo ? (
            <>
              <Stack spacing={2} p={4} backgroundColor="white" borderRadius="6px">
                <Text fontSize="1xl" mb="3" fontWeight="bold">
                  User Information
                </Text>
                <Grid templateColumns={'repeat(3, 1fr)'} gap={4}>
                  {userInfo.map((field, index) => (
                    <Box key={index} borderRadius="sm" p="3" w="100%" bg="secondary">
                      <Text fontSize="small" color="textSecondary">
                        {field.header}
                      </Text>
                      <Text fontSize="md">
                        {field.header === 'Time Elapsed This Session' ? elapsedTime : field.value}
                      </Text>
                    </Box>
                  ))}
                </Grid>
              </Stack>
              <Button
                ml={4}
                color="#fff"
                bg="primary.700"
                onClick={() => history.push(PASSWORD_RESET_URL)}>
                Change Password
              </Button>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

export default Settings;
