import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Text, VStack } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';

import AuthForm from './AuthForm';
import AuthWrapper from './AuthWrapper';

import { login } from '../../api/auth';
import {
  DASHBOARD_URL,
  SIGN_UP_URL,
  LATE_ACCOUNT_VERIFICATION_URL,
  EXTERNAL_VERIFICATION_URL,
  PASSWORD_RESET_URL,
} from '../../constants/routerUrl';
import { useDispatch } from 'react-redux';
import { logIn } from '../../store/actions/authActions';
import { setCurrentUserData, getCurrentUserData } from '../../util/localStorageUtils';
import { USERNAME_REGEX } from '../../constants/regex';
import { PASSWORD_MIN_LENGTH, USERNAME_MIN_LENGTH } from '../../constants/authConstant';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    const { user, accessToken } = getCurrentUserData();
    if (user && accessToken) {
      dispatch(logIn(user));
      history.replace(DASHBOARD_URL);
    }
  });

  const onSubmit = async (data) => {
    try {
      const res = await login(data);
      if (res.status === 200) {
        const { accessToken, fullName, userId, role, phoneNo, email } = res.data;
        let loginTime = new Date();
        const user = { fullName, userId, role, phoneNo, email, lastLogin: loginTime };
        // setting the accessToken and user to localStorage
        setCurrentUserData(user, accessToken);
        dispatch(logIn(user));
        history.replace(DASHBOARD_URL);
      }
    } catch (error) {
      if (error.response.data.isVerified === false) {
        history.replace({
          pathname: LATE_ACCOUNT_VERIFICATION_URL,
          state: { username: data.username },
        });
      }

      if (error?.response?.data?.shouldResetPassword) {
        history.replace(PASSWORD_RESET_URL);
      }
    }
  };

  const formFields = [
    {
      id: 'username',
      name: 'username',
      isRequired: true,
      type: 'text',
      label: 'Username',
      placeholder: 'Enter a username',
    },
    {
      id: 'password',
      name: 'password',
      isRequired: true,
      label: 'Password',
      type: showPass ? 'text' : 'password',
      rightElement: showPass ? (
        <ViewIcon onClick={() => setShowPass(false)} cursor="pointer" mt={1.5} />
      ) : (
        <ViewOffIcon onClick={() => setShowPass(true)} cursor="pointer" mt={1.5} />
      ),
      placeholder: 'Enter your password',
    },
  ];

  const LoginValidationSchema = yup.object().shape({
    username: yup
      .string()
      .matches(USERNAME_REGEX, 'Not a valid username')
      .min(USERNAME_MIN_LENGTH)
      .required(),
    password: yup.string().min(PASSWORD_MIN_LENGTH).required(),
  });

  return (
    <AuthWrapper>
      <AuthForm
        columns={1}
        formName="Login"
        onSubmit={onSubmit}
        formSubmitName="LOGIN"
        formFields={formFields}
        validationSchema={LoginValidationSchema}
        extraField={
          <Link to={PASSWORD_RESET_URL}>
            <Text
              fontSize="sm"
              opacity={0.45}
              transition="0.5s"
              color="primary.700"
              _hover={{ opacity: 1 }}>
              Forgot password?
            </Text>
          </Link>
        }
        additionalLink={
          <VStack spacing={3}>
            <Link to={SIGN_UP_URL}>
              <Text color="primary.700">Create an Account</Text>
            </Link>
            <Link to={EXTERNAL_VERIFICATION_URL}>
              <Text color="primary.700" fontSize="sm">
                Verify Certificate?
              </Text>
            </Link>
          </VStack>
        }
      />
    </AuthWrapper>
  );
};

export default Login;
