import React from 'react';
import * as yup from 'yup';
import { mobileNoValidation } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 2,
    name: 'mpName',
    type: 'text',
    isRequired: true,
    label: 'Name of Mill Representative',
  },
  {
    colSpan: 1,
    name: 'mpOfficeTelephone',
    type: 'text',
    isRequired: true,
    label: 'Office Telephone',
  },
  {
    colSpan: 1,
    name: 'mpResidenceTelephone',
    type: 'text',
    isRequired: true,
    label: 'Residence Telephone',
  },
  {
    colSpan: 1,
    name: 'mpMobileNo',
    type: 'text',
    isRequired: true,
    label: 'Mobile Number',
  },
  {
    colSpan: 1,
    name: 'mpEmail',
    type: 'text',
    isRequired: true,
    label: 'Email Address',
  },
];

export const millPresenterFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: mobileNoValidation.required().label(fields[1].label),
  [fields[2].name]: mobileNoValidation.required().label(fields[2].label),
  [fields[3].name]: mobileNoValidation.required().label(fields[3].label),
  [fields[4].name]: yup.string().email().required().label(fields[4].label),
};

const ManagingDirectorSection = (props) => {
  return (
    <FormSection
      sectionName="Mill Representative’s Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default ManagingDirectorSection;
