export const GENERAL_INFORMATION_FORM_SECTION = 'A';
export const YARN_MANUFACTURER_FORM_SECTION = 'B';
export const FABRIC_MANUFACTURER_FORM_SECTION = 'C';
export const KNIT_FABRIC_FORM_SECTION = 'D';
export const TEXTILE_PRODUCT_PROCESSOR_FORM_SECTION = 'E';
export const HOSIERY_KNITTING_MILLS_FORM_SECTION = 'F';
export const OTHER_INFORMATION_FORM_SECTION = 'G';

export const membershipRequestStatus = {
  PENDING: 'PENDING',
  APPLIED: 'APPLIED',
  INREVIEW: 'IN REVIEW',
  REWORK: 'REWORK',
  REJECTED: 'REJECTED',
  PAYMENTPENDING: 'PAYMENT PENDING',
  APPROVED: 'APPROVED',
};
