import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';

import NavPanel from '../common/NavPanel';
import CertificateLog from './CertificateLog/CertificateLog';
import ApplyCertificate from './CompleteCertificates/ApplyCertificate';
import PurchaseCertificate from './PurchaseCertificate/PurchaseCertificate';

import { CERTIFICATE_NAVIGATION_TABS } from '../../constants/certificateConstant';

const CertificateProduction = () => {
  const [selected, setSelected] = useState(0);

  const renderTabs = () => {
    {
      switch (selected) {
        case 0:
          return <PurchaseCertificate />;
        case 1:
          return <ApplyCertificate setNavState={setSelected} />;
        case 2:
          return <CertificateLog />;
        default:
          return null;
      }
    }
  };

  return (
    <Box p={6} bg="secondary">
      <NavPanel
        navList={CERTIFICATE_NAVIGATION_TABS}
        setNavState={setSelected}
        navState={selected}
      />
      {renderTabs()}
    </Box>
  );
};

export default CertificateProduction;
