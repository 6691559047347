import React, { useRef, useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';

import DataTable from '../../../common/certificateComponents/DataTable';
import CertificateBill from '../../../common/certificateComponents/CertificateBill';

import { useDispatch, useSelector } from 'react-redux';
import { renderDate } from '../../../../util/formatUtils';
import {
  deleteProcessingCertPurchase,
  getProcessingCertHistory,
} from '../../../../api/certificate';
import { BADGE_STATE_COLORS } from '../../../../constants/dataTableConstants';
import { LOGS_TABLE_DISPLAY_SIZE } from '../../../../constants/certificateConstant';
import { FETCH_PROCESSING_CERTIFICATE_DATA } from '../../../../store/actions/actionTypes';
import DialogueBox from '../../../common/DialogueBox';
import { notify } from '../../../../store/actions/globalActions';

//configure columns
const columns = [
  {
    header: 'Order Id',
    accessor: 'orderId',
  },
  {
    header: 'Order Date',
    accessor: 'orderDate',
    isDate: true,
  },
  {
    header: 'Certificate Range',
    accessor: 'certRange',
  },
  {
    header: 'No. of Certificates',
    accessor: 'numOfCert',
  },
  {
    header: 'Total Payable',
    accessor: 'totalDue',
    currency: '৳',
  },
  {
    header: 'Payment Status',
    accessor: 'status',
    isBadge: true,
    colorScheme: BADGE_STATE_COLORS,
    hoverInfo: ['paymentMethod', 'chequeNo as ID', 'chequeDated as Date', 'remarks'],
  },
  {
    header: 'Bill',
    accessor: 'status',
    isButton: true,
    actionScheme: [
      { group: 'PAID', variant: 'print', action: 'PRINT' },
      { group: 'UNPAID', variant: 'print', action: 'PRINT' },
    ],
  },
  {
    header: 'Action',
    accessor: 'status',
    isButton: true,
    actionScheme: [{ group: 'UNPAID', variant: 'kill', action: 'DELETE' }],
  },
];

const PurchaseHistoryTable = () => {
  const dispatch = useDispatch();
  const certificateBillRef = useRef();
  const [rowData, setRowData] = useState();
  const [billData, setBillData] = useState();
  const [costData, setCostData] = useState();
  const mill = useSelector((state) => state.mill.mill);
  const data = useSelector((state) => state.certificate.processingCertData);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_PROCESSING_CERTIFICATE_DATA, payload: null });
    const params = { page, pageSize, searchKey, startDate, endDate };
    const res = await getProcessingCertHistory(params);
    if (res.data) {
      dispatch({ type: FETCH_PROCESSING_CERTIFICATE_DATA, payload: res.data });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_PROCESSING_CERTIFICATE_DATA, payload: null });
    const params = { searchKey, startDate, endDate };
    const res = await getProcessingCertHistory(params);
    if (res.data) {
      dispatch({ type: FETCH_PROCESSING_CERTIFICATE_DATA, payload: res.data });
    }
  };

  const printBill = useReactToPrint({
    documentTitle: `BTMA-Bill-Processing-Certificate-${billData?.billNo}-${renderDate(new Date())}`,
    content: () => certificateBillRef.current,
  });

  const handlePrint = ({ rowObject }) => {
    setBillData({
      billNo: rowObject?.orderId,
      millName: mill?.millName,
      millAddress: mill?.hoInfo?.hoName,
    });
    setCostData({
      certOf: 'processing',
      noOfCopies: rowObject?.numOfCert,
      unitCost: rowObject?.unitCost,
      total: rowObject?.totalDue,
      range: rowObject?.certRange,
    });
    setTimeout(() => {
      printBill();
    }, 500);
  };

  const handleDelete = ({ rowObject }) => {
    setRowData(rowObject);
    onOpen();
  };

  const confirmDelete = async () => {
    const res = await deleteProcessingCertPurchase(rowData.orderId);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      handlePagination({ page: 1, pageSize: 10 });
    }
  };

  const actionObject = { PRINT: handlePrint, DELETE: handleDelete };

  return (
    <Box>
      <DataTable
        caption="Purchase History"
        columns={columns}
        data={data?.purchaseHistory}
        actions={actionObject}
        topPanel={true}
        isPaginated
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        entries={data?.totalEntries}
        currentPage={data?.page}
        currentPageSize={data?.pageSize}
        keySearch
        dateRange
        paginate={handlePagination}
        search={handleSearch}
      />
      <Box display="none">
        <CertificateBill
          ref={certificateBillRef}
          title="Certificate"
          data={billData}
          costs={costData}
        />
      </Box>
      <DialogueBox
        close={onClose}
        alertTitle="DELETE"
        mountControl={isOpen}
        performAction={confirmDelete}
        alertMessage="Are you sure you want to delete this purchase request?"
      />
    </Box>
  );
};

export default PurchaseHistoryTable;
