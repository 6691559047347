import React from 'react';
import { Flex, Text, Stack, Box } from '@chakra-ui/react';
import PublicNavbar from '../snippets/PublicNavbar';
import Footer from '../snippets/Footer';

const ExternalWrapper = ({ children }) => {
  return (
    <>
      <PublicNavbar />
      <Flex
        minH="calc(100vh - 140px)"
        px={6}
        flexDirection="column"
        backgroundColor="secondary"
        justifyContent="center"
        alignSelf="center"
        alignItems="center">
        <Stack flexDir="column" mb="12" mt="12" justifyContent="center" alignItems="center">
          <Text fontSize="2xl" fontWeight="bold" mt="3" mb="3">
            Certificate Verification Portal
          </Text>
          <Box>{children}</Box>
        </Stack>
      </Flex>
      <Footer />
    </>
  );
};

export default ExternalWrapper;
