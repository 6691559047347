import React, { useEffect, useRef } from 'react';
import { Modal, ModalOverlay, ModalCloseButton, Box } from '@chakra-ui/react';

const BasicModal = ({ mountControl, close, children }) => {
  useEffect(() => {
    if (mountControl) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [mountControl]);

  const isOpen = mountControl;
  const cancelRef = useRef();

  return (
    <Modal isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close}>
      <ModalOverlay>
        <Box position="absolute" w="100vw" h="100vh" onClick={close} />
        <Box
          w="calc(80vw - 50px) !important"
          h="calc(100vh - 50px) !important"
          overflow="auto"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bgColor="white"
          boxShadow="0px 5px 10px rgba(0,0,0,0.3)">
          <ModalCloseButton />
          {children}
        </Box>
      </ModalOverlay>
    </Modal>
  );
};

export default BasicModal;
