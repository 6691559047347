import { Box, FormControl, FormLabel, HStack, Select, Text } from '@chakra-ui/react';
import React from 'react';
import { CERTIFICATE_OF_PRODUCTION_TYPES } from '../../../../constants/certificateConstant';

const SelectCertificateType = ({ setCertificateType, error }) => {
  const certTypes = CERTIFICATE_OF_PRODUCTION_TYPES;

  return (
    <Box>
      <HStack spacing={3} mb={4}>
        <FormControl isInvalid={error}>
          <FormLabel mb={1.5} fontSize="md">
            <b style={{ color: '#E53E3E' }}>* </b>Certificate Type
          </FormLabel>
          <Select
            colorScheme="primary"
            focusBorderColor="primary.300"
            h="50px"
            w={'15rem'}
            borderRadius="4px"
            bg="formInputBG"
            placeholder="Select an option"
            onChange={(e) => setCertificateType(e.target.value)}>
            {certTypes.choices?.map((type) => (
              <option key={type.name} value={type.value}>
                {type.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>
      <Text color="invalid" fontSize="15px" mt="6px">
        {error}
      </Text>
    </Box>
  );
};

export default SelectCertificateType;
