import React, { useEffect, useRef } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Box,
} from '@chakra-ui/react';

const DialogueBox = ({
  mountControl,
  close,
  alertTitle,
  alertMessage,
  actionButtonText,
  cancelButtonText,
  performAction,
  onActionKeepOpen,
  children,
  secondaryMount,
}) => {
  useEffect(() => {
    if (!secondaryMount) {
      if (mountControl) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [mountControl, secondaryMount]);

  const isOpen = mountControl;

  const handleAction = () => {
    onActionKeepOpen ? null : close();
    performAction();
  };

  const cancelRef = useRef();

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close}>
      <AlertDialogOverlay>
        <Box position="absolute" w="100vw" h="100vh" onClick={close} />
        <Box
          minW="450px"
          maxW="600px"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bgColor="white"
          borderRadius={10}
          boxShadow="0px 5px 10px rgba(0,0,0,0.3)">
          <AlertDialogHeader fontSize="lg" fontWeight="bold" textTransform="uppercase">
            {alertTitle ? alertTitle : 'alert title'}
          </AlertDialogHeader>

          <AlertDialogCloseButton />

          <AlertDialogBody pt="20px" pb="20px">
            {alertMessage ? alertMessage : 'Are you sure? You can not undo this action afterwards!'}
            {children}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="modalAction" onClick={handleAction} textTransform="uppercase">
              {actionButtonText ? actionButtonText : 'Yes'}
            </Button>
            <Button
              variant="modalAction"
              textTransform="uppercase"
              ref={cancelRef}
              onClick={close}
              ml={3}>
              {cancelButtonText ? cancelButtonText : 'No'}
            </Button>
          </AlertDialogFooter>
        </Box>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DialogueBox;
