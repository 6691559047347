import React from 'react';
import { Box, HStack, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import commaNumber from 'comma-number';
import { convertNumberToWords } from '../../../util/formatUtils';

const CostBreakdown = ({ data, billFor, isFromBlankCert }) => {
  return (
    <Box>
      {billFor ? (
        <Text fontSize="2xl" fontWeight="bold" mb={2} width="100%" textAlign="center">
          {billFor} Bill
        </Text>
      ) : (
        <Text fontSize="md" fontWeight="bold">
          Certificate Cost-Breakdown
        </Text>
      )}

      <Table mt={2}>
        <Thead>
          <Tr>
            <Th>Certificate For</Th>
            <Th>Type</Th>
            <Th textAlign="right">Quantity</Th>
            <Th textAlign="center">Range</Th>
            <Th textAlign="right">Unit Price</Th>
            <Th textAlign="right">Cost(৳)</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Text textTransform="uppercase">
                {data?.certOf?.toUpperCase() === 'DUTY'
                  ? data?.certOf + '-EXEMPTION' + (isFromBlankCert ? ' (Application Fee)' : '')
                  : data?.certOf}
              </Text>
            </Td>
            <Td>
              <Text>{data?.type || 'N/A'}</Text>
            </Td>
            <Td textAlign="right">
              <Text>{commaNumber(data?.noOfCopies || 0)}</Text>
            </Td>
            <Td textAlign="center">
              <Text>{data?.range || 'N/A'}</Text>
            </Td>
            <Td textAlign="right">
              <Text>{commaNumber(data?.unitCost || 0)}</Text>
            </Td>
            <Td textAlign="right">
              <Text>{data?.total}</Text>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="18px" fontWeight="bold">
                Total Cost:
              </Text>
            </Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td textAlign="right">
              <Text fontSize="18px" fontWeight="bold">
                Tk. {commaNumber(data?.total || 0)}
              </Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      {billFor ? (
        <HStack w="100%" borderTop="1px solid black" borderStyle="dotted" mt={1}>
          <Text fontSize="sm" fontWeight="bold">
            In words:{' '}
          </Text>
          <Text fontSize="sm">{convertNumberToWords(data?.total || 0)} Taka Only</Text>
        </HStack>
      ) : null}
    </Box>
  );
};

export default CostBreakdown;
