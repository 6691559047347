//modules
import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import {
  Divider,
  Box,
  Grid,
  GridItem,
  Stack,
  Text,
  HStack,
  Button,
  CloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

//common components
import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import SelectProductSection from './SelectProductSection';
import SelectCertificateSection from './SelectCertificateSection';
import CheckBoxField from '../../../common/hookFormComponents/CheckBoxField';

//form sections
import ItemsInformation, {
  defaultItemFieldValues,
  itemsInformationValidation,
} from './ItemsInformation';
import AdditionalDocumentsSection, {
  additionalReqDocumentsValidation,
} from './AdditionalDocumentsSection';
import CerGeneralInformation, { generalInformationValidation } from './CerGeneralInformation';
import MillInformationSection, { millInformationSectionValidation } from './MillInformationSection';

//utility & actions
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { applyForProcessingCertificate } from '../../../../api/certificate';
import _ from 'lodash';

const CertificateApplicationForm = ({ setNavState, refresh }) => {
  const toast = useToast();
  const toastIdRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [certificate, setCertificate] = useState();
  const [product, setProduct] = useState();
  const [applicationData, setApplicationData] = useState();
  const [certErrorMsg, setCertErrorMsg] = useState('');
  const [prodErrorMsg, setProdErrorMsg] = useState('');

  const validationSchema = yup.object().shape({
    ...itemsInformationValidation,
    ...generalInformationValidation,
    ...millInformationSectionValidation,
    ...additionalReqDocumentsValidation,
    agree: yup.bool().oneOf([true], 'This field must be checked'),
  });

  const resolver = useYupValidationResolver(validationSchema);

  const {
    watch,
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  const watchAllFields = watch();

  useEffect(() => {
    if (certificate?.certNum && certificate?.type && product) {
      setCertErrorMsg('');
      setProdErrorMsg('');
    } else if (product && !certificate?.certNum && !certificate?.type) {
      setProdErrorMsg('');
    } else if (certificate?.certNum && certificate?.type && !product) {
      setCertErrorMsg('');
    }
  }, [certificate, product]);

  const CustomToast = () => (
    <HStack color="white" p={3} bg="green.500" borderRadius="md">
      <Text>Successfully submitted!</Text>
      <Button
        variant="outline"
        fontSize="md"
        height="35px"
        _hover={{ bg: 'green.400' }}
        onClick={() => {
          setNavState(2);
          toast.closeAll();
        }}>
        View Details
      </Button>

      <CloseButton size="sm" onClick={() => toast.closeAll()} alignSelf="flex-start" />
    </HStack>
  );

  const showCustomToast = () => {
    toastIdRef.current = toast({
      position: 'bottom-right',
      duration: null,
      render: CustomToast,
    });
  };

  const confirmSubmit = async () => {
    const res = await applyForProcessingCertificate(
      { ...applicationData, product },
      {
        certId: certificate?.certID,
      },
    );
    if (res.data?.status == 200) {
      let resetObj = {};
      _.mapKeys(applicationData, (value, key) => (resetObj[key] = ''));
      reset({ ...resetObj, items: defaultItemFieldValues });
      setProduct();
      setCertificate();
      showCustomToast();
      refresh();
    }
  };

  const onSubmit = (data) => {
    setApplicationData(data);
    onOpen();
  };

  return (
    <Box>
      <Text fontSize="2xl" mt={6} mb={2}>
        Select Certificate & Product
      </Text>

      <Stack bg="white" pt={6} pb={4} px={4} borderRadius="sm" mb={4}>
        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
          <GridItem colSpan={2}>
            <HStack px={3} spacing={3}>
              <SelectCertificateSection setCertificate={setCertificate} error={certErrorMsg} />
              <SelectProductSection setProductType={setProduct} error={prodErrorMsg} />
            </HStack>
          </GridItem>
        </Grid>
      </Stack>

      {certificate?.certID && product ? (
        <>
          <Text fontSize="2xl" mt={6} mb={2}>
            Certificate of Processing Form
          </Text>

          <Box as="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack bg="white" p={4} borderRadius="sm">
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <GridItem colSpan={2}>
                  <CerGeneralInformation register={register} control={control} errors={errors} />
                </GridItem>
              </Grid>
            </Stack>

            <Stack bg="white" p={4} borderRadius="sm">
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <GridItem colSpan={2}>
                  <ItemsInformation
                    sectionName={`${product} information`}
                    register={register}
                    control={control}
                    errors={errors}
                    section={
                      watchAllFields?.product
                        ? watchAllFields?.product + ' Information'
                        : 'Items Information'
                    }
                  />
                </GridItem>
              </Grid>
            </Stack>

            <Stack bg="white" p={4} borderRadius="sm">
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <GridItem colSpan={2}>
                  <MillInformationSection
                    watch={watch}
                    register={register}
                    errors={errors}
                    control={control}
                  />
                </GridItem>
              </Grid>
            </Stack>

            <Stack bg="white" p={4} borderRadius="sm">
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <GridItem colSpan={2}>
                  <Divider />
                </GridItem>
                <GridItem colSpan={2}>
                  <AdditionalDocumentsSection watch={watch} register={register} errors={errors} />
                </GridItem>
              </Grid>
            </Stack>

            <Stack bg="white" p={4} borderRadius="sm">
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <GridItem colSpan={2}>
                  <Divider />
                </GridItem>
                <GridItem colSpan={1} px={3}>
                  <CheckBoxField
                    control={control}
                    errorMessage={errors['agree']?.message}
                    name="agree"
                    showText="I, the undersigned, Secretary General of Bangladesh Textile Mills Association (BTMA) Dhaka hereby certify that the above declaration was made before me. As far as it can be ascertained from documentary proof submitted, the above goods have been produced/manufactured in Bangladesh."
                  />
                </GridItem>
              </Grid>
            </Stack>

            <Stack bg="white" flexDir="column" alignItems="flex-end" px={7} pb={7}>
              <FormButton type="submit" isLoading={isSubmitting} mt={3}>
                SUBMIT
              </FormButton>
            </Stack>
          </Box>
        </>
      ) : null}

      <DialogueBox
        mountControl={isOpen}
        close={onClose}
        alertTitle="Confirm"
        alertMessage="Are you sure you want to submit this application?"
        performAction={confirmSubmit}
      />
    </Box>
  );
};

export default CertificateApplicationForm;
