import React from 'react';
import { Box, FormControl, FormLabel, HStack, Select, Text } from '@chakra-ui/react';
import { PRODUCTION_PRODUCT_TYPES } from '../../../../constants/certificateConstant';

const SelectProductSection = ({ setProductType, error }) => {
  const prodTypes = PRODUCTION_PRODUCT_TYPES;

  return (
    <Box>
      <HStack spacing={3} mb={4}>
        <FormControl isInvalid={error}>
          <FormLabel mb={1.5} fontSize="md">
            <b style={{ color: '#E53E3E' }}>* </b>Product Type
          </FormLabel>
          <Select
            colorScheme="primary"
            focusBorderColor="primary.300"
            h="50px"
            w="15rem"
            borderRadius="4px"
            bg="formInputBG"
            placeholder="Select an option"
            onChange={(e) => setProductType(e.target.value)}>
            {prodTypes?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>
      <Text color="invalid" fontSize="15px" mt="6px">
        {error}
      </Text>
    </Box>
  );
};

export default SelectProductSection;
