import React, { useState, useEffect } from 'react';
import { HStack, Text, chakra, Box, Icon, Input, IconButton } from '@chakra-ui/react';
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';

const Pagination = ({
  entries,
  pageSizes,
  paginate,
  currentPage,
  currentPageSize,
  currentSearchParams,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [validPage, setValidPage] = useState(true);
  const pageCount = entries ? Math.ceil(entries / pageSize) : 1;
  const [pageErrorMsg, setPageErrorMsg] = useState('');

  // this handles pagination upon any pagination action
  useEffect(() => {
    if (currentPage && currentPageSize) {
      if (page != currentPage || pageSize != currentPageSize) {
        validPage ? paginate({ page, pageSize, ...currentSearchParams }) : null;
      }
    }
  }, [page, pageSize]);

  //this resets table to first page and table size to default upon search action
  useEffect(() => {
    if (page != currentPage) setPage(currentPage);
    if (pageSize != currentPageSize) setPageSize(currentPageSize);
  }, [currentPage, currentPageSize]);

  // pagination actions
  const incrementPage = () => {
    if (page < pageCount) {
      setPage(page + 1);
    }
  };
  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const validatePageJump = (value) => {
    if (value) {
      if (1 <= value && value <= pageCount) {
        setPageErrorMsg('');
        setPage(Number(value));
        setValidPage(true);
      } else {
        setPageErrorMsg(`Please enter a value between 1 - ${pageCount}`);
        setValidPage(false);
      }
    } else {
      setPageErrorMsg('');
      setValidPage(true);
    }
  };

  return (
    <HStack bg="white" p={4} borderRadius="0 0 4px 4px" justifyContent="space-between">
      <Text>
        Page{' '}
        <chakra.strong>
          {currentPage} of {pageCount}
        </chakra.strong>
      </Text>
      <Text color="invalid" mt={2}>
        {pageErrorMsg}
      </Text>
      <Box>
        <IconButton
          mr={2}
          variant="tableAction"
          icon={<Icon as={ChevronLeftIcon} />}
          onClick={decrementPage}
          disabled={page === 1}
        />
        <IconButton
          mr={2}
          variant="tableAction"
          onClick={incrementPage}
          icon={<Icon as={ChevronRightIcon} />}
          disabled={page === pageCount}
        />
        <chakra.span>
          Go to page:{' '}
          <Input
            bg="formInputBG"
            variant="outline"
            focusBorderColor="primary.300"
            borderRadius="4px"
            w="60px"
            onChange={(e) => validatePageJump(e.target.value)}
          />
        </chakra.span>{' '}
        <chakra.select
          h="40px"
          value={currentPageSize}
          onChange={(e) => {
            setPage(1);
            setPageSize(Number(e.target.value));
          }}>
          {pageSizes ? (
            pageSizes.map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))
          ) : (
            <option value={10}>Show 10</option>
          )}
        </chakra.select>
      </Box>
    </HStack>
  );
};

export default Pagination;
