import React from 'react';
import DateRangeSection from './DateRangeSection';

const DateRangePicker = ({ spacing, data, error, validation, clearSelection, cleared }) => {
  const validateRange = (dateRange) => {
    if (dateRange.startDate !== null && dateRange.endDate !== null) {
      if (dateRange.startDate > dateRange.endDate) {
        error('Start Date can not come after End Date');
        validation(false);
      } else {
        data(dateRange);
        error('');
        validation(true);
      }
    } else {
      data(dateRange);
      error('');
      validation(true);
    }
  };

  const receiveData = (dateRange) => {
    validateRange(dateRange);
  };

  return (
    <DateRangeSection
      spacing={spacing}
      data={(dateRange) => receiveData(dateRange)}
      clearSelection={clearSelection}
      fieldsCleared={() => cleared()}
    />
  );
};

export default DateRangePicker;
