import {
  SET_MILL,
  REMOVE_MILL,
  FETCH_TRANSACTION_HISTORY,
  FETCH_INFORMATION_UPDATE_HISTORY,
} from '../actions/actionTypes';

const initialState = {
  mill: null,
  transactions: null,
  updateRequests: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MILL: {
      const { mill } = action.payload;
      return {
        ...state,
        mill: mill,
      };
    }

    case REMOVE_MILL: {
      return {
        ...state,
        mill: null,
      };
    }

    case FETCH_TRANSACTION_HISTORY: {
      return {
        ...state,
        transactions: action.payload,
      };
    }

    case FETCH_INFORMATION_UPDATE_HISTORY: {
      return {
        ...state,
        updateRequests: action.payload,
      };
    }

    default:
      return state;
  }
}
