import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';
import { dateValidation } from '../../../../util/yupUtils';

const fields = [
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'category',
    label: 'Category',
    choices: [
      { name: 'Textile Spare Parts', value: 'Textile Spare Parts' },
      { name: 'Dyes Chemicals', value: 'Dyes Chemicals' },
      { name: 'Polyester Staple Fiber', value: 'Polyester Staple Fiber' },
      { name: 'Viscose Staple Fiber', value: 'Viscose Staple Fiber' },
      { name: 'Tencel & Flax Fiber', value: 'Tencel & Flax Fiber' },
      { name: 'Others Fiber', value: 'Others Fiber' },
    ],
  },
  {
    colSpan: 1,
    name: 'itemName',
    type: 'text',
    label: 'Item Name',
  },
  {
    colSpan: 1,
    name: 'masterLc',
    type: 'text',
    label: 'L/C No.',
  },
  {
    colSpan: 1,
    name: 'value',
    type: 'text',
    label: 'L/C amount',
  },
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'currency',
    label: 'Currency',
    choices: [
      { name: 'BDT', value: 'BDT' },
      { name: 'USD', value: 'USD' },
      { name: 'EURO', value: 'EURO' },
      { name: 'POUND', value: 'POUND' },
      { name: 'RUPEE', value: 'RUPEE' },
      { name: 'YEN', value: 'YEN' },
      { name: 'YUAN', value: 'YUAN' },
      { name: 'CHF', value: 'CHF' },
    ],
  },
  {
    colSpan: 1,
    name: 'quantity',
    type: 'text',
    label: 'Quantity in KG',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'dateOfLc',
    label: 'L/C Date',
  },
  {
    colSpan: 1,
    name: 'blNo',
    type: 'text',
    label: 'BL/Airway Bill/Truck Receipt No.',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'blDate',
    label: 'BL Date',
  },
  {
    colSpan: 1,
    name: 'invoice',
    type: 'text',
    label: 'Invoice No.',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'invoiceDate',
    label: 'Invoice Date',
  },
];

export const requiredInfoFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
  [fields[3].name]: yup.number().typeError(`${fields[3].label} must be a number`).required(),
  [fields[4].name]: yup.string().required().label(fields[4].label),
  [fields[5].name]: yup.number().typeError(`${fields[5].label} must be a number`).required(),
  [fields[6].name]: dateValidation.required().label(fields[6].label),
  [fields[7].name]: yup.string().required().label(fields[7].label),
  [fields[8].name]: dateValidation.required().label(fields[8].label),
  [fields[9].name]: yup.string().required().label(fields[9].label),
  [fields[10].name]: dateValidation.required().label(fields[10].label),
};

const RequiredInfoSection = (props) => {
  return (
    <FormSection
      sectionName="Required Information"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default RequiredInfoSection;
