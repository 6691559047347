export const LOGS_TABLE_DISPLAY_SIZE = [10, 20, 30, 40, 50];

export const BADGE_STATE_COLORS = [
  { group: 'PENDING', color: 'purple' },
  { group: 'APPLIED', color: 'gray' },
  { group: 'IN REVIEW', color: 'yellow' },
  { group: 'PAYMENT PENDING', color: 'orange' },
  { group: 'REWORK', color: 'blue' },
  { group: 'REJECTED', color: 'red' },
  { group: 'APPROVED', color: 'green' },
  { group: 'PAID', color: 'green' },
  { group: 'UNPAID', color: 'red' },
  { group: 'CASH', color: 'blue' },
  { group: 'CHEQUE', color: 'purple' },
];
