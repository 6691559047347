import React from 'react';
import * as yup from 'yup';
import { fileValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'changeRequest',
    type: 'text',
    fieldType: 'textArea',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'attachment',
    type: 'file',
    label: 'Attachment (PDF/JPG/JPEG-Max 5mb)',
  },
];

export const requestFieldsValidation = {
  [fields[0].name]: yup.string().required().label('Change Request'),
  [fields[1].name]: fileValidation.optional,
};

const RequestFormSection = (props) => {
  return (
    <FormSection sectionName="Request Changes" sectionColumn={1} formFields={fields} {...props} />
  );
};

export default RequestFormSection;
