import React, { useEffect, useState } from 'react';
import { Badge, Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import ExternalWrapper from './ExternalWrapper';
import InfoSection from '../common/InfoSection';
import Loader from '../snippets/Loader';
import { renderDate } from '../../util/formatUtils';
import { EXTERNAL_VERIFICATION_URL } from '../../constants/routerUrl';

const DataPreview = () => {
  const history = useHistory();
  const location = useLocation();
  const data = location?.state;
  const [certInfo, setCertInfo] = useState();
  const [certInfoFields, setCertInfoFields] = useState();

  useEffect(() => {
    if (data) {
      let certData = data?.certificateInfo;

      switch (certData?.type) {
        case 'PRODUCTION_CASH': {
          let cashCertData =
            data?.certificateInfo?.cashAssitanceYarnExport ||
            data?.certificateInfo?.cashAssitanceFabricExport;
          setCertInfo(cashCertData);

          setCertInfoFields([
            { header: 'Export L/C No.', value: cashCertData?.masterLc },
            { header: 'Date', value: renderDate(cashCertData?.dateOfLc) },
            { header: 'Total Quantity Under L/C', value: cashCertData?.totalQuantity },
            { header: 'Value', value: cashCertData?.totalValueInUsd },
            { header: 'Back-to-Back L/C No.', value: cashCertData?.btbLc },
            { header: 'Back-to-Back L/C Date', value: renderDate(cashCertData?.btbLcDate) },
            { header: 'Total Value', value: cashCertData?.valueInUsd },
            { header: 'L/C Opening Bank', value: cashCertData?.bank?.split(',')?.[0] },
            { header: 'Bank Branch', value: cashCertData?.bank?.split(',')?.[1] },
            { header: 'Expiry Date of L/C', value: renderDate(cashCertData?.expiryDateOfLc) },
            { header: 'Mushak No.', value: cashCertData?.mushakNum },
            { header: 'Mushak Date', value: renderDate(cashCertData?.mushakDate) },
          ]);
          break;
        }

        case 'PRODUCTION_GSP': {
          let gspCertData =
            data?.certificateInfo?.gspYarnExport || data?.certificateInfo?.gspFabricExport;
          setCertInfo(gspCertData);

          setCertInfoFields([
            { header: 'Export L/C No.', value: gspCertData?.masterLc },
            { header: 'Date', value: renderDate(gspCertData?.dateOfLc) },
            { header: 'Total Quantity Under L/C', value: gspCertData?.totalQuantity },
            { header: 'Back-to-Back L/C No.', value: gspCertData?.btbLc },
            { header: 'Back-to-Back L/C Date', value: renderDate(gspCertData?.btbLcDate) },
            { header: 'Total Value', value: gspCertData?.valueInUsd },
            { header: 'L/C Opening Bank', value: gspCertData?.bank?.split(',')?.[0] },
            { header: 'Bank Branch', value: gspCertData?.bank?.split(',')?.[1] },
            { header: 'Expiry Date of L/C', value: renderDate(gspCertData?.expiryDateOfLc) },
            { header: 'Vat Challan No.', value: gspCertData?.exciseGatePassNum },
            { header: 'Vat Challan Date', value: renderDate(gspCertData?.exciseGatePassDate) },
          ]);
          break;
        }

        case 'PROCESSING': {
          let processingCertData =
            data?.certificateInfo?.processingTextile || data?.certificateInfo?.processingFabric;
          setCertInfo(processingCertData);

          setCertInfoFields([
            { header: 'Master L/C No.', value: processingCertData?.masterLc },
            { header: 'Date', value: renderDate(processingCertData?.dateOfLc) },
            { header: 'Total Quantity Under L/C', value: processingCertData?.totalQuantity },
            { header: 'Back-to-Back L/C No.', value: processingCertData?.btbLc },
            { header: 'Back-to-Back L/C Date', value: renderDate(processingCertData?.btbLcDate) },
            { header: 'L/C Opening Bank', value: processingCertData?.bank?.split(',')?.[0] },
            { header: 'Bank Branch', value: processingCertData?.bank?.split(',')?.[1] },
            { header: 'Vat Challan No.', value: processingCertData?.exciseGatePassNum },
            {
              header: 'Vat Challan Date',
              value: renderDate(processingCertData?.exciseGatePassDate),
            },
          ]);
          break;
        }

        case 'DUTY': {
          let dutyCertData = data?.certificateInfo?.dutyExemptionCertificates;
          setCertInfo(dutyCertData);

          setCertInfoFields([
            { header: 'ঋণপত্র নং', value: dutyCertData?.masterLc },
            { header: 'তারিখ (ঋণপত্র)', value: renderDate(dutyCertData?.dateOfLc) },
            { header: 'ইনভয়েস নং', value: dutyCertData?.invoice },
            { header: 'তারিখ (ইনভয়েস)', value: renderDate(dutyCertData?.invoiceDate) },
            { header: 'বি.এল নং', value: dutyCertData?.blNo },
            { header: 'তারিখ (বি.এল)', value: renderDate(dutyCertData?.blDate) },
          ]);
          break;
        }
      }
    }
  }, [data]);

  const millInfoFields = [
    { header: 'Mill Name', value: data?.millInfo?.millName },
    { header: 'Email', value: data?.millInfo?.hoInfo?.millEmail },
    { header: 'Contact', value: data?.millInfo?.hoInfo?.millPhoneNo },
    {
      header: 'Head Office',
      value: data?.millInfo?.hoInfo?.hoName,
    },
  ];

  return (
    <ExternalWrapper>
      {data ? (
        <Box>
          <Box mb={12}>
            <InfoSection sectionName="Mill Information" infoFields={millInfoFields} />
          </Box>
          <Box mb={4}>
            <HStack fontSize="2xl" w="100%" justifyContent="center" spacing={10}>
              <HStack>
                <Text>Certificate No:</Text>
                <Badge colorScheme="purple" fontSize="xl">
                  {data?.certificateInfo?.certificateNum}
                </Badge>
              </HStack>
              <HStack>
                <Text>Certificate Status:</Text>
                <Badge colorScheme="green" fontSize="xl">
                  {data?.certificateInfo?.applicationStatus}
                </Badge>
              </HStack>
              <HStack>
                <Text>Issue Date:</Text>
                <Badge colorScheme="yellow" fontSize="xl">
                  {renderDate(certInfo?.issueDate)}
                </Badge>
              </HStack>
            </HStack>
          </Box>
          <Box mb={4}>
            <InfoSection sectionName="Certificate Information" infoFields={certInfoFields} />
          </Box>

          <Stack flexDir="column" alignItems="center" pt={6}>
            <Button
              borderRadius={2}
              h="50px"
              type="submit"
              variant="solid"
              minW="240px"
              onClick={() => history.replace(EXTERNAL_VERIFICATION_URL)}>
              CHECK ANOTHER
            </Button>
          </Stack>
        </Box>
      ) : (
        <Loader fullScreen />
      )}
    </ExternalWrapper>
  );
};

export default DataPreview;
