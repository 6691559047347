import { Box } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { getDashboardHome } from '../api/dashboard';
import Layout from '../components/snippets/Layout';

import {
  CERTIFICATE_PROCESSING_URL,
  CERTIFICATE_PRODUCTION_URL,
  DUTY_EXEMPTION_URL,
  LABOUR_DATABASE_URL,
  ROOT_URL,
} from '../constants/routerUrl';
import { setMill } from '../store/actions/millActions';
import { getCurrentUserData } from '../util/localStorageUtils';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isRestricted, setIsRestricted] = useState(false);
  const { user, accessToken } = getCurrentUserData();
  const isLoggedIn = user && accessToken;
  const mill = useSelector((state) => state.mill.mill);

  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        const { data } = await getDashboardHome();
        if (data) {
          dispatch(setMill(data.mill));
        }
      }
    };

    fetchData();
  }, [isLoggedIn]);

  useEffect(() => {
    let restrictedRoutes = [
      CERTIFICATE_PRODUCTION_URL,
      CERTIFICATE_PROCESSING_URL,
      DUTY_EXEMPTION_URL,
      LABOUR_DATABASE_URL,
    ];
    let currentUrl = history.location.pathname.split('/');
    let currentRoute = `/${currentUrl?.[currentUrl?.length - 1]}`;
    if (mill?.membershipStatus !== 'APPROVED' && restrictedRoutes.includes(currentRoute)) {
      setIsRestricted(true);

      //allow only duty section for PROVISIONAL MEMBER
      if (
        mill?.membershipStatus == 'PROVISIONAL MEMBER' &&
        currentRoute == '/exemption-certificate'
      ) {
        setIsRestricted(false);
      }
    } else {
      setIsRestricted(false);
    }
  }, [mill, history.location.pathname]);

  if (isLoggedIn) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Layout>{isRestricted ? <Box></Box> : <Component {...props} />}</Layout>;
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: ROOT_URL,
              state: {
                redirectUrl: path,
              },
            }}
          />
        )}
      />
    );
  }
};

export default PrivateRoute;
