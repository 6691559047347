import { Stack, Box, Text, Divider, IconButton, Icon, VStack } from '@chakra-ui/react';
import React from 'react';
import { BiChevronsDown, BiChevronsUp } from 'react-icons/bi';

const MembershipFormWrapper = (props) => {
  return (
    <Box as="form" {...props}>
      <Text fontSize="22px" mt={6} mb={2}>
        Membership Form
      </Text>
      <Stack bg="white" p={5} pt={4} borderRadius="4px">
        <Text fontSize="18px" fontWeight="bold">
          {props.formName}
        </Text>
        <Divider />
        {props.children}
      </Stack>
      <VStack position="fixed" bottom={40} right={2}>
        <IconButton
          variant="scrollIcon"
          icon={<Icon as={BiChevronsUp} />}
          onClick={() => window.scrollTo(0, 0)}
        />
        <IconButton
          variant="scrollIcon"
          icon={<Icon as={BiChevronsDown} />}
          onClick={() => window.scrollTo(0, document.body.scrollHeight)}
        />
      </VStack>
    </Box>
  );
};

export default MembershipFormWrapper;
