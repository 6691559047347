import React from 'react';

import { Grid, GridItem, Stack, Divider, HStack, Button, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { useYupValidationResolver } from '../../../../util/yupUtils';

import _ from 'lodash';

// wrapper
import MembershipFormWrapper from '../../MembershipFormWrapper';
// Form Sections
import {
  HumanResources,
  HumanResourcesFieldsValidation,
  AdditionalDocumentsSection,
  additionalDocumentsValidation,
  FactoryOperation,
  FactoryOperationFieldsValidation,
} from './sections';

import CheckBoxField from '../../../common/hookFormComponents/CheckBoxField';

// membership api
import {
  patchMembership,
  patchMembershipDocuments,
  postMembershipDocuments,
} from '../../../../api/membership';
import { OTHER_INFORMATION_FORM_SECTION } from '../../../../constants/membershipFormConstant';

// yup validation schema
const OtherInformationValidatonSchema = yup.object().shape({
  ...additionalDocumentsValidation,
  ...HumanResourcesFieldsValidation,
  ...FactoryOperationFieldsValidation,
  agree: yup.bool().oneOf([true], 'This field must be checked'),
});

const OtherInformationForm = ({ note, currentStep, setStep, defaultFields, documents }) => {
  const resolver = useYupValidationResolver(OtherInformationValidatonSchema);

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, defaultValues: { ...defaultFields } });

  const onForwardSubmit = async (data) => {
    const {
      noOfMale,
      noOfFemale,
      hoursPerShift,
      numberOfStaff,
      numberOfOfficers,
      numberOfWorkingDaysInAYear,
      numberOfShiftsPerWorkingDay,

      moa,
      tinCert,
      difeCert,
      additional,
      fireLicense,
      utilityBill,
      tradeLicense,
      leaseDocument,
      vatRegistration,
      environmentCert,
      registrationCert,
      managingDirector,
      incorporationCert,
      groupInsurancePolicy,
      machineryProcurement,
    } = data;

    const sendFormData = {
      noOfMale,
      noOfFemale,
      hoursPerShift,
      numberOfStaff,
      numberOfOfficers,
      numberOfWorkingDaysInAYear,
      numberOfShiftsPerWorkingDay,
    };

    const sendDocData = {
      moa,
      tinCert,
      difeCert,
      additional,
      fireLicense,
      utilityBill,
      tradeLicense,
      leaseDocument,
      vatRegistration,
      environmentCert,
      registrationCert,
      managingDirector,
      incorporationCert,
      groupInsurancePolicy,
      machineryProcurement,
    };

    // if there was no document data from the get request, then do a post request for documents
    if (!Object.values(documents).some((x) => x !== null && x !== '')) {
      // ******Human Resources*******
      if (_.isEqual(sendFormData, defaultFields) === false) {
        await patchMembership(OTHER_INFORMATION_FORM_SECTION, { ...sendFormData, note });
      }

      // ******Documents*******
      if (Object.values(sendDocData).some((x) => x !== null && x !== '' && x?.length !== 0)) {
        await postMembershipDocuments(sendDocData);
      }

      setStep(currentStep + 1);
    } else {
      // if formdata and documents are not updated
      if (
        _.isEqual(sendFormData, defaultFields) &&
        !Object.values(sendDocData).some((x) => x !== null && x !== '' && x?.length !== 0)
      ) {
        setStep(currentStep + 1);
      }

      // if only formdata is updated
      else if (
        _.isEqual(sendFormData, defaultFields) === false &&
        !Object.values(sendDocData).some((x) => x !== null && x !== '' && x?.length !== 0)
      ) {
        await patchMembership(OTHER_INFORMATION_FORM_SECTION, { ...sendFormData, note });
        setStep(currentStep + 1);
      }

      // if only documents are updated
      else if (
        _.isEqual(sendFormData, defaultFields) &&
        Object.values(sendDocData).some((x) => x !== null && x !== '' && x?.length !== 0)
      ) {
        await patchMembershipDocuments(sendDocData);
        setStep(currentStep + 1);
      }

      // both are updated
      else {
        await patchMembership(OTHER_INFORMATION_FORM_SECTION, { ...sendFormData, data });
        await patchMembershipDocuments(sendDocData);
        setStep(currentStep + 1);
      }
    }
  };

  const onBackwardSubmit = async (data) => {
    const {
      noOfMale,
      noOfFemale,
      hoursPerShift,
      numberOfStaff,
      numberOfOfficers,
      numberOfWorkingDaysInAYear,
      numberOfShiftsPerWorkingDay,
    } = data;
    const sendFormData = {
      noOfMale,
      noOfFemale,
      hoursPerShift,
      numberOfStaff,
      numberOfOfficers,
      numberOfWorkingDaysInAYear,
      numberOfShiftsPerWorkingDay,
    };
    // ******Human Resources*******
    if (_.isEqual(sendFormData, defaultFields) === false) {
      await patchMembership(OTHER_INFORMATION_FORM_SECTION, sendFormData);
      setStep(currentStep - 1);
    } else {
      setStep(currentStep - 1);
    }
    const {
      moa,
      tinCert,
      difeCert,
      additional,
      fireLicense,
      utilityBill,
      tradeLicense,
      leaseDocument,
      vatRegistration,
      environmentCert,
      registrationCert,
      managingDirector,
      incorporationCert,
      groupInsurancePolicy,
      machineryProcurement,
    } = data;
    const sendDocData = {
      moa,
      tinCert,
      difeCert,
      additional,
      fireLicense,
      utilityBill,
      tradeLicense,
      leaseDocument,
      vatRegistration,
      environmentCert,
      registrationCert,
      managingDirector,
      incorporationCert,
      groupInsurancePolicy,
      machineryProcurement,
    };

    // ******Documents*******
    // if the form was sent empty
    if (!Object.values(sendDocData).some((x) => x !== null && x !== '' && x?.length !== 0)) {
      setStep(currentStep - 1);
    }
    // if there was not any data from the get request do a post request
    else if (!Object.values(documents).some((x) => x !== null && x !== '')) {
      await postMembershipDocuments(sendDocData);
      setStep(currentStep - 1);
    }
    // otherwise patch
    else {
      await patchMembershipDocuments(sendDocData);
      setStep(currentStep - 1);
    }
  };

  return (
    <MembershipFormWrapper formName="Other Information">
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem colSpan={2}>
          <HumanResources register={register} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <FactoryOperation register={register} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <AdditionalDocumentsSection
            watch={watch}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            defaultFiles={documents}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <Text>
            <b>Note: </b>
            {note}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1} ml={4} mr={4}>
          <CheckBoxField
            control={control}
            errorMessage={errors['agree']?.message}
            name="agree"
            showText="I, hereby, pledge to abide by the Memorandum and Articles of Association of BTMA, and shall implement all decisions taken by the Board of Directors."
          />
        </GridItem>
      </Grid>
      <Stack flexDir="column" alignItems="flex-end">
        <HStack mt={3}>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onBackwardSubmit)();
            }}
            isLoading={isSubmitting}>
            PREVIOUS
          </Button>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onForwardSubmit)();
            }}
            isLoading={isSubmitting}>
            NEXT
          </Button>
        </HStack>
      </Stack>
    </MembershipFormWrapper>
  );
};

export default OtherInformationForm;
