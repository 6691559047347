import React from 'react';
import { Flex, Box, Text, Divider } from '@chakra-ui/react';
import SideNav from './SideNav';
import TopNav from './TopNav';
import './layoutStyle.css';

const Layout = ({ children }) => {
  return (
    <Flex className="app-container" w="100%" minH="100vh">
      <Box
        className="side-nav-container"
        bg="white"
        w="18%"
        h="100vh"
        zIndex={5}
        overflowY="auto"
        position="sticky"
        top="0px"
        left="0px"
        boxShadow="2px 0px 5px rgba(0,0,0,0.2)">
        <SideNav />
        <Box position="sticky" bg="white" w="100%" bottom="0px" p={0}>
          <Divider />
          <Text w="100%" textAlign="center" py={3} fontSize="sm">
            &copy; Copyright by BTMA
          </Text>
        </Box>
      </Box>
      <Box className="component-container" bg="secondary" w="82%">
        <TopNav />
        {children}
      </Box>
    </Flex>
  );
};

export default Layout;
