import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

import ApplyForMembership from './ApplyForMembership/ApplyForMembership';
import MembershipRenewal from './MembershipRenewal/MembershipRenewal';
import MillInformation from './MillInformation/MillInformation';
import NavPanel from '../common/NavPanel';
import { useSelector } from 'react-redux';
import { membershipRequestStatus } from '../../constants/membershipFormConstant';

const Membership = () => {
  const [selected, setSelected] = useState(0);
  const data = useSelector((state) => state.mill.mill);
  const navList = ['Apply for Membership', 'Mill Information', 'Membership Renewal'];

  useEffect(() => {
    if (data?.membershipStatus === membershipRequestStatus.APPROVED) {
      setSelected(1);
    }
  }, [data]);

  return (
    <Box p={6} bg="secondary">
      <NavPanel
        navState={selected}
        setNavState={setSelected}
        navList={navList}
        disabledState={data?.membershipStatus === membershipRequestStatus.APPROVED ? 0 : null}
      />

      {selected === 0 && <ApplyForMembership />}
      {selected === 1 && <MillInformation />}
      {selected === 2 && <MembershipRenewal />}
    </Box>
  );
};

export default Membership;
