import React from 'react';

import { Grid, GridItem, Stack, Text, Divider, HStack, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { useYupValidationResolver } from '../../../../util/yupUtils';

import _ from 'lodash';

// wrapper
import MembershipFormWrapper from '../../MembershipFormWrapper';
// Form Sections
import {
  MachineInformationSection,
  defaultMachineInformationFieldValues,
  MachineInformationFieldsValidation,
  AnnualCapacitySection,
  AnnualCapacityFieldsValidation,
  TypeOfTestingEquipmentSection,
  TypeOfTestingEquipmentFieldsValidation,
  defaultTypeOfTestingEquipmentFieldValues,
} from './sections';

// membershipapi
import { patchMembership } from '../../../../api/membership';
import { HOSIERY_KNITTING_MILLS_FORM_SECTION } from '../../../../constants/membershipFormConstant';

// yup validation schema
const HosieryknittingMillsValidatonSchema = yup.object().shape({
  ...MachineInformationFieldsValidation,
  ...AnnualCapacityFieldsValidation,
  ...TypeOfTestingEquipmentFieldsValidation,
});

const HosieryknittingMillForm = ({ currentStep, setStep, defaultFields }) => {
  const resolver = useYupValidationResolver(HosieryknittingMillsValidatonSchema);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      typeOfTestingEquipment: defaultFields.typeOfTestingEquipment?.length
        ? [...defaultFields.typeOfTestingEquipment]
        : [{ ...defaultTypeOfTestingEquipmentFieldValues }],
      machineInfo: defaultFields.machineInfo
        ? [...defaultFields.machineInfo]
        : [{ ...defaultMachineInformationFieldValues }],
    },
  });

  const onForwardSubmit = async (data) => {
    const cleanData = _.pickBy(data, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanData, cleanDefaultFields) === false) {
      await patchMembership(HOSIERY_KNITTING_MILLS_FORM_SECTION, data);
      setStep(currentStep + 1);
    } else {
      setStep(currentStep + 1);
    }
  };

  const onBackwardSubmit = async (data) => {
    const cleanData = _.pickBy(data, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanData, cleanDefaultFields) === false) {
      await patchMembership(HOSIERY_KNITTING_MILLS_FORM_SECTION, data);
      setStep(currentStep - 1);
    } else {
      setStep(currentStep - 1);
    }
  };

  return (
    <MembershipFormWrapper formName="Hosiery Knitting Mills">
      <Grid templateColumns="repeat(1, 1fr)" gap={2}>
        <GridItem colSpan={1}>
          <MachineInformationSection register={register} errors={errors} control={control} />
          <AnnualCapacitySection register={register} errors={errors} />
        </GridItem>
        <GridItem colSpan={1} mt={4}>
          <Text fontSize="xl" fontWeight="bold">
            List of Laboratory/Testing Equipment
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1}>
          <TypeOfTestingEquipmentSection register={register} errors={errors} control={control} />
        </GridItem>
        <GridItem colSpan={1}>
          <Divider />
        </GridItem>
      </Grid>
      <Stack flexDir="column" alignItems="flex-end">
        <HStack mt={3}>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onBackwardSubmit)();
            }}
            isLoading={isSubmitting}>
            PREVIOUS
          </Button>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onForwardSubmit)();
            }}
            isLoading={isSubmitting}>
            NEXT
          </Button>
        </HStack>
      </Stack>
    </MembershipFormWrapper>
  );
};

export default HosieryknittingMillForm;
