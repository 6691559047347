import {
  FETCH_PRODUCTION_CERTIFICATE_DATA,
  FETCH_PROCESSING_CERTIFICATE_DATA,
  FETCH_UNUSED_PRODUCTION_CERTIFICATES,
  FETCH_UNUSED_PROCESSING_CERTIFICATES,
  FETCH_PRODUCTION_CERTIFICATE_LOGS,
  FETCH_PRODUCTION_CERTIFICATE,
  FETCH_PROCESSING_CERTIFICATE_LOGS,
  FETCH_PROCESSING_CERTIFICATE,
  FETCH_DUTY_CERTIFICATE_LOGS,
  FETCH_DUTY_CERTIFICATE,
  FETCH_DUTY_CERTIFICATE_ALL,
  FETCH_UNUSED_DUTY_CERTIFICATES,
} from '../actions/actionTypes';

const initialState = {
  productionCertData: null,
  processingCertData: null,
  dutyCertData: null,
  unusedProdCertificates: null,
  unusedProcCertificates: null,
  unusedDutyCertificates: null,
  productionCertLogs: null,
  processingCertLogs: null,
  dutyCertLogs: null,
  currentCert: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTION_CERTIFICATE_DATA:
      return {
        ...state,
        productionCertData: action.payload,
      };
    case FETCH_PROCESSING_CERTIFICATE_DATA:
      return {
        ...state,
        processingCertData: action.payload,
      };
    case FETCH_UNUSED_PRODUCTION_CERTIFICATES:
      return {
        ...state,
        unusedProdCertificates: action.payload,
      };
    case FETCH_UNUSED_PROCESSING_CERTIFICATES:
      return {
        ...state,
        unusedProcCertificates: action.payload,
      };
    case FETCH_UNUSED_DUTY_CERTIFICATES:
      return {
        ...state,
        unusedDutyCertificates: action.payload,
      };
    case FETCH_PRODUCTION_CERTIFICATE_LOGS:
      return {
        ...state,
        productionCertLogs: action.payload,
      };
    case FETCH_PRODUCTION_CERTIFICATE:
      return {
        ...state,
        currentCert: action.payload,
      };
    case FETCH_PROCESSING_CERTIFICATE_LOGS:
      return {
        ...state,
        processingCertLogs: action.payload,
      };
    case FETCH_PROCESSING_CERTIFICATE:
      return {
        ...state,
        currentCert: action.payload,
      };
    case FETCH_DUTY_CERTIFICATE_LOGS:
      return {
        ...state,
        dutyCertLogs: action.payload,
      };
    case FETCH_DUTY_CERTIFICATE:
      return {
        ...state,
        currentCert: action.payload,
      };
    case FETCH_DUTY_CERTIFICATE_ALL:
      return {
        ...state,
        dutyCertData: action.payload,
      };
    default:
      return state;
  }
}
