import React, { useRef, useState } from 'react';
import {
  Box,
  Grid,
  Text,
  Stack,
  HStack,
  Button,
  Divider,
  useToast,
  GridItem,
  CloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

// common components
import FormButton from '../../common/FormButton';
import DialogueBox from '../../common/DialogueBox';

// form sections
import AdditionalDocumentSection, {
  additionalDocumentValidation,
} from './sections/AdditionalDocumentSection';
import RequiredInformationSection, {
  requiredInfoFieldsValidation,
} from './sections/RequiredInformationSection';

// api & actions
import { applyForDutyCertificate } from '../../../api/certificate';

// utils & consts
import _ from 'lodash';
import { formatDate } from '../../../util/formatUtils';
import { useYupValidationResolver } from '../../../util/yupUtils';

const DutyExemptionValidatonSchema = yup.object().shape({
  ...requiredInfoFieldsValidation,
  ...additionalDocumentValidation,
});

const DutyExemptionForm = ({ setNavState }) => {
  const toast = useToast();
  const toastIdRef = useRef();
  const [sendData, setSendData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for alert modal
  const alertString = 'Are you sure you want to submit this application?';

  const resolver = useYupValidationResolver(DutyExemptionValidatonSchema);

  const {
    reset,
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  const formatFormData = (data) => {
    let formData = new FormData();
    formData.append('blNo', data?.blNo);
    formData.append('value', data?.value);
    formData.append('invoice', data?.invoice);
    formData.append('category', data?.category);
    formData.append('itemName', data?.itemName);
    formData.append('masterLc', data?.masterLc);
    formData.append('currency', data?.currency);
    formData.append('quantity', data?.quantity);
    formData.append('blDate', formatDate(data?.blDate));
    formData.append('dateOfLc', formatDate(data?.dateOfLc));
    formData.append('invoiceDate', formatDate(data?.invoiceDate));
    formData.append('additionalDocuments', data?.additionalDocuments[0]);

    return formData;
  };

  const onSubmit = (data) => {
    setSendData(data);
    onOpen();
  };

  const CustomToast = () => (
    <HStack color="white" p={3} bg="green.500" borderRadius="md" alignItems="end">
      <Text maxWidth="220px">
        Successfully submitted! Please visit BTMA office to pay Tk. 100 for blank certificate and
        start the approval process.
      </Text>
      <Button
        fontSize="md"
        height="35px"
        variant="outline"
        _hover={{ bg: 'green.400' }}
        onClick={() => {
          setNavState(2);
          toast.closeAll();
        }}>
        View Details
      </Button>

      <CloseButton size="sm" onClick={() => toast.closeAll()} alignSelf="flex-start" />
    </HStack>
  );

  const showCustomToast = () => {
    toastIdRef.current = toast({
      position: 'bottom-right',
      duration: null,
      render: CustomToast,
    });
  };

  const confirmSubmit = async () => {
    const res = await applyForDutyCertificate(formatFormData(sendData));

    if (res.data) {
      let resetObj = {};
      _.mapKeys(sendData, (value, key) => (resetObj[key] = ''));
      reset(resetObj);
      showCustomToast();
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} mt={6}>
      <Stack bg="white" p={3} mt={2} borderRadius="4px">
        <Text fontSize="20" mb="3" ml="3" fontWeight="bold">
          Certificate of Duty Exemption
        </Text>
        <Grid templateColumns="repeat(1, 1fr)" colSpan={2} mt={1} ml={2} mr={2} gap={2}>
          <GridItem colSpan={1}>
            <Divider />
          </GridItem>
          <GridItem colSpan={1}>
            <RequiredInformationSection register={register} errors={errors} control={control} />
          </GridItem>
          <GridItem colSpan={1}>
            <AdditionalDocumentSection
              register={register}
              errors={errors}
              control={control}
              watch={watch}
            />
          </GridItem>
        </Grid>
      </Stack>
      <Stack flexDir="column" alignItems="flex-end">
        <FormButton type="submit" rightIcon={null} isLoading={isSubmitting}>
          SUBMIT
        </FormButton>
      </Stack>
      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        alertMessage={alertString}
        performAction={confirmSubmit}
      />
    </Box>
  );
};

export default DutyExemptionForm;
