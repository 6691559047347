//modules
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Divider, Box, Grid, GridItem, Stack, useDisclosure } from '@chakra-ui/react';

//common components
import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import CheckBoxField from '../../../common/hookFormComponents/CheckBoxField';

//form sections

import DocumentSection, {
  documentSectionValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/DocumentSection';
import ItemsInformation, {
  defaultItemFieldValues,
  itemsInformationValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/ItemsInformation';
import GspExpiryInformation, {
  gspExpInformationSectionValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/GspExpiryInformation';
import CashExpiryInformation, {
  cashExpInformationSectionValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/CashExpiryInformation';
import CerGeneralInformation, {
  cerGenInfoSectionValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/CerGeneralInformation';
import MillInformationSection, {
  millInformationSectionValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/MillInformationSection';
import GspAdditionalDocumentsSection, {
  gspAdditionalDocumentValidationAlt,
} from '../../CompleteCertificates/CertificateApplicationForm/GspAdditionalDocuments';
import CashAdditionalDocumentsSection, {
  cashAdditionalDocumentValidationAlt,
} from '../../CompleteCertificates/CertificateApplicationForm/CashAdditionalDocuments';
import YarnCashAssistanceSection, {
  yarnCashAssistanceFieldsValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/YarnCashAssistanceSection';

//utility & actions
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { patchProductionCertificate } from '../../../../api/certificate';
import _ from 'lodash';

const ApplicationPatchForm = ({ refresh, closeModal }) => {
  const dispatch = useDispatch();
  const [applicationData, setApplicationData] = useState();
  const data = useSelector((state) => state.certificate.currentCert);
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for alert modal

  const getDefaultFiles = () => {
    if (data?.certType === 'CASH') {
      return {
        declarationOne: data?.declarationOne,
        lcBankDocuments: data?.lcBankDocuments,
        proformaInvoice: data?.proformaInvoice,
        declarationThree: data?.declarationThree,
      };
    } else {
      return {
        vatChallan: data?.vatChallan,
        purchaseOrder: data?.purchaseOrder,
        lcCertificate: data?.lcCertificate,
        lcBankDocuments: data?.lcBankDocuments,
        proformaInvoice: data?.proformaInvoice,
        deliveryChallan: data?.deliveryChallan,
        commercialInvoice: data?.commercialInvoice,
      };
    }
  };

  const getConditionalFields = () => {
    if (data?.certType === 'CASH') {
      return {
        mushakNum: data?.mushakNum,
        mushakDate: data?.mushakDate,
        totalValueInUSD: data?.totalValueInUSD,
      };
    } else {
      return {
        exciseGatePassNum: data?.exciseGatePassNum,
        exciseGatePassDate: data?.exciseGatePassDate,
      };
    }
  };

  //filters default fields so that only currently active fields are passed inside the form
  const defaultFields = {
    //general information
    masterLc: data?.masterLc,
    dateOfLc: data?.dateOfLc,
    totalQuantity: data?.totalQuantity,
    expiryDateOfLc: data?.expiryDateOfLc,

    //documentSection
    bank: data?.bank,
    btbLc: data?.btbLc,
    btbLcDate: data?.btbLcDate,
    bankBranch: data?.bankBranch,
    valueInUSD: data?.valueInUSD,

    //invoice
    invoice: data?.invoice,
    invoiceDate: data?.invoiceDate,
    deliveryDate: data?.deliveryDate,

    //millInfo
    suppliedTo: data?.suppliedTo,
    annualProdCapacity: data?.annualProdCapacity,
    nameOfMillAndAddress: data?.nameOfMillAndAddress,

    //fields depending on certificate type
    ...getConditionalFields(),
  };

  const getValidationSchema = () => {
    if (data?.certType === 'CASH' && data?.productType === 'YARN') {
      return yup.object().shape({
        ...documentSectionValidation,
        ...itemsInformationValidation,
        ...cerGenInfoSectionValidation,
        ...millInformationSectionValidation,
        ...yarnCashAssistanceFieldsValidation,
        ...cashExpInformationSectionValidation,
        ...cashAdditionalDocumentValidationAlt,
        agree: yup.bool().oneOf([true], 'This field must be checked'),
      });
    } else if (data?.certType === 'CASH') {
      return yup.object().shape({
        ...documentSectionValidation,
        ...itemsInformationValidation,
        ...cerGenInfoSectionValidation,
        ...millInformationSectionValidation,
        ...cashAdditionalDocumentValidationAlt,
        ...cashExpInformationSectionValidation,
        agree: yup.bool().oneOf([true], 'This field must be checked'),
      });
    } else if (data?.certType === 'GSP') {
      return yup.object().shape({
        ...documentSectionValidation,
        ...itemsInformationValidation,
        ...cerGenInfoSectionValidation,
        ...millInformationSectionValidation,
        ...gspAdditionalDocumentValidationAlt,
        ...gspExpInformationSectionValidation,
        agree: yup.bool().oneOf([true], 'This field must be checked'),
      });
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    watch,
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      items: data.productDetails?.length ? [...data.productDetails] : [...defaultItemFieldValues],
    },
  });

  const watchAllFields = watch();

  const {
    // eslint-disable-next-line no-unused-vars
    agree,
    items,
    vatChallan,
    purchaseOrder,
    lcCertificate,
    declarationOne,
    lcBankDocuments,
    proformaInvoice,
    deliveryChallan,
    declarationThree,
    commercialInvoice,
    ...rest
  } = watchAllFields;

  const isFileUpdated = () => {
    let fileUpdateFlag = false;
    let files = {
      vatChallan,
      purchaseOrder,
      lcCertificate,
      declarationOne,
      lcBankDocuments,
      proformaInvoice,
      deliveryChallan,
      declarationThree,
      commercialInvoice,
    };
    for (const key in files) {
      if (files?.[key]?.length > 0) {
        fileUpdateFlag = true;
      }
    }
    return fileUpdateFlag;
  };

  const checkUpdate = () => {
    return (
      _.isEqual({ ...rest }, { ...defaultFields }) &&
      _(items).differenceWith(data?.productDetails, _.isEqual).isEmpty() &&
      !isFileUpdated()
    );
  };

  const onSubmit = (formData) => {
    if (!checkUpdate()) {
      setApplicationData({ ...formData, product: data?.productType });
      onOpen();
    }
  };

  const confirmSubmit = async () => {
    const res = await patchProductionCertificate(
      { ...applicationData },
      {
        certId: data.certificateNum,
        certType: data?.certType,
      },
    );
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
    }
    closeModal();
  };

  return (
    <Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Stack bg="white" p={2}>
            <Grid templateColumns="repeat(2, 1fr)" gap={2} mb={4}>
              <GridItem colSpan={2}>
                <CerGeneralInformation register={register} control={control} errors={errors} />
                {data?.certType === 'GSP' ? (
                  <GspExpiryInformation register={register} control={control} errors={errors} />
                ) : data?.certType === 'CASH' ? (
                  <CashExpiryInformation register={register} control={control} errors={errors} />
                ) : null}
              </GridItem>
            </Grid>
          </Stack>

          <Stack bg="white" p={2}>
            <Grid templateColumns="repeat(2, 1fr)" gap={2} mb={4}>
              <GridItem colSpan={2}>
                <DocumentSection register={register} control={control} errors={errors} />
              </GridItem>
            </Grid>
          </Stack>

          <Stack bg="white" p={2}>
            <Grid templateColumns="repeat(2, 1fr)" gap={2} mb={4}>
              <GridItem colSpan={2}>
                <ItemsInformation
                  sectionName={
                    data?.productType === 'YARN' ? 'Yarn Information' : 'Fabric Information'
                  }
                  register={register}
                  control={control}
                  errors={errors}
                />
              </GridItem>
            </Grid>
          </Stack>

          {data?.certType === 'CASH' && data?.productType === 'YARN' ? (
            <Stack bg="white" p={2}>
              <Grid templateColumns="repeat(2, 1fr)" gap={2} mb={4}>
                <GridItem colSpan={2}>
                  <YarnCashAssistanceSection
                    register={register}
                    control={control}
                    errors={errors}
                  />
                </GridItem>
              </Grid>
            </Stack>
          ) : null}

          <Stack bg="white" p={2}>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <GridItem colSpan={2} mt={2} mx={1}>
                <MillInformationSection register={register} errors={errors} getValues={getValues} />
              </GridItem>
            </Grid>
          </Stack>

          {data?.certType === 'CASH' ? (
            <Stack bg="white" p={2}>
              <Divider />
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <GridItem colSpan={2} mt={1} mb={7} mx={1}>
                  <CashAdditionalDocumentsSection
                    watch={watch}
                    register={register}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    defaultFiles={getDefaultFiles()}
                  />
                </GridItem>
              </Grid>
            </Stack>
          ) : data?.certType === 'GSP' ? (
            <Stack bg="white" p={2}>
              <Divider />
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <GridItem colSpan={2} mt={1} mb={7} mx={1}>
                  <GspAdditionalDocumentsSection
                    watch={watch}
                    register={register}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    defaultFiles={getDefaultFiles()}
                  />
                </GridItem>
              </Grid>
            </Stack>
          ) : null}

          <Stack bg="white" p={2}>
            <Divider />
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <GridItem colSpan={1}>
                <CheckBoxField
                  errorMessage={errors['agree']?.message}
                  control={control}
                  name="agree"
                  showText="I, the undersigned, Secretary General of Bangladesh Textile Mills Association (BTMA) Dhaka hereby certify that the above declaration was made before me. As far as it can be ascertained from documentary proof submitted, the above goods have been produced/manufactured in Bangladesh."
                />
              </GridItem>
            </Grid>
          </Stack>

          <Stack bg="white" flexDir="column" alignItems="flex-end" p={4}>
            <FormButton type="submit" isLoading={isSubmitting} isDisabled={checkUpdate()}>
              Re-Submit
            </FormButton>
          </Stack>
        </Box>

        <DialogueBox
          secondaryMount
          mountControl={isOpen}
          close={onClose}
          alertTitle="Confirm"
          alertMessage="Are you sure you want to re-submit this application?"
          performAction={confirmSubmit}
        />
      </Box>
    </Box>
  );
};

export default ApplicationPatchForm;
