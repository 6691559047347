const Button = {
  baseStyle: {
    borderRadius: '2px',
    fontWeight: 'semibold',
    _focus: { boxShadow: '0 0 0 2px #9280ff' },
  },
  sizes: {
    xl: {
      h: '56px',
      px: '32px',
      fontSize: 'lg',
    },
  },
  variants: {
    formNext: () => ({
      size: 'lg',
      minW: '200px',
      color: '#fff',
      fontSize: 'md',
      variant: 'solid',
      bg: 'primary.700',
      borderRadius: '2px',
      _hover: { bg: 'primary.400' },
      height: ['45px', '45px', '45px', '50px', '55px'],
    }),
    tabBarTab: () => ({
      bg: 'white',
      lineHeight: 1,
      fontSize: 'md',
      isFullWidth: true,
      borderRadius: '0px',
      whiteSpace: 'normal',
      color: 'primary.700',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'primary.700',
        bg: 'primary.50',
        boxShadow: '2px 2px 2px rgba(0,0,0,0.2)',
      },
    }),
    sideNavTab: () => ({
      width: '100%',
      minHeight: '40px',
      textAlign: 'left',
      whiteSpace: 'normal',
      height: 'fit-content',
      color: 'textSecondary',
      justifyContent: 'flex-start',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'primary.700',
        bgColor: 'primary.50',
      },
      _hover: {
        color: 'primary.700',
        bgColor: 'rgba(233, 228, 255, 0.4)',
      },
    }),
    logOut: () => ({
      height: '40px',
      color: '#E53E3E',
      justifyContent: 'left',
      _active: {
        bg: 'red.200',
      },
      _hover: {
        bg: '#E53E3E',
        color: 'white',
      },
      _focus: {
        boxShadow: 'none',
      },
    }),
    modalAction: () => ({
      minW: '100px',
      color: '#fff',
      bg: 'primary.700',
      borderRadius: '2px',
      _hover: { bg: 'primary.400' },
    }),
    tableAction: () => ({
      size: 'lg',
      bg: 'white',
      borderRadius: '4px',
      color: 'primary.300',
      border: '2px solid #674dfe',
      _focus: { boxShadow: 'none' },
      _hover: { bg: 'primary.300', color: 'white' },
    }),
    subtle: () => ({
      mt: 2,
      minH: '35px',
      color: '#fff',
      height: '4.5vh',
      maxW: 'fit-content',
      borderRadius: '2px',
      bgColor: 'textSecondary',
      justifyContent: 'flex-start',
      _focus: { boxShadow: 'none' },
      _hover: {
        color: '#fff',
        bg: 'rgba(107, 121, 130, 0.7)',
      },
    }),
    scrollIcon: () => ({
      color: 'white',
      fontSize: 'xl',
      borderRadius: '50%',
      bgColor: 'textSecondary',
      _focus: { boxShadow: 'none' },
      _hover: {
        color: '#fff',
        bg: 'rgba(107, 121, 130, 0.7)',
      },
      boxShadow: '2px 2px 3px rgba(0,0,0,0.3)',
    }),
    ghost: () => ({
      color: 'textSecondary',
      _active: {
        color: 'primary.700',
        bg: 'secondary',
        borderColor: 'primary.200',
      },
      _focus: {
        borderColor: 'primary.200',
      },
    }),
    solid: () => ({
      color: '#fff',
      bg: 'primary.700',
      _hover: { bg: 'primary.400' },
    }),
    'with-shadow': {
      bg: 'red.400',
      boxShadow: '0 0 2px 2px #efdfde',
    },
  },
};

export default Button;
