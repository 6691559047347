import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Box,
  Stack,
  Text,
  VStack,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Button,
  Icon,
} from '@chakra-ui/react';
import { getMembershipCost } from '../../../api/membership';
import Loader from '../../snippets/Loader';
import FormButton from '../../common/FormButton';
import CostBreakDown from './CostBreakDown';
import { renderDate } from '../../../util/formatUtils';
import MembershipBill from './MembershipBill';
import { useSelector } from 'react-redux';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import commaNumber from 'comma-number';

const SubscriptionCost = ({ renewal }) => {
  const [data, setData] = useState();
  const [membershipType, setMembershipType] = useState('ASSOCIATE');
  const member = useSelector((state) => state.mill.mill);
  const membershipBillRef = useRef();
  const pricingA = data?.pricing?.factorsA;
  const pricingB = data?.pricing?.factorsB;
  const pricingC = data?.pricing?.factorsC;

  useEffect(() => {
    if (!renewal) {
      if (member) {
        (async () => {
          const res = await getMembershipCost();
          if (res.data?.status === 200) {
            setData(res.data);
          }
        })();
      }
    } else {
      setData(member?.billingData);
    }
  }, [member]);

  useEffect(() => {
    if (data) {
      let memType = 'ASSOCIATE';
      const { yarnInfo, fabricInfo, textileInfo } = data?.summary;

      if (yarnInfo?.spindlesInsCap >= 12500) {
        memType = 'GENERAL';
      }
      if (yarnInfo?.rotorsInsCap >= 3000) {
        memType = 'GENERAL';
      }
      if (fabricInfo?.shuttleInsCap >= 100) {
        memType = 'GENERAL';
      }
      if (fabricInfo?.shuttleLessInsCap >= 40) {
        memType = 'GENERAL';
      }
      if (textileInfo?.texFabricKnitProcCap >= 1000000) {
        memType = 'GENERAL';
      }
      if (textileInfo?.texFabricWovenProcCap >= 3000000) {
        memType = 'GENERAL';
      }
      if (textileInfo?.texYarnProcCap >= 1000000) {
        memType = 'GENERAL';
      }

      setMembershipType(memType);
    }
  }, [data]);

  const getArrearYear = () => {
    let year = new Date().getFullYear();
    let month = new Date().getMonth();

    return month > 6 ? `01-Jun-${year}` : `01-Jun-${year - 1}`;
  };

  return data && member ? (
    <Box borderRadius="4px">
      <HStack mb={4} w="100%" justifyContent="right" alignItems="flex-start">
        <Box pt={10}>
          <Menu>
            <MenuButton as={Button} rightIcon={<Icon as={AiOutlineInfoCircle} w={5} h={5} />}>
              Current Costings
            </MenuButton>
            <MenuList>
              <VStack p={4} alignItems="flex-start">
                <Text>
                  <b>Spindles:</b>{' '}
                  <em>
                    Unit price for first {pricingA?.spindleThreshold} is Tk.{' '}
                    {pricingA?.unitPriceSpindleX} and above {pricingA?.spindleThreshold} is Tk.{' '}
                    {pricingA?.unitPriceSpindleY}
                  </em>
                </Text>
                <Text>
                  <b>Rotors:</b>{' '}
                  <em>
                    Unit price for first {pricingA?.rotorThreshold} is Tk.{' '}
                    {pricingA?.unitPriceRotorX} and above {pricingA?.rotorThreshold} is Tk.{' '}
                    {pricingA?.unitPriceRotorY}
                  </em>
                </Text>
                <Text>
                  <b>Shuttle:</b>{' '}
                  <em>
                    Unit price for first {pricingB?.shuttleThreshold} is Tk.{' '}
                    {pricingB?.unitPriceShuttleX} and above {pricingB?.shuttleThreshold} is Tk.{' '}
                    {pricingB?.unitPriceShuttleY}
                  </em>
                </Text>
                <Text>
                  <b>Shuttleless:</b>{' '}
                  <em>
                    Unit price for first {pricingB?.shuttleLessThreshold} is Tk.{' '}
                    {pricingB?.unitPriceShuttleLessX} and above {pricingB?.shuttleLessThreshold} is
                    Tk. {pricingB?.unitPriceShuttleLessY}
                  </em>
                </Text>
                <Text>
                  <b>Knitting:</b>{' '}
                  <em>
                    Cost for first {pricingB?.knitThreshold}Kg is Tk. {pricingB?.unitPriceKnitX} and
                    above
                    {pricingB?.knitThreshold}Kg is Tk. {pricingB?.unitPriceKnitY} per{' '}
                    {pricingB?.unitFactorKnit}kg
                  </em>
                </Text>
                <Text>
                  <b>Yarn:</b>{' '}
                  <em>
                    Cost for first {pricingC?.yarnThreshold}m is Tk. {pricingC?.unitPriceYarnX} and
                    above {pricingC?.yarnThreshold}m is Tk. {pricingC?.unitPriceYarnY} per{' '}
                    {pricingC?.unitFactorYarn}kg
                  </em>
                </Text>
                <Text>
                  <b>Fabric (knit):</b>{' '}
                  <em>
                    Cost for first {pricingC?.tKnitThreshold}m is Tk. {pricingC?.unitPriceTKnitX}{' '}
                    and above {pricingC?.tKnitThreshold}m is Tk. {pricingC?.unitPriceTKnitY} per{' '}
                    {pricingC?.unitFactorTKnit}kg
                  </em>
                </Text>
                <Text>
                  <b>Fabric (woven):</b>{' '}
                  <em>
                    Cost for first {pricingC?.wovenThreshold}m is Tk. {pricingC?.unitPriceWovenX}{' '}
                    and above {pricingC?.wovenThreshold}m is Tk. {pricingC?.unitPriceWovenY} per{' '}
                    {pricingC?.unitFactorWoven}kg
                  </em>
                </Text>
              </VStack>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
      <CostBreakDown
        renewal={renewal}
        data={
          renewal
            ? {
                ...data,
                costs: {
                  ...data?.costs,
                  totalWithArrear: commaNumber(data?.costs?.total + data?.costs?.previousDue),
                },
                arrear: {
                  upto: getArrearYear(),
                  amount: parseInt(data?.costs?.previousDue),
                },
                membershipType: membershipType,
              }
            : { ...data, membershipType: membershipType }
        }
      />
      <Stack flexDir="column" alignItems="flex-end">
        <ReactToPrint
          documentTitle={`BTMA-Bill-Membership-${renewal ? 'Renewal' : 'Subscription'}-${
            data?.summary?.uid
          }-${renderDate(new Date())}`}
          trigger={() => <FormButton>PRINT BILL</FormButton>}
          content={() => membershipBillRef.current}
        />
        <Box display="none">
          <MembershipBill
            title={`Membership ${renewal ? 'Renewal' : 'Subscription'}`}
            ref={membershipBillRef}
            data={{
              billNo: data?.summary?.uid,
              millName: data?.summary?.millName,
              millAddress: data?.summary?.millAddress,
            }}
            costs={
              renewal
                ? {
                    ...data,
                    costs: {
                      ...data?.costs,
                      totalWithArrear: commaNumber(data?.costs?.total + data?.costs?.previousDue),
                    },
                    arrear: {
                      upto: getArrearYear(),
                      amount: parseInt(data?.costs?.previousDue),
                    },
                    membershipType: membershipType,
                  }
                : { ...data, membershipType: membershipType }
            }
          />
        </Box>
      </Stack>
    </Box>
  ) : (
    <Loader />
  );
};

export default SubscriptionCost;
