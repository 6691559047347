import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'typeAndNoOfMachine',
    type: 'text',
    label: 'Type & No. of Machine',
  },
  {
    colSpan: 1,
    name: 'countryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'yearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'option',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },
];

export const defaultTypeOfTestingEquipmentFieldValues = fields.reduce(
  (obj, item) => ({ ...obj, [item['name']]: '' }),
  {},
);

export const TypeOfTestingEquipmentFieldsValidation = {
  typeAndConstructionOfFabric: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string().required(),
      [fields[1].name]: yup.string().required(),
      [fields[2].name]: yup.string().required(),
      [fields[3].name]: yup.string().required(),
    }),
  ),
};

const TypeOfTestingEquipmentSection = (props) => {
  return (
    <FieldArrayFormSection
      sectionName="Type and Description of Testing Equipments"
      sectionColumn={4}
      name="typeOfTestingEquipment"
      formFields={fields}
      {...props}
    />
  );
};

export default TypeOfTestingEquipmentSection;
