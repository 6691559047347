import React from 'react';
import { useSelector } from 'react-redux';
import MembershipInfoWrapper from '../MembershipInfoWrapper';
import InfoSection from '../../../common/InfoSection';

const PowerManagementSection = () => {
  const mill = useSelector((state) => state.mill.mill);
  const data = mill?.operationInfo;

  const gasFields = [
    { header: 'Number', value: data?.powerManagementSummary?.numberGas },
    { header: 'Capacity', value: data?.powerManagementSummary?.capacityGas },
    { header: 'Country of Origin', value: data?.powerManagementSummary?.countryOfOriginGas },
  ];
  const dieselFields = [
    { header: 'Number', value: data?.powerManagementSummary?.numberDiesel },
    { header: 'Capacity', value: data?.powerManagementSummary?.capacityDiesel },
    { header: 'Country of Origin', value: data?.powerManagementSummary?.countryOfOriginDiesel },
  ];
  const otherFields = [
    { header: 'Capacity', value: data?.powerManagementSummary?.capacityOtherFuel },
    { header: 'Country of Origin', value: data?.powerManagementSummary?.countryOfOriginOtherFuel },
  ];
  const sancFields = [
    { header: 'Electricity (Kilowatt)', value: data?.powerManagementSummary?.sancElectricity },
    { header: 'Gas', value: data?.powerManagementSummary?.sancGas },
  ];

  return (
    <MembershipInfoWrapper name="Power Management">
      <InfoSection sectionName="Gas" infoFields={gasFields} />
      <InfoSection sectionName="Diesel" infoFields={dieselFields} />
      <InfoSection sectionName="Other Fuel" infoFields={otherFields} />
      <InfoSection sectionName="Sanction Load" infoFields={sancFields} />
    </MembershipInfoWrapper>
  );
};

export default PowerManagementSection;
