import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'mushakNum',
    type: 'text',
    label: 'Mushak No.',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'mushakDate',
    label: 'Mushak Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'totalValueInUSD',
    type: 'text',
    label: 'Total Value (In US$)',
    isRequired: true,
  },
];

export const cashExpInformationSectionValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: dateValidation.required().label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
};

const CashExpiryInformation = (props) => {
  return <FormSection secondary sectionColumn={3} formFields={fields} {...props} />;
};

export default CashExpiryInformation;
