import React from 'react';
import { Box } from '@chakra-ui/react';

// import PurchaseInformationForm from './PurchaseInformationForm/PurchaseInformationForm';
import PurchaseHistoryTable from './PurchaseHistoryTable/PurchaseHistoryTable';

const PurchaseCertificate = () => {
  return (
    <Box>
      {/* <PurchaseInformationForm /> */}
      <PurchaseHistoryTable />
    </Box>
  );
};

export default PurchaseCertificate;
