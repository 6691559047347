import React from 'react';
import * as yup from 'yup';
import { mobileNoValidation } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 2,
    name: 'chairmanName',
    type: 'text',
    isRequired: true,
    label: 'Name of Chairman',
  },
  {
    colSpan: 1,
    name: 'chairmanOfficeTelephone',
    type: 'text',
    isRequired: true,
    label: 'Office Telephone',
  },
  {
    colSpan: 1,
    name: 'chairmanResidenceTelephone',
    type: 'text',
    isRequired: true,
    label: 'Residence Telephone',
  },
  {
    colSpan: 1,
    name: 'chairmanMobileNo',
    type: 'text',
    isRequired: true,
    label: 'Mobile Number',
  },
  {
    colSpan: 1,
    name: 'chairmanEmail',
    type: 'email',
    isRequired: true,
    label: 'Email Address',
  },
];

export const chairmanInfoFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: mobileNoValidation.required().label(fields[1].label),
  [fields[2].name]: mobileNoValidation.required().label(fields[2].label),
  [fields[3].name]: mobileNoValidation.required().label(fields[3].label),
  [fields[4].name]: yup.string().email().required().label(fields[4].label),
};

const ChairmanInformationSection = (props) => {
  return (
    <FormSection
      sectionName="Chairman Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default ChairmanInformationSection;
