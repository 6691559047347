import React from 'react';
import { ModalHeader, ModalBody, Text, Divider } from '@chakra-ui/react';
import InfoSection from '../../../common/InfoSection';
import Loader from '../../../snippets/Loader';
import { renderDate } from '../../../../util/formatUtils';
import { useSelector } from 'react-redux';

const CertificatePreview = ({ type = 'PROCESSING' }) => {
  const data = useSelector((state) => state.certificate.currentCert);

  const generalInfoFields = [
    {
      header: 'Certificate No.',
      value: data?.certificateNum,
    },
    {
      header: 'Category',
      value: data?.category,
    },
    {
      header: 'Item Name',
      value: data?.itemName,
    },
    {
      header: 'L/C No.',
      value: data?.masterLc,
    },
    {
      header: 'L/C amount',
      value: data?.value,
    },
    {
      header: 'Currency',
      value: data?.currency,
    },
    {
      header: 'Quantity in KG',
      value: data?.quantity,
    },
    {
      header: 'L/C Date',
      value: renderDate(data?.dateOfLc),
    },
    {
      header: 'BL/Airway Bill/Truck Receipt No.',
      value: data?.blNo,
    },
    {
      header: 'BL Date',
      value: renderDate(data?.blDate),
    },
    {
      header: 'Invoice No.',
      value: data?.invoice,
    },
    {
      header: 'Invoice Date',
      value: renderDate(data?.invoiceDate),
    },
  ];

  return (
    <>
      <ModalHeader fontSize="lg" fontWeight="bold" textTransform="uppercase">
        <Text fontSize="lg" fontWeight="bold">
          Certificate of {type}
        </Text>
        <Divider />
      </ModalHeader>
      {data ? (
        <ModalBody>
          <InfoSection sectionName="General Information" infoFields={generalInfoFields} />
        </ModalBody>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CertificatePreview;
