import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';

import Loader from '../snippets/Loader';
import ErrorView from './csvUploader/ErrorView';
import UploadData from './csvUploader/UploadData';
import LabourTable from './tableViews/LabourTable';
import { getLabourData } from '../../api/labourer';
import PreviewTable from './tableViews/PreviewTable';
import GuideLinesTable from './tableViews/GuideLinesTable';
import { FETCH_LABOUR_DATA } from '../../store/actions/actionTypes';

const LabourDatabase = () => {
  const underConstruction = true;
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [view, setView] = useState();
  const [tempData, setTempData] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getLabourData({ page: 1, pageSize: 10 });
      if (res.data?.labourData?.length > 0) {
        dispatch({ type: FETCH_LABOUR_DATA, payload: res.data });
        setView('TABLE');
      } else {
        setView('UPLOAD');
      }
    };

    fetchData();
  }, []);

  const renderView = () => {
    switch (view) {
      case 'LOADER':
        return <Loader />;
      case 'UPLOAD':
        return (
          <>
            <UploadData
              setView={setView}
              setData={setData}
              setTempData={setTempData}
              setPreview={setPreview}>
              {preview === 'PREVIEW' ? null : <GuideLinesTable />}
            </UploadData>
            {preview === 'PREVIEW' ? (
              <PreviewTable data={data} setView={setView} />
            ) : preview === 'ERROR' ? (
              <ErrorView errors={data} data={tempData} preview={preview} />
            ) : null}
          </>
        );
      case 'TABLE':
        return <LabourTable setView={setView} />;
      case 'REUPLOAD':
        return (
          <>
            <UploadData
              reUpload={true}
              setView={setView}
              setData={setData}
              setTempData={setTempData}
              setPreview={setPreview}>
              {preview === 'PREVIEW' ? null : <GuideLinesTable />}
            </UploadData>
            {preview === 'PREVIEW' ? (
              <PreviewTable reUpload={true} data={data} setView={setView} />
            ) : preview === 'ERROR' ? (
              <ErrorView errors={data} data={tempData} preview={preview} />
            ) : null}
          </>
        );
      default:
        return <Loader />;
    }
  };

  const renderMaintenancePreview = () => {
    return (
      <Box p={6}>
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <AlertTitle>Labour database system is currently under maintenance!</AlertTitle>
          <AlertDescription>
            Please contact BTMA office to manage your labour database.
          </AlertDescription>
        </Alert>
      </Box>
    );
  };

  return underConstruction ? renderMaintenancePreview() : renderView();
};

export default LabourDatabase;
