import React from 'react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { Box, VStack, Flex, Text, Button, HStack, useDisclosure } from '@chakra-ui/react';

import Loader from '../../snippets/Loader';
import SubscriptionCost from './SubscriptionCost';
import DialogueBox from '../../common/DialogueBox';

import { useDispatch, useSelector } from 'react-redux';
import { getDashboardHome } from '../../../api/dashboard';
import { setMill } from '../../../store/actions/millActions';
import { notify } from '../../../store/actions/globalActions';
import { updateMembershipStatus } from '../../../api/membership';
import { membershipRequestStatus } from '../../../constants/membershipFormConstant';

const ApplicationStatus = ({ setStep, defaultStep, step3Label, step4Label }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for alert modal
  const data = useSelector((state) => state.mill.mill);

  const recorded = (
    <Flex py={4}>
      <Text p={1}>
        Your membership application has been recorded. You can now make revisions by clicking on the
        ‘Edit Application’ button. If you click on the ‘Submit for verification’ button, you will no
        longer be able to edit your submission. Your submission might take 3-5 working days to
        verify.
      </Text>
    </Flex>
  );

  const submitted = (
    <Flex py={4}>
      <Text p={1}>
        Your membership application has been successfully submitted and will be reviewed and
        verified by BTMA officials. This step might take 3-5 working days to complete. You will be
        notified via email on the next steps.
      </Text>
    </Flex>
  );

  const inReview = (
    <Flex py={4}>
      <Text p={1}>
        Your membership application is currently in review. You will be notified via email on the
        next steps once the review is complete.
      </Text>
    </Flex>
  );

  const rjected = (
    <Flex py={4}>
      <Text p={1}>
        Unfortunately, your membership application was rejected. You can apply again anytime. If you
        think there was a mistake, feel free to reach us at BTMA office, thank you.
      </Text>
    </Flex>
  );

  const rework = data ? (
    <>
      <Flex py={4}>
        <Text p={1}>
          Your membership application was reviewed and it requires some rework. Please, consider
          re-submitting your application after completing necessary changes.
        </Text>
      </Flex>
      <Text fontSize="lg">
        <b>Reason for rework: </b>
        {data.remark}
      </Text>
    </>
  ) : (
    <Loader />
  );

  const awatingPayement = (
    <Flex py={4}>
      <Text p={1}>
        Your membership application is verified. Please pay your membership subsciption fee at BTMA
        to avail membership. (‘General Members’ need to pay the yearly subsciption fee in two
        installments and ‘Associate Members’ need to pay the yearly subsciption fee at once.)
      </Text>
    </Flex>
  );

  const steps = [
    { label: 'Recorded', content: null },
    { label: 'Submitted', content: recorded },
    {
      label: step3Label || 'Awaiting Verification',
      content:
        step3Label === 'In Review'
          ? inReview
          : step3Label === 'Rework'
          ? rework
          : step3Label === 'Rejected'
          ? rjected
          : submitted,
    },
    { label: step4Label || 'Awaiting Payment', content: awatingPayement },
    { label: 'Approved', content: null },
  ];

  const { nextStep, activeStep } = useSteps({
    initialStep: defaultStep || 1,
  });

  const handleSubmit = async () => {
    const res = await updateMembershipStatus({ status: membershipRequestStatus.APPLIED });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
    }
    nextStep();
  };

  const handleRework = async () => {
    const res = await updateMembershipStatus({ status: membershipRequestStatus.PENDING });
    if (res.data?.status === 200) {
      const { data } = await getDashboardHome();
      if (data) {
        dispatch(setMill(data.mill));
      }
    }
  };

  return (
    <Box>
      <Box bg="white" mt={6} borderRadius="4px">
        <Text fontSize="xl" fontWeight="bold" pt={6} pl={6} pr={4}>
          Application Status
        </Text>
        <VStack px={6} pt={4} pb={6} spacing={5}>
          <Steps colorScheme="primary" activeStep={activeStep}>
            {steps.map(({ label, content }) => (
              <Step label={label} key={label}>
                {content}
              </Step>
            ))}
          </Steps>
          {activeStep > 1 ? (
            step3Label === 'Rework' ? (
              <HStack spacing={3}>
                <Button variant="formNext" onClick={handleRework}>
                  Edit Application
                </Button>
              </HStack>
            ) : null
          ) : (
            <HStack spacing={3}>
              <Button variant="formNext" onClick={() => setStep(0)}>
                Edit Application
              </Button>
              <Button variant="formNext" onClick={onOpen}>
                Submit for Verification
              </Button>
            </HStack>
          )}
        </VStack>
      </Box>
      {activeStep === 3 ? (
        <Box mt={4}>
          <SubscriptionCost />
        </Box>
      ) : null}
      <DialogueBox
        mountControl={isOpen}
        close={onClose}
        alertTitle="Confirm"
        alertMessage="Are you sure you want to submit your application for verification? You can not edit any information afterwards."
        performAction={handleSubmit}
      />
    </Box>
  );
};
export default ApplicationStatus;
