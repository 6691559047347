import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FieldArrayFormSection from '../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'product',
    type: 'text',
    isRequired: true,
    label: 'Description of Item',
  },
  {
    colSpan: 1,
    name: 'productQuantity',
    type: 'text',
    isRequired: true,
    label: 'Quantity or Weight ',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'deliveryDate',
    label: 'Date of Delivery',
  },
  {
    colSpan: 1,
    name: 'invoice',
    type: 'text',
    label: 'Com. Invoice No.',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'invoiceDate',
    label: 'Com. Invoice Date',
  },
];

export const defaultItemFieldValues = fields.reduce(
  (obj, item) => [{ ...obj, [item['name']]: '' }],
  [{}],
);

export const itemsInformationValidation = {
  items: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string().required().label(fields[0].label),
      [fields[1].name]: yup.string().required().label(fields[1].label),
      [fields[2].name]: dateValidation.label(fields[2].label),
      [fields[3].name]: yup.string().label(fields[3].label),
      [fields[4].name]: dateValidation.label(fields[4].label),
    }),
  ),
};

const ItemsInformation = (props) => (
  <FieldArrayFormSection sectionColumn={5} name="items" formFields={fields} {...props} />
);

export default ItemsInformation;
