import React, { useState, useEffect } from 'react';
import Loader from '../../../snippets/Loader';
import YarnManufacturerForm from './YarnManufacturerForm';
import { getMembershipFormData } from '../../../../api/membership';
import { YARN_MANUFACTURER_FORM_SECTION } from '../../../../constants/membershipFormConstant';

const YarnManufacturer = (props) => {
  const [note, setNote] = useState();
  const [defaultFields, setDefaultFields] = useState(null);

  useEffect(() => {
    (async () => {
      const { data: formData, error: getFormDataError } = await getMembershipFormData(
        YARN_MANUFACTURER_FORM_SECTION,
      );
      if (formData) {
        if (formData.yarnManufacturer?.note) {
          const { note, ...rest } = formData.yarnManufacturer;
          setNote(note);
          setDefaultFields(rest || {});
        } else {
          setNote();
          setDefaultFields(formData.yarnManufacturer || {});
        }
      } else {
        console.error(getFormDataError);
      }
    })();
  }, []);

  return defaultFields ? (
    <YarnManufacturerForm {...props} defaultFields={defaultFields} note={note} />
  ) : (
    <Loader />
  );
};

export default YarnManufacturer;
