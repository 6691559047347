import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  Text,
  Input,
  Stack,
  Button,
  Tooltip,
  Divider,
  GridItem,
  FormLabel,
  InputGroup,
  FormControl,
  InputRightElement,
} from '@chakra-ui/react';

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

const Form = ({
  formName,
  formText,
  onSubmit,
  formFields,
  extraField,
  columns = 2,
  formSubmitName,
  additionalLink,
  validationSchema,
}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack
        p={8}
        spacing={0}
        maxW="60vw"
        minW="380px"
        boxShadow="md"
        borderRadius="4px"
        backgroundColor="white">
        <Box pb={4}>
          <Text
            fontSize="22px"
            fontWeight="bold"
            letterSpacing={2}
            textAlign="center"
            color="textSecondary"
            textTransform="uppercase">
            {formName}
          </Text>
          {formText && (
            <>
              <Text
                mt={4}
                mb={2}
                w="300px"
                fontSize="sm"
                textAlign="justify"
                fontWeight="normal"
                color="textSecondary">
                {formText}
              </Text>
              <Divider />
            </>
          )}
        </Box>
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={4}>
          {formFields.map((field, index) => (
            <GridItem colSpan={field.colSpan} key={index}>
              <FormControl key={index} id={field.id} isInvalid={errors && errors[field.name]}>
                <FormLabel fontSize="sm">
                  {field.isRequired ? <b style={{ color: '#E53E3E' }}>* </b> : null}
                  {field.label}
                </FormLabel>
                <InputGroup>
                  <InputRightElement>{field.rightElement}</InputRightElement>
                  <Input
                    h="5.7vh"
                    minH="40px"
                    maxH="50px"
                    fontSize="md"
                    bg="secondary"
                    variant="outline"
                    borderRadius="sm"
                    name={field.name}
                    type={field.type}
                    focusBorderColor="primary.300"
                    placeholder={field.placeholder}
                    {...register(field.id)}
                  />
                </InputGroup>
                <Tooltip
                  label={errors[field.id]?.message?.length > 38 ? errors[field.id]?.message : ''}
                  area-label="A Tooltip"
                  placement="bottom-start">
                  <Text mb={1.5} fontSize="xs" color="invalid">
                    {errors[field.id]?.message?.substring(0, 38)}
                    {errors[field.id]?.message?.length > 38 ? '...' : null}
                  </Text>
                </Tooltip>
              </FormControl>
            </GridItem>
          ))}
        </Grid>
        {extraField}
        <Stack flexDir="column" alignItems="center" pt={6}>
          <Button
            h="5vh"
            minH="35px"
            maxH="45px"
            width="full"
            type="submit"
            variant="solid"
            borderRadius={2}
            isLoading={isSubmitting}>
            {formSubmitName}
          </Button>
          {additionalLink}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Form;
