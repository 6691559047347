import React from 'react';
import * as yup from 'yup';
import PurchaseSection from '../../../../common/certificateComponents/PurchaseFields';

const purchaseInformationFields = [
  {
    colSpan: 1,
    id: 'numOfCert',
    label: 'How many certificates do you need?',
    name: 'numOfCert',
    isRequired: true,
    type: 'number',
  },
];

export const purchaseInformationValidation = {
  [purchaseInformationFields[0].name]: yup.number().required(),
};

const PurchaseInformationSection = ({ register, control, errors }) => {
  return (
    <PurchaseSection
      formFields={purchaseInformationFields}
      register={register}
      control={control}
      errors={errors}
    />
  );
};

export default PurchaseInformationSection;
