export const getCurrentUser = () => {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  }
  return undefined;
};

export const setCurrentUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const setAccessToken = (accessToken) => {
  localStorage.setItem('accessToken', accessToken);
};

export const removeCurrentUserData = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
};

export const setCurrentUserData = (user, accessToken) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('accessToken', accessToken);
};

export const getCurrentUserData = () => {
  const user = getCurrentUser();
  const accessToken = localStorage.getItem('accessToken');
  return { user, accessToken };
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};
