import React, { useEffect, useState } from 'react';
import Loader from '../../../snippets/Loader';
import KnitFabricForm from './KnitFabricForm';
import { getMembershipFormData } from '../../../../api/membership';
import { KNIT_FABRIC_FORM_SECTION } from '../../../../constants/membershipFormConstant';

const KnitFabric = (props) => {
  const [defaultFields, setDefaultFields] = useState();

  useEffect(() => {
    (async () => {
      const { data: formData, error: getFormDataError } = await getMembershipFormData(
        KNIT_FABRIC_FORM_SECTION,
      );
      if (formData) {
        setDefaultFields(formData.knitFabric || {});
      } else {
        console.error(getFormDataError);
      }
    })();
  }, []);

  return defaultFields ? <KnitFabricForm defaultFields={defaultFields} {...props} /> : <Loader />;
};

export default KnitFabric;
