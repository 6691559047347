import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, VStack, Text, Button } from '@chakra-ui/react';
import { getUnusedProductionCertificates } from '../../../api/certificate';
import CertificateApplicationForm from './CertificateApplicationForm/CertificateApplicationForm';
import { FETCH_UNUSED_PRODUCTION_CERTIFICATES } from '../../../store/actions/actionTypes';
import Loader from '../../snippets/Loader';

const ApplyCertificate = ({ setNavState }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const res = await getUnusedProductionCertificates();
      if (res.data) {
        dispatch({
          type: FETCH_UNUSED_PRODUCTION_CERTIFICATES,
          payload: res.data.certificates,
        });
        setData(res.data.certificates);
      } else {
        setData([]);
      }
    })();
  }, []);

  const updateUnusedCert = async () => {
    const res = await getUnusedProductionCertificates();
    if (res.data) {
      dispatch({
        type: FETCH_UNUSED_PRODUCTION_CERTIFICATES,
        payload: res.data.certificates,
      });
      setData(res.data.certificates);
    } else {
      setData([]);
    }
  };

  return data?.length > 0 ? (
    <Box>
      <CertificateApplicationForm setNavState={setNavState} refresh={updateUnusedCert} />
    </Box>
  ) : data?.length == 0 ? (
    <Box mt={4} p={2} bg="white">
      <VStack h="60vh" justifyContent="center">
        <Text>You do not have any blank certificate.</Text>
        <Text pb="20px">Please, place a request to buy new certificates.</Text>
        <Button variant="formNext" onClick={() => setNavState(0)}>
          Request Certificate
        </Button>
      </VStack>
    </Box>
  ) : (
    <Loader />
  );
};

export default ApplyCertificate;
