import React from 'react';
import { Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import MembershipInfoWrapper from '../MembershipInfoWrapper';
import InfoSection from '../../../common/InfoSection';

const ProductionRelatedInformationSection = () => {
  const mill = useSelector((state) => state.mill.mill);
  const data = mill?.operationInfo;

  const yarnInstalledCapacityFields = [
    { header: 'Spindles', value: data?.yarnManufacturerSummary?.spindlesInsCap },
    { header: 'Rotors', value: data?.yarnManufacturerSummary?.rotorsInsCap },
    { header: 'Autocone', value: data?.yarnManufacturerSummary?.autoConeInsCap },
    { header: 'Others', value: data?.yarnManufacturerSummary?.otherInsCap },
  ];
  const yarnInOperationFields = [
    { header: 'Spindles', value: data?.yarnManufacturerSummary?.spindlesInOp },
    { header: 'Rotors', value: data?.yarnManufacturerSummary?.spindlesInOp },
    { header: 'Autocone', value: data?.yarnManufacturerSummary?.autoConeInOp },
    { header: 'Others', value: data?.yarnManufacturerSummary?.otherInOp },
  ];
  const yarnCapacityFields = [
    { header: 'Yarn Production Capacity (kg)', value: data?.yarnManufacturerSummary?.yarnProdCap },
    { header: 'Yarn Exportable Capacity (kg)', value: data?.yarnManufacturerSummary?.yarnExpoCap },
  ];
  const fabricInstalledCapacityFields = [
    { header: 'Airjet', value: data?.fabricsManufacturerSummary?.airjetInsCap },
    { header: 'Shuttle', value: data?.fabricsManufacturerSummary?.shuttleInsCap },
    { header: 'Shuttle Less', value: data?.fabricsManufacturerSummary?.shuttleLessInsCap },
    { header: 'Repair', value: data?.fabricsManufacturerSummary?.repairInsCap },
    { header: 'Knitting', value: data?.fabricsManufacturerSummary?.knittingInsCap },
    { header: 'Others', value: data?.fabricsManufacturerSummary?.othersInsCap },
  ];
  const fabricCapacityFields = [
    { header: 'Knit-Production (Kg)', value: data?.fabricsManufacturerSummary?.fabricKnitProdCap },
    { header: 'Woven-Production (m)', value: data?.fabricsManufacturerSummary?.fabricWovenProdCap },
    { header: 'Knit-Exportable (Kg)', value: data?.fabricsManufacturerSummary?.fabricKnitExpoCap },
    { header: 'Woven-Exportable (m)', value: data?.fabricsManufacturerSummary?.fabricWovenExpoCap },
  ];
  const fabricProcessingCapacityFields = [
    {
      header: 'Knit-Processing (Kg)',
      value: data?.textileProductProcessorSummary?.texFabricKnitProcCap,
    },
    {
      header: 'Woven-Processing (m)',
      value: data?.textileProductProcessorSummary?.texFabricWovenProcCap,
    },
    {
      header: 'Knit-Exportable (Kg)',
      value: data?.textileProductProcessorSummary?.texFabricKnitExpoCap,
    },
    {
      header: 'Woven-Exportable (m)',
      value: data?.textileProductProcessorSummary?.texFabricWovenExpoCap,
    },
  ];
  const yarnTexCapacityFields = [
    { header: 'Yarn-Processing (Kg)', value: data?.textileProductProcessorSummary?.texYarnProcCap },
    { header: 'Yarn-Exportable (kg)', value: data?.textileProductProcessorSummary?.texYarnExpoCap },
  ];

  return (
    <MembershipInfoWrapper name="Production Related Information">
      <Text fontSize="1xl" mt={3} fontWeight="bold">
        Category ‘A’ Spinning (Yarn ManuFacturer)
      </Text>
      <InfoSection sectionName="Installed Capacity" infoFields={yarnInstalledCapacityFields} />
      <InfoSection sectionName="In Operation" infoFields={yarnInOperationFields} />
      <InfoSection sectionName="Capacity" infoFields={yarnCapacityFields} />
      <Text fontSize="1xl" mt={3} fontWeight="bold">
        Category ‘B’ Weaving (Fabric ManuFacturer)
      </Text>
      <InfoSection sectionName="Installed Capacity" infoFields={fabricInstalledCapacityFields} />
      <InfoSection sectionName="Capacity" infoFields={fabricCapacityFields} />
      <Text fontSize="1xl" mt={3} fontWeight="bold">
        Category ‘C’ Textile (Product Processor)
      </Text>
      <InfoSection
        sectionName="Fabric Processing Capacity"
        infoFields={fabricProcessingCapacityFields}
      />
      <InfoSection sectionName="Yarn Processing Capacity" infoFields={yarnTexCapacityFields} />
    </MembershipInfoWrapper>
  );
};

export default ProductionRelatedInformationSection;
