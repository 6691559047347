import axios from './axios';

export const requestAgentVerification = async (data) => {
  try {
    const res = await axios.get(
      `/verification/certificate/${data?.certificateType}/${data?.certificateNum}`,
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const verifyAgentOtp = async (data) => {
  try {
    const res = await axios.post('/verification/verify', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
