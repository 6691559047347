import axios from './axios';
import { formatDate } from '../util/formatUtils';

const formatProductDetails = (products) => {
  return products?.map((item) => ({
    ...item,
    deliveryDate: formatDate(item?.deliveryDate),
    invoiceDate: formatDate(item?.invoiceDate),
  }));
};

//inserting into formData
const formatFormData = (data) => {
  let formData = new FormData();
  //common fields
  formData.append('masterLc', data.masterLc);
  formData.append('dateOfLc', formatDate(data.dateOfLc));
  formData.append('expiryDateOfLc', formatDate(data.expiryDateOfLc));
  formData.append('totalQuantity', data.totalQuantity);
  formData.append('btbLc', data.btbLc);
  formData.append('btbLcDate', formatDate(data.btbLcDate));
  formData.append('valueInUSD', data.valueInUSD);
  formData.append('bank', data.bank);
  formData.append('bankBranch', data.bankBranch);
  formData.append('suppliedTo', data.suppliedTo);
  formData.append('productType', data.product);
  formData.append('productDetails', JSON.stringify(formatProductDetails(data.items)));

  //conditional fields
  formData.append('exciseGatePassNum', data.exciseGatePassNum || '');
  formData.append('exciseGatePassDate', formatDate(data.exciseGatePassDate) || '');
  formData.append('nameOfMillAndAddress', data.nameOfMillAndAddress || '');
  formData.append('annualProdCapacity', data.annualProdCapacity || '');
  formData.append('producedBy', data.producedBy || '');
  formData.append('processedBy', data.processedBy || '');
  formData.append('videCertificateNo', data.videCertificateNo || '');
  formData.append('videCertificateDate', formatDate(data.videCertificateDate) || '');
  formData.append('mushakNum', data.mushakNum || '');
  formData.append('mushakDate', formatDate(data.mushakDate) || '');
  formData.append('totalValueInUSD', data.totalValueInUSD || '');
  formData.append('btmaCashAssistanceCertForYarn', data.btmaCashAssistanceCertForYarn || '');
  formData.append(
    'btmaCashAssistanceCertForYarnDate',
    formatDate(data.btmaCashAssistanceCertForYarnDate) || '',
  );

  const docs = {
    commercialInvoice: data.commercialInvoice,
    deliveryChallan: data.deliveryChallan,
    vatChallan: data.vatChallan,
    lcBankDocuments: data.lcBankDocuments,
    proformaInvoice: data.proformaInvoice,
    purchaseOrder: data.purchaseOrder,
    lcCertificate: data.lcCertificate,
    declarationOne: data.declarationOne,
    declarationThree: data.declarationThree,
    amendments: data.amendments,
  };

  for (const key in docs) {
    if (docs?.[key]?.length > 0) {
      formData.append(key, data[key][0]);
    }
  }
  return formData;
};

export const getProductionCertHistory = async (data) => {
  try {
    const res = await axios.get('/certificates/production-purchase-history', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteProductionCertPurchase = async (orderId) => {
  try {
    const res = await axios.delete(`/certificates/production/${orderId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getProcessingCertHistory = async (data) => {
  try {
    const res = await axios.get('/certificates/processing-purchase-history', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getDutyCertHistory = async (data) => {
  try {
    const res = await axios.get('/certificates/duty-exemption-purchase-history', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteProcessingCertPurchase = async (orderId) => {
  try {
    const res = await axios.delete(`/certificates/processing/${orderId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteDutyCertPurchase = async (orderId) => {
  try {
    const res = await axios.delete(`/certificates/duty-exemption/${orderId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const purchaseProductionCertificate = async (data) => {
  try {
    const res = await axios.post('/certificates/production', data);
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const purchaseProcessingCertificate = async (data) => {
  try {
    const res = await axios.post('/certificates/processing', data);
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const purchaseDutyCertificate = async (data) => {
  try {
    const res = await axios.post('/certificates/duty-exemption-purchase', data);
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const getUnusedProductionCertificates = async () => {
  try {
    const res = await axios.get('/certificates/production/?flag=unused');
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const getUnusedProcessingCertificates = async () => {
  try {
    const res = await axios.get('/certificates/processing/?flag=unused');
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getUnusedDutyCertificates = async () => {
  try {
    const res = await axios.get('/certificates/duty-exemption/?flag=unused');
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const applyForProductionCertificate = async (data, param) => {
  try {
    const res = await axios.post(`/certificates/production/${param.certId}`, formatFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const applyForProcessingCertificate = async (data, param) => {
  try {
    const res = await axios.post(`/certificates/processing/${param.certId}`, formatFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getProductionCertLogs = async (data) => {
  try {
    const res = await axios.get('/certificates/production', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getProductionCertificate = async (param) => {
  try {
    const res = await axios.get(
      `/certificates/production/${param.certId}/PRODUCTION_${param.certType}`,
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchProductionCertificate = async (data, param) => {
  try {
    const res = await axios.patch(
      `/certificates/production/${param.certId}/PRODUCTION_${param.certType}`,
      formatFormData(data),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getProcessingCertLogs = async (data) => {
  try {
    const res = await axios.get('/certificates/processing', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getProcessingCertificate = async (param) => {
  try {
    const res = await axios.get(`/certificates/processing/${param}/PROCESSING`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchProcessingCertificate = async (data, param) => {
  try {
    const res = await axios.patch(
      `/certificates/processing/${param.certId}/PROCESSING`,
      formatFormData(data),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const applyForDutyCertificate = async (data) => {
  try {
    const res = await axios.post('/certificates/duty-exemption', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const getDutyCertLogs = async (data) => {
  try {
    const res = await axios.get('/certificates/duty-exemption', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getDutyCertificate = async (param) => {
  try {
    const res = await axios.get(`/certificates/duty-exemption/${param}`);
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchDutyCertificate = async (data, param) => {
  try {
    const res = await axios.patch(`/certificates/duty-exemption/${param}`, data);
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};
