export const CSVReaderStyle = {
  dropArea: {
    borderColor: '#ccc',
    borderRadius: 3,
    padding: '.3em',
    width: '640px',
    marginTop: 6,
    marginBottom: 6,
    marginLeft: 0,
  },
  dropAreaActive: {
    borderColor: '#28D9C3',
    borderRadius: 3,
    padding: '.3em',
    marginTop: 6,
    marginBottom: 6,
  },
  dropFile: {
    width: 'auto',
    borderRadius: 3,
    height: 'auto',
    background: '#D3D3D3',
  },
  fileSizeInfo: {
    borderRadius: 3,
    fontSize: 1,
    marginTop: '.1em',
    background: '#D3D3D3',
  },
  fileNameInfo: {
    borderRadius: 3,
    maxWidth: '605px',
    fontSize: 16,
    padding: '10px 25px 8px 10px',
    background: '#D3D3D3',
  },
  progressBar: {
    backgroundColor: '#e9e4ff',
  },
};
