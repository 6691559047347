import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';
import { dateValidation, mobileNoValidation } from '../../../util/yupUtils';
import { birthCertificateValidation, nidNoValidation } from '../../../util/csvUtils';
import { BIRTH_CERTIFICATE_REGEX, NID_NO_REGEX } from '../../../constants/formValidationRegex';

const fields = [
  {
    colSpan: 1,
    name: 'millEmployeeId',
    type: 'text',
    label: 'Employee-ID',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'name',
    type: 'text',
    label: 'Name',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'nid',
    type: 'text',
    label: 'NID No',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'birthCertNum',
    type: 'text',
    label: 'Birth Certificate No',
    isRequired: true,
  },
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'dateOfBirth',
    label: 'Date of Birth',
    isRequired: true,
  },
  {
    fieldType: 'select',
    name: 'gender',
    label: 'Select Gender',
    isRequired: true,
    choices: [
      { name: 'Male', value: 'MALE' },
      { name: 'Female', value: 'FEMALE' },
      { name: 'Other', value: 'OTHER' },
    ],
  },
  {
    colSpan: 1,
    name: 'fatherName',
    type: 'text',
    label: 'Father Name',
  },
  {
    colSpan: 1,
    name: 'motherName',
    type: 'text',
    label: 'Mother Name',
  },
  {
    colSpan: 1,
    name: 'department',
    type: 'text',
    label: 'Department',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'designation',
    type: 'text',
    label: 'Designation',
    isRequired: true,
  },
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'joiningDate',
    label: 'Joining Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'departureDate',
    label: 'Departure Date',
  },
  {
    colSpan: 1,
    name: 'contactNo',
    type: 'text',
    label: 'Contact No',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'presentAddress',
    type: 'text',
    label: 'Present Address',
  },
  {
    fieldType: 'select',
    name: 'insuranceCoverage',
    label: 'Insurance Coverage',
    isRequired: true,
    choices: [
      { name: 'Yes', value: 'YES' },
      { name: 'No', value: 'NO' },
    ],
  },
  {
    colSpan: 1,
    name: 'nominee',
    type: 'text',
    label: 'Name of Nominee',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'relation',
    type: 'text',
    label: 'Relationship with Employee',
    isRequired: true,
  },
];

export const labourerInfoValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),

  [fields[2].name]: yup.string().when([fields[3].name], {
    is: (fieldTwo) =>
      !fieldTwo || fieldTwo.length === 0 || !fieldTwo.match(BIRTH_CERTIFICATE_REGEX),
    then: nidNoValidation.required(
      'At least one of the fields (NID/Birth Certificate) is required',
    ),
  }),
  [fields[3].name]: yup.string().when([fields[2].name], {
    is: (fieldOne) => !fieldOne || fieldOne.length === 0 || !fieldOne.match(NID_NO_REGEX),
    then: birthCertificateValidation.required(
      'At least one of the fields (NID/Birth Certificate) is required',
    ),
  }),

  [fields[4].name]: dateValidation.required().label(fields[4].label),
  [fields[5].name]: yup.string().required().label(fields[5].label),
  [fields[6].name]: yup.string().label(fields[6].label),
  [fields[7].name]: yup.string().label(fields[7].label),
  [fields[8].name]: yup.string().required().label(fields[8].label),
  [fields[9].name]: yup.string().required().label(fields[9].label),
  [fields[10].name]: dateValidation.required().label(fields[10].label),
  [fields[11].name]: dateValidation.label(fields[11].label),
  [fields[12].name]: mobileNoValidation.required().label(fields[12].label),
  [fields[13].name]: yup.string().label(fields[13].label),
  [fields[14].name]: yup.string().required().label(fields[14].label),
  [fields[15].name]: yup.string().required().label(fields[15].label),
  [fields[16].name]: yup.string().required().label(fields[16].label),
};

const LabourerInfoSection = (props) => {
  return (
    <FormSection
      sectionName="Employee Information"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default LabourerInfoSection;
