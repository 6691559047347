import axios from './axios';

import {
  GENERAL_INFORMATION_FORM_SECTION,
  YARN_MANUFACTURER_FORM_SECTION,
  FABRIC_MANUFACTURER_FORM_SECTION,
  KNIT_FABRIC_FORM_SECTION,
  TEXTILE_PRODUCT_PROCESSOR_FORM_SECTION,
  HOSIERY_KNITTING_MILLS_FORM_SECTION,
  OTHER_INFORMATION_FORM_SECTION,
} from '../constants/membershipFormConstant';

export const getMillCategories = async () => {
  try {
    const res = await axios.get('/membership/categories');
    if (res.status === 200) return { categories: res.data.categories, error: null };
  } catch (error) {
    return { categories: null, error };
  }
};

const retrieveFormDataA = (data) => {
  const sendData = {
    millName: data.millName,
    dateOfEstablishment: data.dateOfEstablishment,
    website: data.website,
    yearOfOperation: data.yearOfOperation,

    ...data.hoInfo,
    ...data.msInfo,
    ...data.chairmanInfo,
    ...data.mdInfo,
    ...data.millPresentatorInfo,
    ...data.otherInfo,
    ...data.dateOfCommencementOfProduction,
    ...data.capitalStructure,
    ...data.sourceOfCapital,
    ...data.marketingOfFinishedProducts,
    ...data.bondedWarehouseLicenseNo,
    ...data.powerManagement,
  };
  return sendData;
};

export const getMembershipFormData = async (section) => {
  try {
    const res = await axios.get(`/membership/?section=${section}`);
    if (res.status === 200) {
      switch (section) {
        case GENERAL_INFORMATION_FORM_SECTION:
          return {
            data: retrieveFormDataA(res.data.formData),
            error: null,
          };
        case YARN_MANUFACTURER_FORM_SECTION:
          return { data: res.data.formData, error: null };
        case FABRIC_MANUFACTURER_FORM_SECTION:
          return { data: res.data.formData, error: null };
        case KNIT_FABRIC_FORM_SECTION:
          return { data: res.data.formData, error: null };
        case TEXTILE_PRODUCT_PROCESSOR_FORM_SECTION:
          return { data: res.data.formData, error: null };

        case HOSIERY_KNITTING_MILLS_FORM_SECTION:
          return { data: res.data.formData, error: null };

        case OTHER_INFORMATION_FORM_SECTION:
          return { data: res.data.formData, error: null };

        default:
          return { data: null, error: null };
      }
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      return { data: {}, error: null };
    }
    return { data: null, error };
  }
};

const readyFormDataA = (data) => {
  const {
    categoryId,
    millName,
    millEmail,
    millPhoneNo,
    website,
    dateOfEstablishment,
    yearOfOperation,

    hoName,
    hoHouseNo,
    hoRoadNo,
    hoPoliceStation,
    hoPostOffice,
    hoDistrict,

    msAddress,
    msTotalArea,
    msTotalBuilding,
    msHoldingNo,
    msPoliceStation,
    msDistrict,
    msPhoneNo,
    msEmail,

    chairmanName,
    chairmanOfficeTelephone,
    chairmanResidenceTelephone,
    chairmanMobileNo,
    chairmanEmail,

    mdName,
    mdOfficeTelephone,
    mdResidenceTelephone,
    mdMobileNo,
    mdEmail,

    mpName,
    mpOfficeTelephone,
    mpResidenceTelephone,
    mpMobileNo,
    mpEmail,

    natureOfCompany,
    mainManufactureActivity,

    expectedDate,
    actualDate,

    authorizedCapitalInTK,
    paidupCapitalInTK,
    authorizedCapitalInUS,
    paidupCapitalInUS,

    sponsors,
    bankFinance,
    publicIssue,
    participationShareMarket,
    etp,
    other,
    workingCapital,
    totalCostOfProject,

    local,
    exportable,

    applicable,
    licenseNo,
    licenseDate,
    renewDate,

    diesel,
    gas,
    approvedGasLoad,
    approvedElectricityLoad,
    note,
  } = data;

  const sendData = {
    categoryId,
    millName,
    dateOfEstablishment,
    yearOfOperation,
    website,

    hoInfo: {
      hoName,
      hoHouseNo,
      hoRoadNo,
      millEmail,
      millPhoneNo,
      hoPoliceStation,
      hoPostOffice,
      hoDistrict,
    },

    msInfo: {
      msAddress,
      msTotalArea,
      msTotalBuilding,
      msHoldingNo,
      msPoliceStation,
      msDistrict,
      msPhoneNo,
      msEmail,
    },

    chairmanInfo: {
      chairmanName,
      chairmanOfficeTelephone,
      chairmanResidenceTelephone,
      chairmanMobileNo,
      chairmanEmail,
    },

    mdInfo: {
      mdName,
      mdOfficeTelephone,
      mdResidenceTelephone,
      mdMobileNo,
      mdEmail,
    },

    millPresentatorInfo: {
      mpName,
      mpOfficeTelephone,
      mpResidenceTelephone,
      mpMobileNo,
      mpEmail,
    },

    otherInfo: {
      natureOfCompany,
      mainManufactureActivity,
      note,
    },

    dateOfCommencementOfProduction: {
      expectedDate,
      actualDate,
    },

    capitalStructure: {
      authorizedCapitalInTK,
      paidupCapitalInTK,
      authorizedCapitalInUS,
      paidupCapitalInUS,
    },

    sourceOfCapital: {
      sponsors,
      bankFinance,
      publicIssue,
      participationShareMarket,
      etp,
      other,
      workingCapital,
      totalCostOfProject,
    },

    marketingOfFinishedProducts: {
      local,
      exportable,
    },

    bondedWarehouseLicenseNo: {
      applicable,
      licenseNo,
      licenseDate,
      renewDate,
    },

    powerManagement: {
      diesel,
      gas,
      approvedGasLoad,
      approvedElectricityLoad,
    },
  };
  return sendData;
};

export const postMembership = async (data) => {
  try {
    const res = await axios.post('/membership', readyFormDataA(data));
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchMembership = async (section, data) => {
  try {
    if (section === GENERAL_INFORMATION_FORM_SECTION) {
      data = readyFormDataA(data);
    }
    const res = await axios.patch(`/membership/?section=${section}`, data);
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateMembershipStatus = async (data) => {
  try {
    const res = await axios.patch(`/membership/?status=${data?.status}`);
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMembershipDocuments = async () => {
  try {
    const res = await axios.get('membership/documents/');
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 400) {
      return { data: {}, error: null };
    }
    return { data: null, error };
  }
};

export const postMembershipDocuments = async (data) => {
  let formData = new FormData();
  for (const key in data) {
    if (data?.[key]?.length > 0) {
      formData.append(key, data[key][0]);
    }
  }
  try {
    const res = await axios.post('/membership/documents/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200) {
      return { data: res.data, error: null };
    }
  } catch (error) {
    return { data: null, error };
  }
};

export const patchMembershipDocuments = async (data) => {
  let formData = new FormData();
  for (const key in data) {
    if (data?.[key]?.length > 0) {
      formData.append(key, data[key][0]);
    }
  }
  try {
    const res = await axios.patch('/membership/documents/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200) {
      return { data: res.data, error: null };
    }
  } catch (error) {
    return { data: null, error };
  }
};

export const getMembershipCost = async (data) => {
  try {
    const res = await axios.get(`/membership/subscription/${data?.flag}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMillTransactions = async (data) => {
  try {
    const res = await axios.get('/membership/transactions', {
      params: data,
    });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.page || 1,
          pageSize: data?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const requestMembershipRenewal = async () => {
  try {
    const res = await axios.post('/membership/renewal');
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const requestMembershipInfoUpdate = async (data) => {
  try {
    const res = await axios.post('/membership/update', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getInfoUpdateRequests = async (data) => {
  try {
    const res = await axios.get('/membership/update', {
      params: data,
    });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.page || 1,
          pageSize: data?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
