import * as yup from 'yup';
import {
  NID_NO_REGEX,
  PHONE_NO_REGEX,
  DATE_VALIDATION_REGEX,
  BIRTH_CERTIFICATE_REGEX,
} from '../constants/formValidationRegex';
import { formatDate } from './formatUtils';
import { jsonToCSV } from 'react-papaparse';
import { labourKeyNames, labourKeys } from '../constants/labourDatabaseConstant';

export const nidNoValidation = yup
  .string()
  .matches(NID_NO_REGEX, 'Not a valid NID, must be a 10/13/17 digit number');

export const birthCertificateValidation = yup
  .string()
  .matches(BIRTH_CERTIFICATE_REGEX, 'Not a valid birth certificate, must be a 16/17 digit number');

export const validateCsv = async (parsedData) => {
  let errors = [];
  let validData = [];

  /* checks if the keys in the parsed csv file are valid */
  const checkKeys = (data) => {
    let areKeysValid = true;
    let invalidKeys = [];
    const keys = Object.keys(data)?.filter((key) => key);
    for (let key of keys) {
      if (!labourKeyNames().includes(key)) {
        invalidKeys.push(key);
        areKeysValid = false;
      }
    }
    return { areKeysValid, invalidKeys };
  };

  /* checks if the required fields are available in the parsed csv file */
  const requiredValidity = (data) => {
    let validity = true;
    for (let key in labourKeys) {
      if (labourKeys[key].isRequired && !data[labourKeys[key].name]) {
        validity = false;
      }
    }
    if (!data[labourKeys.NID.name] && !data[labourKeys.BIRTH_CERT.name]) {
      validity = false;
    }
    return validity;
  };

  /* validates gender type values */
  const validateGender = (gender) => {
    if (
      gender.toUpperCase() === 'MALE' ||
      gender.toUpperCase() === 'FEMALE' ||
      gender.toUpperCase() === 'OTHER'
    ) {
      return true;
    } else {
      return false;
    }
  };

  /* validates insurance coverage values */
  const validateInsurance = (insurance) => {
    if (insurance.toUpperCase() === 'YES' || insurance.toUpperCase() === 'NO') {
      return true;
    } else {
      return false;
    }
  };

  /* validates employee status values */
  const validateStatus = (status) => {
    if (status) {
      if (status.toUpperCase() === 'ACTIVE' || status.toUpperCase() === 'INACTIVE') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  /* checks for duplicate entries and keeps the first one */
  const doesExist = (data) => {
    validData?.map((item) => {
      if (data === item.birthCertNum || data === item.nid) {
        return true;
      } else {
        return false;
      }
    });
  };
  if (checkKeys(parsedData[0]?.data)?.areKeysValid) {
    parsedData?.map((row, idx) => {
      const data = row.data;
      if (data) {
        if (requiredValidity(data)) {
          //required fields available
          if (
            (data[labourKeys.NID.name]?.match(NID_NO_REGEX) ||
              data[labourKeys.BIRTH_CERT.name]?.match(BIRTH_CERTIFICATE_REGEX)) &&
            (!data[labourKeys.DEPARTURE.name] ||
              data[labourKeys.DEPARTURE.name]?.match(DATE_VALIDATION_REGEX)) &&
            data[labourKeys.CONTACT.name].match(PHONE_NO_REGEX) &&
            data[labourKeys.DOB.name].match(DATE_VALIDATION_REGEX) &&
            data[labourKeys.JOINING.name].match(DATE_VALIDATION_REGEX) &&
            data[labourKeys.NAME.name].length > 2 &&
            validateGender(data[labourKeys.GENDER.name]) &&
            validateInsurance(data[labourKeys.INSURANCE.name]) &&
            validateStatus(data[labourKeys.STATUS.name])
          ) {
            if (!doesExist(data[labourKeys.NID] || data[labourKeys.BIRTH_CERT])) {
              let sendData = {};
              for (let key in labourKeys) {
                if (labourKeys[key].isDate) {
                  let date = data[labourKeys[key].name].split('/');
                  sendData[labourKeys[key].dbKey] =
                    date?.length === 3
                      ? formatDate(new Date(`${date[1]}/${date[0]}/${date[2]}`))
                      : null;
                } else {
                  sendData[labourKeys[key].dbKey] = data[labourKeys[key].name];
                }
              }
              validData.push(sendData);
            }
          } else {
            //field(s) invalid
            if (!data[labourKeys.NAME.name]?.length > 2) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.NAME.column,
                msg: `Name is too short at row ${idx + 1}, must be at least 3 characters long`,
              };
              errors.push(errorObject);
            }
            if (data[labourKeys.NID.name] && !data[labourKeys.NID.name]?.match(NID_NO_REGEX)) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.NID.column,
                msg: `NID is invalid at row ${idx + 1}, must be a 10/13/17 digit number`,
              };
              errors.push(errorObject);
            }
            if (
              data[labourKeys.BIRTH_CERT.name] &&
              !data[labourKeys.BIRTH_CERT.name]?.match(BIRTH_CERTIFICATE_REGEX)
            ) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.BIRTH_CERT.column,
                msg: `Birth Certificate is invalid at row ${
                  idx + 1
                }, must be a 16 or 17 digit number`,
              };
              errors.push(errorObject);
            }
            if (!data[labourKeys.CONTACT.name]?.match(PHONE_NO_REGEX)) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.CONTACT.column,
                msg: `Contact No is invalid at row ${idx + 1}, enter a valid phone number`,
              };
              errors.push(errorObject);
            }
            if (!data[labourKeys.DOB.name]?.match(DATE_VALIDATION_REGEX)) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.DOB.column,
                msg: `Date of Birth is invalid at row ${idx + 1}, must be in DD/MM/YYYY format`,
              };
              errors.push(errorObject);
            }
            if (!data[labourKeys.JOINING.name]?.match(DATE_VALIDATION_REGEX)) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.JOINING.column,
                msg: `Joining Date is invalid at row ${idx + 1}, must be in DD/MM/YYYY format`,
              };
              errors.push(errorObject);
            }
            if (
              data[labourKeys.DEPARTURE.name] &&
              !data[labourKeys.DEPARTURE.name]?.match(DATE_VALIDATION_REGEX)
            ) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.DEPARTURE.column,
                msg: `Departaure Date is invalid at row ${idx + 1}, must be in DD/MM/YYYY format`,
              };
              errors.push(errorObject);
            }
            if (!validateGender(data[labourKeys.GENDER.name])) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.GENDER.column,
                msg: `Gender is invalid at row ${idx + 1}, must be male, female or other`,
              };
              errors.push(errorObject);
            }
            if (!validateInsurance(data[labourKeys.INSURANCE.name])) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.INSURANCE.column,
                msg: `Insurance Coverage is invalid at row ${idx + 1}, must be yes or no`,
              };
              errors.push(errorObject);
            }
            if (!validateStatus(data[labourKeys.STATUS.name])) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.STATUS.column,
                msg: `Status is invalid at row ${idx + 1}, must be active or inactive`,
              };
              errors.push(errorObject);
            }
          }
        } else {
          //required field(s) missing
          if (!data[labourKeys.NID.name] && !data[labourKeys.BIRTH_CERT.name]) {
            let errorObject = {
              index: idx + 1,
              colIndex: [labourKeys.NID.column, labourKeys.BIRTH_CERT.column],
              msg: `NID and Birth Certificate No is missing at row ${
                idx + 1
              }, provide at least one`,
            };
            errors.push(errorObject);
          }

          for (let key in labourKeys) {
            if (labourKeys[key].isRequired && !data[labourKeys[key].name]) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys[key].column,
                msg: `${labourKeys[key].name} is missing at row ${idx + 1}`,
              };
              errors.push(errorObject);
            }
          }

          if (data[labourKeys.NAME.name] && !data[labourKeys.NAME.name]?.length > 2) {
            let errorObject = {
              index: idx + 1,
              colIndex: labourKeys.NAME.column,
              msg: `Name is too short at row ${idx + 1}, must be at least 3 characters long`,
            };
            errors.push(errorObject);
          }
          if (data[labourKeys.NID.name] && !data[labourKeys.NID.name]?.match(NID_NO_REGEX)) {
            let errorObject = {
              index: idx + 1,
              colIndex: labourKeys.NID.column,
              msg: `NID is invalid at row ${idx + 1}, must be a 10/13/17 digit number`,
            };
            errors.push(errorObject);
          }
          if (
            data[labourKeys.BIRTH_CERT.name] &&
            !data[labourKeys.BIRTH_CERT.name]?.match(BIRTH_CERTIFICATE_REGEX)
          ) {
            let errorObject = {
              index: idx + 1,
              colIndex: labourKeys.BIRTH_CERT.column,
              msg: `Birth Certificate is invalid at row ${
                idx + 1
              }, must be a 16 or 17 digit number`,
            };
            errors.push(errorObject);
          }
          if (
            data[labourKeys.CONTACT.name] &&
            !data[labourKeys.CONTACT.name]?.match(PHONE_NO_REGEX)
          ) {
            let errorObject = {
              index: idx + 1,
              colIndex: labourKeys.CONTACT.column,
              msg: `Contact No is invalid at row ${idx + 1}, enter a valid phone number`,
            };
            errors.push(errorObject);
          }
          if (data[labourKeys.GENDER.name] && !validateGender(data[labourKeys.GENDER.name])) {
            let errorObject = {
              index: idx + 1,
              colIndex: labourKeys.GENDER.column,
              msg: `Gender is invalid at row ${idx + 1}, must be male, female or other`,
            };
            errors.push(errorObject);
          }
          if (
            data[labourKeys.INSURANCE.name] &&
            !validateInsurance(data[labourKeys.INSURANCE.name])
          ) {
            let errorObject = {
              index: idx + 1,
              colIndex: labourKeys.INSURANCE.column,
              msg: `Insurance Coverage is invalid at row ${idx + 1}, must be yes or no`,
            };
            errors.push(errorObject);
          }
          if (data[labourKeys.STATUS.name] && !validateStatus(data[labourKeys.STATUS.name])) {
            let errorObject = {
              index: idx + 1,
              colIndex: labourKeys.STATUS.column,
              msg: `Status is invalid at row ${idx + 1}, must be active or inactive`,
            };
            errors.push(errorObject);
          }
        }
      }
    });
  } else {
    let errorObject = {
      index: null,
      colIndex: null,
      msg: `File With Invalid Column(s) Uploaded\nInvalid columns: ${checkKeys(
        parsedData[0]?.data,
      )?.invalidKeys?.toString()}`,
    };
    errors.push(errorObject);
  }

  return { valid: errors?.length > 0 ? false : true, errors, validData };
};

export const convertToCSV = async (data) => {
  try {
    const csv = jsonToCSV(data);
    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob, { type: 'text/csv;charset=utf-8;' });
    a.download = 'Employee-Export-File.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return 'File downloaded!';
  } catch (error) {
    return error;
  }
};
