import React from 'react';
import { Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import MembershipInfoWrapper from '../MembershipInfoWrapper';
import InfoSection from '../../../common/InfoSection';

const OrganizationalInformationSection = () => {
  const mill = useSelector((state) => state.mill.mill);
  const data = mill?.operationInfo;

  const requirementAFields = [
    { header: 'PSF (kg)', value: data?.yarnManufacturerSummary?.psfReq },
    { header: 'Acrylic(kg)', value: data?.yarnManufacturerSummary?.acrylicReq },
    { header: 'Viscose (Kg)', value: data?.yarnManufacturerSummary?.viscoseReq },
    { header: 'Cotton Waste (Kg)', value: data?.yarnManufacturerSummary?.cotWasteReq },
    { header: 'Chips', value: data?.yarnManufacturerSummary?.chipsReq },
    { header: 'Pet Chips (Kg)', value: data?.yarnManufacturerSummary?.petChipsReq },
    { header: 'Raw Cotton (Kg)', value: data?.yarnManufacturerSummary?.rawCottonReq },
  ];
  const consumptionAFields = [
    { header: 'PSF (kg)', value: data?.yarnManufacturerSummary?.psfCon },
    { header: 'Acrylic(kg)', value: data?.yarnManufacturerSummary?.acrylicCon },
    { header: 'Viscose (Kg)', value: data?.yarnManufacturerSummary?.viscoseCon },
    { header: 'Cotton Waste (Kg)', value: data?.yarnManufacturerSummary?.cotWasteCon },
    { header: 'Chips', value: data?.yarnManufacturerSummary?.chipsCon },
    { header: 'Pet Chips (Kg)', value: data?.yarnManufacturerSummary?.petChipsCon },
    { header: 'Raw Cotton (Kg)', value: data?.yarnManufacturerSummary?.rawCottonCon },
  ];
  const requirementBFields = [
    { header: 'Cotton Yarn (kg)', value: data?.fabricsManufacturerSummary?.cotYarn },
    { header: 'Synthetic Yarn (kg)', value: data?.fabricsManufacturerSummary?.syntheticYarn },
    { header: 'Other Yarn (kg)', value: data?.fabricsManufacturerSummary?.otherYarn },
  ];
  const requirementCFields = [
    { header: 'Fabric-Woven (m)', value: data?.textileProductProcessorSummary?.fabWoven },
    { header: 'Fabric-knit (kg)', value: data?.textileProductProcessorSummary?.fabKnit },
    { header: 'Dice (kg)', value: data?.textileProductProcessorSummary?.dice },
    { header: 'Yarn', value: data?.textileProductProcessorSummary?.yarn },
    { header: 'Chemical (kg)', value: data?.textileProductProcessorSummary?.chemical },
  ];
  const hrFields = [
    { header: 'Number Of Officers', value: data?.humanResources?.numberOfOfficers },
    { header: 'Number Of Staff', value: data?.humanResources?.numberOfStaff },
    { header: 'Male', value: data?.humanResources?.noOfMale },
    { header: 'Female', value: data?.humanResources?.noOfFemale },
  ];

  return (
    <MembershipInfoWrapper name="Organizational Information">
      <Text fontSize="1xl" mt={3} fontWeight="bold">
        Category ‘A’ Spinning (Yarn ManuFacturer)
      </Text>
      <InfoSection sectionName="Raw Material Requirement" infoFields={requirementAFields} />
      <InfoSection
        sectionName="Raw Material Consumption (year end)"
        infoFields={consumptionAFields}
      />
      <Text fontSize="1xl" mt={3} fontWeight="bold">
        Category ‘B’ Weaving (Fabric ManuFacturer)
      </Text>
      <InfoSection sectionName="Raw Material Requirement" infoFields={requirementBFields} />
      <Text fontSize="1xl" mt={3} fontWeight="bold">
        Category ‘C’ Textile (Product Processor)
      </Text>
      <InfoSection sectionName="Raw Material Requirement" infoFields={requirementCFields} />
      <InfoSection sectionName="Human Resources" infoFields={hrFields} />
    </MembershipInfoWrapper>
  );
};

export default OrganizationalInformationSection;
