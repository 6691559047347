import React, { useEffect, useState } from 'react';
import Loader from '../../../snippets/Loader';
import GeneralInformationForm from './GeneralInformationForm';
import { getMembershipFormData } from '../../../../api/membership';
import { GENERAL_INFORMATION_FORM_SECTION } from '../../../../constants/membershipFormConstant';

const GeneralInformation = ({ currentStep, setStep }) => {
  const [note, setNote] = useState();
  const [defaultFields, setDefaultFields] = useState();

  useEffect(() => {
    (async () => {
      const { data: formData } = await getMembershipFormData(GENERAL_INFORMATION_FORM_SECTION);
      if (formData) {
        if (formData?.note) {
          const { note, ...rest } = formData;
          setNote(note);
          setDefaultFields(rest || {});
        } else {
          setNote();
          setDefaultFields(formData || {});
        }
      }
    })();
  }, []);

  return defaultFields ? (
    <GeneralInformationForm
      note={note}
      setStep={setStep}
      currentStep={currentStep}
      defaultFields={defaultFields}
    />
  ) : (
    <Loader />
  );
};

export default GeneralInformation;
