import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Box, Stack, Accordion, Text, HStack, useDisclosure } from '@chakra-ui/react';
import {
  GeneralInormationSection,
  ProductionRelatedInformationSection,
  OrganizationalInformationSection,
  PowerManagementSection,
} from './sections';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../snippets/Loader';
import FormButton from '../../common/FormButton';
import DialogueBox from '../../common/DialogueBox';
import { useYupValidationResolver } from '../../../util/yupUtils';
import { membershipRequestStatus } from '../../../constants/membershipFormConstant';
import RequestFormSection, { requestFieldsValidation } from './sections/RequestFormSection';
import { getInfoUpdateRequests, requestMembershipInfoUpdate } from '../../../api/membership';
import { notify } from '../../../store/actions/globalActions';
import DataTable from '../../common/certificateComponents/DataTable';
import { BADGE_STATE_COLORS, LOGS_TABLE_DISPLAY_SIZE } from '../../../constants/dataTableConstants';
import { FETCH_UPDATE_REQUESTS } from '../../../store/actions/millActions';

const MillInformation = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState();
  const [requestForm, setRequestForm] = useState(false);
  const data = useSelector((state) => state.mill);

  const columns = [
    { isDate: true, header: 'Date', accessor: 'createdAt' },
    { header: 'Request Id', accessor: 'uid' },
    { header: 'Request Message', accessor: 'message', limit: 32, hoverInfo: ['message'] },
    { isFile: true, header: 'Attachment', accessor: 'attachment' },
    { isBadge: true, header: 'Status', accessor: 'status', colorScheme: BADGE_STATE_COLORS },
  ];

  const requestStatus = {
    name: 'Request Status',
    choices: [
      { name: 'PENDING', value: 'PENDING' },
      { name: 'APPROVED', value: 'APPROVED' },
      { name: 'REJECTED', value: 'REJECTED' },
    ],
  };

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...requestFieldsValidation,
    }),
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({ resolver });

  const { changeRequest, attachment } = watch();

  const onSubmit = (formdata) => {
    const sendData = new FormData();
    sendData.append('changeRequest', formdata.changeRequest);
    sendData.append('attachment', formdata.attachment?.[0]);
    setFormData(sendData);
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await requestMembershipInfoUpdate(formData);
    if (res.data?.status === 200) {
      reset({ changeRequest: '' });
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      setRequestForm(false);
      handleSearch({ page: 1, pageSize: 10 });
    }
  };

  const handlePagination = async ({ page, pageSize, startDate, endDate, selected }) => {
    dispatch(FETCH_UPDATE_REQUESTS(null));
    const params = {
      page,
      pageSize,
      startDate,
      endDate,
      status: selected?.['Request Status'],
    };
    const res = await getInfoUpdateRequests(params);
    if (res.data?.status === 200) {
      dispatch(FETCH_UPDATE_REQUESTS(res.data));
    }
  };

  const handleSearch = async ({ startDate, endDate, selected }) => {
    dispatch(FETCH_UPDATE_REQUESTS(null));
    const params = {
      startDate,
      endDate,
      status: selected?.['Request Status'],
    };
    const res = await getInfoUpdateRequests(params);
    if (res.data?.status === 200) {
      dispatch(FETCH_UPDATE_REQUESTS(res.data));
    }
  };

  return data ? (
    data.mill?.membershipStatus === membershipRequestStatus.APPROVED ? (
      <Box mt={6}>
        {requestForm ? (
          <Box as="form" onSubmit={handleSubmit(onSubmit)}>
            <RequestFormSection
              register={register}
              control={control}
              errors={errors}
              watch={watch}
            />
            <HStack w="100%" justifyContent="end" mt={4}>
              <FormButton mt={0} onClick={() => setRequestForm(false)}>
                CANCEL
              </FormButton>
              <FormButton
                type="submit"
                isDisabled={changeRequest?.length <= 0 && attachment?.length <= 0}
                isLoading={isSubmitting}
                mt={0}>
                SUBMIT
              </FormButton>
            </HStack>
          </Box>
        ) : (
          <FormButton mt={0} onClick={() => setRequestForm(true)}>
            Request For Updating Information
          </FormButton>
        )}

        <Box my={6}>
          <DataTable
            caption="Information Update History"
            columns={columns}
            data={data?.updateRequests?.changeRequests}
            topPanel
            isPaginated
            pageSizes={LOGS_TABLE_DISPLAY_SIZE}
            entries={data?.updateRequests?.totalEntries}
            currentPage={data?.updateRequests?.page}
            currentPageSize={data?.updateRequests?.pageSize}
            dateRange
            selectOptions={[requestStatus]}
            paginate={handlePagination}
            search={handleSearch}
          />
        </Box>

        <Stack bg="white" borderRadius="4px">
          <Accordion allowToggle>
            <GeneralInormationSection />
            <ProductionRelatedInformationSection />
            <OrganizationalInformationSection />
            <PowerManagementSection />
          </Accordion>
        </Stack>

        <DialogueBox
          mountControl={isOpen}
          alertTitle="Confirm"
          alertMessage="Are you sure you want to submit this information update request?"
          close={onClose}
          performAction={confirmSubmit}
        />
      </Box>
    ) : (
      <Box mt={6}>
        <Stack bg="white" borderRadius="4px">
          <Text textAlign="center" fontSize="lg" px={4} py={10}>
            Not an approved member yet. You can see mill information under this tab once membrship
            registration is approved for your mill.
          </Text>
        </Stack>
      </Box>
    )
  ) : (
    <Loader />
  );
};

export default MillInformation;
