import React from 'react';
import * as yup from 'yup';
import AuthForm from './AuthForm';
import AuthWrapper from './AuthWrapper';
import { resendVerificationOtp } from '../../api/auth';
import { useHistory, useLocation } from 'react-router';
import { PHONE_NO_REGEX } from '../../constants/regex';
import { ACCOUNT_VERIFICATION_URL } from '../../constants/routerUrl';

const LateAccountVerification = () => {
  const history = useHistory();
  const location = useLocation();

  const onSubmit = async (data) => {
    const sendData = {
      username: location.state.username,
      phoneNo: data.phone,
    };
    try {
      const res = await resendVerificationOtp(sendData);
      const { phone } = data;
      if (res.status === 200) {
        history.replace({
          pathname: ACCOUNT_VERIFICATION_URL,
          state: { phone: phone, username: location.state.username },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const LateVerifyValidationSchema = yup.object().shape({
    phone: yup
      .string()
      .matches(PHONE_NO_REGEX, 'Not a valid phone no.')
      .required()
      .label('Phone Number'),
  });

  const formFields = [
    {
      id: 'phone',
      name: 'phone',
      isRequired: true,
      type: 'tel',
      label: 'Phone Number',
      placeholder: 'Enter your phone number',
    },
  ];

  return (
    <AuthWrapper>
      <AuthForm
        columns={1}
        formName="Account Verification"
        formText="Please enter your phone number to receive OTP for account verification."
        formFields={formFields}
        validationSchema={LateVerifyValidationSchema}
        formSubmitName="NEXT"
        onSubmit={onSubmit}
      />
    </AuthWrapper>
  );
};

export default LateAccountVerification;
