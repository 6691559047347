import React from 'react';

import { Input, FormControl, Text } from '@chakra-ui/react';

const CertificateInput = ({
  id,
  isRequired,
  isInvalid,
  name,
  type,
  placeholder,
  register,
  errorMessage,
  label,
}) => {
  return (
    <>
      <FormControl
        id={id}
        isRequired={isRequired}
        isInvalid={isInvalid}
        display="flex"
        flexDir="row"
        width="container.sm"
        justifyContent="space-between"
        alignItems="center">
        <Text fontSize="1xl" fontWeight="bold">
          {label}
        </Text>
        <Input
          autoFocus
          variant="outline"
          bg="formInputBG"
          h="40px"
          w="20"
          focusBorderColor="primary.300"
          borderRadius="4px"
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          {...register(id)}
        />
      </FormControl>
      <Text color="invalid" fontSize="15px" mt="6px">
        {errorMessage}
      </Text>
    </>
  );
};

export default CertificateInput;
