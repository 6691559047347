import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'exciseGatePassNum',
    type: 'text',
    label: 'Vat Challan No.',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'exciseGatePassDate',
    label: 'Vat Challan Date',
    isRequired: true,
  },
];

export const gspExpInformationSectionValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: dateValidation.required().label(fields[1].label),
};

const GspExpiryInformation = (props) => {
  return <FormSection secondary sectionColumn={3} formFields={fields} {...props} />;
};

export default GspExpiryInformation;
