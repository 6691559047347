import React from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react';
import Select from 'react-select';

const SelectCertificateSectionProduction = ({
  certificate,
  certificateType,
  setCertificate,
  error,
}) => {
  const certificates = useSelector((state) => state.certificate.unusedProdCertificates);

  const handleChange = (data) => {
    if (data) {
      setCertificate(data);
    } else {
      setCertificate();
    }
  };

  return (
    <Box>
      <HStack spacing={3} mb={4}>
        <FormControl isInvalid={error}>
          <FormLabel mb={1.5} fontSize="md">
            <b style={{ color: '#E53E3E' }}>* </b>Certificate No. & Type
          </FormLabel>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? '#674dfe' : 'gray.300',
                height: '50px',
                borderRadius: '4px',
                backgroundColor: '#F8F8F8',
                width: '15rem',
              }),
              indicatorSeparator: (baseStyles) => ({
                ...baseStyles,
                display: 'none',
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: 'gray.400',
              }),
            }}
            value={certificate}
            noOptionsMessage={() => {
              if (certificateType) {
                return 'No certificates available for this type';
              } else {
                return 'Please select certificate type first';
              }
            }}
            placeholder="Select an option"
            onChange={(value) => handleChange(value)}
            options={certificates.filter((cert) => cert.type === certificateType)}
            getOptionLabel={(option) => `${option?.certNum}-${option?.type}`}
            getOptionValue={(option) => option?.certID}
          />
        </FormControl>
      </HStack>
      <Text color="invalid" fontSize="15px" mt="6px">
        {error}
      </Text>
    </Box>
  );
};

export default SelectCertificateSectionProduction;
