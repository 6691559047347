import React, { useEffect, useState } from 'react';
import { getMembershipFormData, getMembershipDocuments } from '../../../../api/membership';
import OtherInformationForm from './OtherInformationForm';
import { OTHER_INFORMATION_FORM_SECTION } from '../../../../constants/membershipFormConstant';
import Loader from '../../../snippets/Loader';

const OtherInformation = (props) => {
  const [note, setNote] = useState();
  const [documents, setDocuments] = useState();
  const [defaultFields, setDefaultFields] = useState();

  useEffect(() => {
    (async () => {
      const { data: formData, error: getFormDataError } = await getMembershipFormData(
        OTHER_INFORMATION_FORM_SECTION,
      );
      const { data: documentData, error: documentDataError } = await getMembershipDocuments();
      if (formData) {
        if (formData.humanResources?.note) {
          const { note, ...rest } = formData.humanResources;
          setNote(note);
          setDefaultFields(rest || {});
        } else {
          setNote();
          setDefaultFields(formData.humanResources || {});
        }
      } else {
        console.error(getFormDataError);
      }
      if (documentData) {
        setDocuments({ ...documentData.documents });
      } else {
        console.error(documentDataError);
      }
    })();
  }, []);

  return defaultFields && documents ? (
    <OtherInformationForm
      note={note}
      documents={documents}
      defaultFields={defaultFields}
      {...props}
    />
  ) : (
    <Loader />
  );
};

export default OtherInformation;
