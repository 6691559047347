import React, { useState, useEffect } from 'react';
import GeneralInformation from './GeneralInformation/GeneralInformation';
import YarnManufacturer from './YarnManufacturer/YarnManufacturer';
import OtherInformation from './OtherInformation/OtherInformation';
import TextileProductProcessor from './TextileProductProcessor/TextileProductProcessor';
import KnitFabric from './KnitFabric/KnitFabric';
import FabricManufacturer from './FabricManufacturer/FabricManufacturer';
import HosieryKnittingMill from './HosieryKnittingMill/HosieryKnittingMill';
import ApplicationStatus from './ApplicationStatus';
import { membershipRequestStatus } from '../../../constants/membershipFormConstant';
import { useSelector } from 'react-redux';
import Loader from '../../snippets/Loader';

const ApplyForMembership = () => {
  const [step, setStep] = useState(0);
  const data = useSelector((state) => state.mill);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  if (data) {
    switch (data?.mill?.membershipStatus) {
      case membershipRequestStatus.APPLIED:
        return <ApplicationStatus setStep={setStep} defaultStep={2} />;
      case membershipRequestStatus.INREVIEW:
        return <ApplicationStatus setStep={setStep} defaultStep={2} step3Label={'In Review'} />;
      case membershipRequestStatus.PAYMENTPENDING:
        return <ApplicationStatus setStep={setStep} defaultStep={3} step3Label={'Verified'} />;
      case membershipRequestStatus.REWORK:
        return <ApplicationStatus setStep={setStep} defaultStep={2} step3Label={'Rework'} />;
      case membershipRequestStatus.REJECTED:
        return <ApplicationStatus setStep={setStep} defaultStep={2} step3Label={'Rejected'} />;

      default:
        switch (step) {
          case 1:
            return <YarnManufacturer currentStep={step} setStep={setStep} />;
          case 2:
            return <FabricManufacturer currentStep={step} setStep={setStep} />;
          case 3:
            return <KnitFabric currentStep={step} setStep={setStep} />;
          case 4:
            return <TextileProductProcessor currentStep={step} setStep={setStep} />;
          case 5:
            return <HosieryKnittingMill currentStep={step} setStep={setStep} />;
          case 6:
            return <OtherInformation currentStep={step} setStep={setStep} />;
          case 7:
            return <ApplicationStatus setStep={setStep} />;
          default:
            return <GeneralInformation currentStep={step} setStep={setStep} />;
        }
    }
  } else {
    return <Loader />;
  }
};

export default ApplyForMembership;
