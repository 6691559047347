import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 2,
    name: 'producedBy',
    type: 'text',
    isRequired: true,
    placeholder: 'Name & Address of the Mill',
    label: 'Produced By',
  },
  {
    colSpan: 2,
    name: 'processedBy',
    type: 'text',
    isRequired: true,
    placeholder: 'Name & Address of the Mill',
    label: 'Processed By',
  },
  {
    colSpan: 1,
    name: 'videCertificateNo',
    type: 'text',
    isRequired: true,
    label: 'Vide Certificate No',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'videCertificateDate',
    isRequired: true,
    label: 'Vide Certificate Date',
  },
  {
    colSpan: 2,
    name: 'suppliedTo',
    type: 'text',
    isRequired: true,
    placeholder: 'Name & Address of the Mill',
    label: 'Buyer',
  },
];

export const millInformationSectionValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
  [fields[3].name]: dateValidation.required().label(fields[3].label),
  [fields[4].name]: yup.string().required().label(fields[4].label),
};

const MillInformationSection = (props) => {
  return (
    <FormSection
      sectionName="Mill Information Section"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default MillInformationSection;
