import React, { useEffect, useState } from 'react';
import { Divider, Grid, GridItem, Stack, Text } from '@chakra-ui/react';

import { useForm, useWatch } from 'react-hook-form';

import * as yup from 'yup';
import { useYupValidationResolver } from '../../../../util/yupUtils';

import _ from 'lodash';

// wrapper
import MembershipFormWrapper from '../../MembershipFormWrapper';

// Form Sections and Field Array Form Sections
import {
  MillInformationSection,
  millInformationFieldsValidation,
  AddressSection,
  addressFieldsValidation,
  MillSiteSection,
  millSiteFieldsValidation,
  ChairmanInformationSection,
  chairmanInfoFieldsValidation,
  ManagingDirectorSection,
  managingDirectorFieldsValidation,
  MillPresenterSection,
  millPresenterFieldsValidation,
  OtherInformationSection,
  otherInformationFieldsValidation,
  DateOfCommencementProductionSection,
  dateOfCommencementFieldsValidation,
  CapitalStructureSection,
  capitalStructureFieldsValidation,
  SourceOfCapitalSection,
  sourceOfCapitalFieldsValidation,
  BondedWarehouseApplicableSection,
  bondedWarehouseApplicableFieldsValidation,
  BondedWarehouseLicenseSection,
  bondedWarehouseLicenseFieldsValidation,
  PowerManagementLeftOver,
  powerManagementLeftOverFieldsValidation,
  DieselSection,
  dieselFieldsValidation,
  defaultDeiselFieldValues,
  GasSection,
  gasFieldsValidation,
  defaultGasFieldValues,
  MarketingFinisheProductSection,
  marketingFinishedProductFieldsValidation,
} from './sections';

// Membership api call
import { postMembership, patchMembership } from '../../../../api/membership';
import FormButton from '../../../common/FormButton';
import { GENERAL_INFORMATION_FORM_SECTION } from '../../../../constants/membershipFormConstant';

// Yup validation schema
const GeneralInformationValidatonSchema = {
  ...gasFieldsValidation,
  ...dieselFieldsValidation,
  ...addressFieldsValidation,
  ...millSiteFieldsValidation,
  ...chairmanInfoFieldsValidation,
  ...millPresenterFieldsValidation,
  ...millInformationFieldsValidation,
  ...sourceOfCapitalFieldsValidation,
  ...otherInformationFieldsValidation,
  ...capitalStructureFieldsValidation,
  ...managingDirectorFieldsValidation,
  ...dateOfCommencementFieldsValidation,
  ...powerManagementLeftOverFieldsValidation,
  ...marketingFinishedProductFieldsValidation,
  ...bondedWarehouseApplicableFieldsValidation,
};

const GeneralInformationForm = ({ note, currentStep, setStep, defaultFields }) => {
  const [isMdSame, setIsMdSame] = useState(false);
  const [isMsSame, setIsMsSame] = useState(false);
  const [conversions, setConversions] = useState();
  const [licenseSection, setLicenseSection] = useState(false);

  useEffect(() => {
    let axios = require('axios').default;
    let options = {
      method: 'GET',
      headers: {
        'x-rapidapi-host': process.env.REACT_APP_RAPID_API_HOST,
        'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    let paramList = ['USD', 'EUR', 'GBP', 'INR', 'JPY', 'CNY', 'CHF'];
    let exchangeRates = { BDT: 1 };

    paramList.forEach((item) => {
      axios
        .request({
          ...options,
          url: `${process.env.REACT_APP_RAPID_API_URL}/${item}`,
        })
        .then(function (response) {
          exchangeRates[item] = response.data?.rates?.BDT;
        })
        .catch(function (error) {
          console.error(error);
        });
    });

    setConversions(exchangeRates);
  }, []);

  const getValidationSchema = () => {
    if (licenseSection) {
      return yup
        .object()
        .shape({ ...GeneralInformationValidatonSchema, ...bondedWarehouseLicenseFieldsValidation });
    } else {
      return yup.object().shape(GeneralInformationValidatonSchema);
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      diesel: defaultFields.diesel?.length
        ? [...defaultFields.diesel]
        : [...defaultDeiselFieldValues],
      gas: defaultFields.gas?.length ? [...defaultFields.gas] : [...defaultGasFieldValues],
    },
  });

  const chairmanName = useWatch({ control, name: 'chairmanName' });
  const chairmanEmail = useWatch({ control, name: 'chairmanEmail' });
  const chairmanMobileNo = useWatch({ control, name: 'chairmanMobileNo' });
  const chairmanOfficeTelephone = useWatch({ control, name: 'chairmanOfficeTelephone' });
  const chairmanResidenceTelephone = useWatch({ control, name: 'chairmanResidenceTelephone' });

  const hoName = useWatch({ control, name: 'hoName' });
  const millEmail = useWatch({ control, name: 'millEmail' });
  const hoHouseNo = useWatch({ control, name: 'hoHouseNo' });
  const hoDistrict = useWatch({ control, name: 'hoDistrict' });
  const millPhoneNo = useWatch({ control, name: 'millPhoneNo' });
  const hoPoliceStation = useWatch({ control, name: 'hoPoliceStation' });

  const authorizedCapitalInTK = useWatch({ control, name: 'authorizedCapitalInTK' });
  const paidupCapitalInTK = useWatch({ control, name: 'paidupCapitalInTK' });

  const applicable = useWatch({ control, name: 'applicable' });

  useEffect(() => {
    setLicenseSection(applicable === 'Yes');
  }, [applicable]);

  const {
    mdName,
    msEmail,
    mdEmail,
    msPhoneNo,
    renewDate,
    msAddress,
    mdMobileNo,
    actualDate,
    msDistrict,
    licenseDate,
    msHoldingNo,
    expectedDate,
    msPoliceStation,
    mdOfficeTelephone,
    dateOfEstablishment,
    mdResidenceTelephone,
  } = defaultFields;

  useEffect(() => {
    if (
      chairmanName ||
      chairmanEmail ||
      chairmanMobileNo ||
      chairmanOfficeTelephone ||
      chairmanResidenceTelephone
    ) {
      if (
        mdName === chairmanName &&
        mdEmail === chairmanEmail &&
        mdMobileNo === chairmanMobileNo &&
        mdOfficeTelephone === chairmanOfficeTelephone &&
        mdResidenceTelephone === chairmanResidenceTelephone
      ) {
        setIsMdSame(true);
      }
    }

    if (hoName || millEmail || millPhoneNo || hoHouseNo || hoDistrict || hoPoliceStation) {
      if (
        msAddress === hoName &&
        msEmail === millEmail &&
        msPhoneNo === millPhoneNo &&
        msHoldingNo === hoHouseNo &&
        msDistrict === hoDistrict &&
        msPoliceStation === hoPoliceStation
      ) {
        setIsMsSame(true);
      }
    }
  }, []);

  useEffect(() => {
    if (isMdSame) {
      setValue('mdName', chairmanName);
      setValue('mdEmail', chairmanEmail);
      setValue('mdMobileNo', chairmanMobileNo);
      setValue('mdOfficeTelephone', chairmanOfficeTelephone);
      setValue('mdResidenceTelephone', chairmanResidenceTelephone);
    } else {
      setValue('mdName', '');
      setValue('mdEmail', '');
      setValue('mdMobileNo', '');
      setValue('mdOfficeTelephone', '');
      setValue('mdResidenceTelephone', '');
    }

    if (isMsSame) {
      setValue('msAddress', hoName);
      setValue('msEmail', millEmail);
      setValue('msDistrict', hoDistrict);
      setValue('msHoldingNo', hoHouseNo);
      setValue('msPhoneNo', millPhoneNo);
      setValue('msPoliceStation', hoPoliceStation);
    } else {
      setValue('msEmail', '');
      setValue('msAddress', '');
      setValue('msPhoneNo', '');
      setValue('msDistrict', '');
      setValue('msHoldingNo', '');
      setValue('msPoliceStation', '');
    }
  }, [
    isMdSame,
    isMsSame,
    chairmanName,
    chairmanEmail,
    chairmanMobileNo,
    chairmanOfficeTelephone,
    chairmanResidenceTelephone,
    hoName,
    millEmail,
    hoHouseNo,
    hoDistrict,
    millPhoneNo,
    hoPoliceStation,
    applicable,
  ]);

  useEffect(() => {
    if (authorizedCapitalInTK && conversions?.USD) {
      setValue(
        'authorizedCapitalInUS',
        Math.ceil(
          parseFloat(parseFloat(authorizedCapitalInTK) * parseFloat(conversions?.USD)).toFixed(2),
        ) || 0,
      );
    } else {
      setValue('authorizedCapitalInUS', parseFloat(authorizedCapitalInTK || 0).toFixed(2));
    }
    if (paidupCapitalInTK && conversions?.USD) {
      setValue(
        'paidupCapitalInUS',
        Math.ceil(
          parseFloat(parseFloat(paidupCapitalInTK) * parseFloat(conversions?.USD)).toFixed(2),
        ) || 0,
      );
    } else {
      setValue('paidupCapitalInUS', parseFloat(paidupCapitalInTK || 0).toFixed(2));
    }
  }, [authorizedCapitalInTK, paidupCapitalInTK]);

  const onSubmit = async (data) => {
    // no associated mills with this user, so make a post request
    if (_.isEmpty(defaultFields)) {
      const { data: responseData } = await postMembership(data);
      if (responseData) {
        setStep(currentStep + 1);
      }
    }
    // already an associated mill exists so patch request if get data and submitted data doesn't match
    else if (
      _.isEqual(data, {
        ...defaultFields,
        renewDate: new Date(renewDate),
        actualDate: new Date(actualDate),
        licenseDate: new Date(licenseDate),
        expectedDate: new Date(expectedDate),
        dateOfEstablishment: new Date(dateOfEstablishment),
      }) === false
    ) {
      const { data: responseData } = await patchMembership(GENERAL_INFORMATION_FORM_SECTION, {
        ...data,
        note,
      });
      if (responseData) {
        setStep(currentStep + 1);
      }
    }
    // no api call if get data and submitted data matches
    else {
      setStep(currentStep + 1);
    }
  };

  return (
    <MembershipFormWrapper onSubmit={handleSubmit(onSubmit)} formName="General Information">
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem colSpan={1}>
          <MillInformationSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <AddressSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <MillSiteSection
            errors={errors}
            control={control}
            register={register}
            autoFillValue={isMsSame}
            setAutoFillValue={() => setIsMsSame(!isMsSame)}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1}>
          <ChairmanInformationSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <ManagingDirectorSection
            errors={errors}
            control={control}
            register={register}
            autoFillValue={isMdSame}
            setAutoFillValue={() => setIsMdSame(!isMdSame)}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1}>
          <MillPresenterSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <DateOfCommencementProductionSection
            errors={errors}
            control={control}
            register={register}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <CapitalStructureSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <SourceOfCapitalSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1}>
          <MarketingFinisheProductSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <OtherInformationSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1} mt={4}>
          <Text fontSize="xl" fontWeight="bold">
            Power Management Information
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <DieselSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <GasSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <PowerManagementLeftOver register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <BondedWarehouseApplicableSection register={register} control={control} errors={errors} />
          {applicable === 'Yes' ? (
            <BondedWarehouseLicenseSection register={register} control={control} errors={errors} />
          ) : null}
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <Text>
            <b>Note: </b>
            {note}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
      </Grid>
      <Stack flexDir="column" alignItems="flex-end">
        <FormButton type="submit" isLoading={isSubmitting}>
          NEXT
        </FormButton>
      </Stack>
    </MembershipFormWrapper>
  );
};

export default GeneralInformationForm;
