import React from 'react';
import * as yup from 'yup';
import AuthForm from './AuthForm';
import { signup } from '../../api/auth';
import AuthWrapper from './AuthWrapper';
import { HStack, Text } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { ACCOUNT_VERIFICATION_URL, LOG_IN_URL } from '../../constants/routerUrl';
import { FULL_NAME_REGEX, PHONE_NO_REGEX, USERNAME_REGEX } from '../../constants/regex';
import { PASSWORD_MIN_LENGTH, USERNAME_MIN_LENGTH } from '../../constants/authConstant';

const SignUp = () => {
  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      const res = await signup(data);
      const { username, phone } = data;
      if (res.status === 200) {
        history.replace({
          pathname: ACCOUNT_VERIFICATION_URL,
          state: { username, phone: phone },
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const SignUpValidationSchema = yup.object().shape({
    fullname: yup
      .string()
      .matches(FULL_NAME_REGEX, 'Not a valid name')
      .required()
      .label('Full Name'),
    username: yup
      .string()
      .matches(USERNAME_REGEX, 'Not a valid username')
      .min(USERNAME_MIN_LENGTH)
      .required()
      .label('Username'),
    phone: yup
      .string()
      .matches(PHONE_NO_REGEX, 'Not a valid phone no. Try using +8801XXXXXXXXX/01XXXXXXXXX')
      .required()
      .label('Phone Number'),
    email: yup.string().email().required().label('Email Address'),
    password: yup.string().min(PASSWORD_MIN_LENGTH).required().label('Password'),
    confirm_password: yup
      .string()
      .min(PASSWORD_MIN_LENGTH)
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required()
      .label('Confirm Password'),
  });

  const formFields = [
    {
      colSpan: 1,
      id: 'fullname',
      name: 'fullname',
      isRequired: true,
      type: 'text',
      label: 'Full Name',
      placeholder: 'Enter your full name',
    },
    {
      colSpan: 1,
      id: 'username',
      name: 'username',
      isRequired: true,
      type: 'text',
      label: 'Username',
      placeholder: 'Enter a username',
    },
    {
      colSpan: 1,
      id: 'phone',
      name: 'phone',
      isRequired: true,
      type: 'tel',
      label: 'Phone Number',
      placeholder: 'Enter your phone number',
    },
    {
      colSpan: 1,
      id: 'email',
      name: 'email',
      isRequired: true,
      type: 'email',
      label: 'Email Address',
      placeholder: 'Enter your email address',
    },
    {
      colSpan: 1,
      id: 'password',
      name: 'password',
      isRequired: true,
      type: 'password',
      label: 'Password',
      placeholder: 'Enter your password',
    },
    {
      colSpan: 1,
      id: 'confirm_password',
      name: 'confirm_password',
      isRequired: true,
      type: 'password',
      label: 'Confirm Password',
      placeholder: 'Confim your password',
    },
  ];

  return (
    <AuthWrapper>
      <AuthForm
        formName="Signup"
        formFields={formFields}
        validationSchema={SignUpValidationSchema}
        formSubmitName="SIGNUP"
        onSubmit={onSubmit}
        additionalLink={
          <HStack>
            <Text mt={2}>Already have an account?</Text>
            <Link to={LOG_IN_URL}>
              <Text color="primary.700" mt={2}>
                Log in
              </Text>
            </Link>
          </HStack>
        }
      />
    </AuthWrapper>
  );
};

export default SignUp;
