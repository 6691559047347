import React from 'react';
import { useSelector } from 'react-redux';
import MembershipInfoWrapper from '../MembershipInfoWrapper';
import InfoSection from '../../../common/InfoSection';
import { renderDate } from '../../../../util/formatUtils';

const GeneralInormationSection = () => {
  const mill = useSelector((state) => state.mill.mill);

  const basicInformationFields = [
    { header: 'Represented By', value: mill?.millName },
    { header: 'Category', value: mill?.billingData?.summary?.category },
    { header: 'Membership Number', value: mill?.membershipNo },
    { header: 'Membership Type', value: mill?.membershipType },
  ];

  const headOfficeInformationFields = [
    {
      header: 'Address',
      value: mill?.hoInfo?.hoName,
    },
    { header: 'Website', value: mill?.website },
    { header: 'Email Address', value: mill?.hoInfo?.millEmail },
    { header: 'Phone Number', value: mill?.hoInfo?.millPhoneNo },
  ];

  const millSiteInformationFields = [
    { header: 'Factory Location', value: mill?.msInfo?.msAddress },
    { header: 'Email Address', value: mill?.msInfo?.msEmail },
    { header: 'Phone Number', value: mill?.msInfo?.msPhoneNo },
  ];

  const contactPersonInformationFields = [
    { header: 'Name of Managing Director', value: mill?.mdInfo?.mdName },
    { header: 'Managing Director Phone Number', value: mill?.mdInfo?.mdOfficeTelephone },
    { header: 'Name of Chairman', value: mill?.chairmanInfo?.chairmanName },
    { header: 'Chairman Phone Number', value: mill?.chairmanInfo?.chairmanOfficeTelephone },
    { header: 'Represented By', value: mill?.millPresentatorInfo?.mpName },
  ];

  const othersInformationFields = [
    { header: 'Establishment Date', value: renderDate(mill?.dateOfEstablishment) },
    { header: 'Year of Operation', value: mill?.yearOfOperation },
    { header: 'Cost of Project', value: mill?.otherInfo?.totalCostOfProject },
    { header: 'Equity/Sponsors', value: mill?.otherInfo?.sponsors },
    { header: 'Nature of Company', value: mill?.otherInfo?.natureOfCompany },
    {
      header: 'Main Manufacturing Activity of the mill',
      value: mill?.otherInfo?.mainManufactureActivity,
    },
    { header: 'Public Issue', value: mill?.otherInfo?.publicIssue },
    { header: 'Bank Finance (Paid Up Capital)', value: mill?.capitalStructure?.paidupCapitalInTK },
    { header: 'ETP', value: mill?.otherInfo?.etp },
    { header: 'Bonded Warehouse License No', value: mill?.bondedWarehouseLicenseNo?.licenseNo },
  ];

  return (
    <MembershipInfoWrapper name="General Information">
      <InfoSection sectionName="Basic Information" infoFields={basicInformationFields} />
      <InfoSection sectionName="Head Office Information" infoFields={headOfficeInformationFields} />
      <InfoSection sectionName="Mill Site Information" infoFields={millSiteInformationFields} />
      <InfoSection
        sectionName="Contact Person Information"
        infoFields={contactPersonInformationFields}
      />
      <InfoSection sectionName="Others Information" infoFields={othersInformationFields} />
    </MembershipInfoWrapper>
  );
};

export default GeneralInormationSection;
