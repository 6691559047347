import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import NavPanel from '../common/NavPanel';
import CertificateLog from './CertificateLog/CertificateLog';
import PurchaseCertificate from './PurchaseCertificate/PurchaseCertificate';
import ApplyCertificate from './ApplyForDutyExemption/ApplyCertificate';

const DutyExemption = () => {
  const [selected, setSelected] = useState(0);
  const navList = ['Duty Exemption Application', 'Application Fees', 'Duty Exemption Logs'];

  const renderTabs = () => {
    {
      switch (selected) {
        case 0:
          return <ApplyCertificate setNavState={setSelected} />;
        case 1:
          return <PurchaseCertificate />;
        case 2:
          return <CertificateLog />;
        default:
          return null;
      }
    }
  };

  return (
    <Box p={6} bg="secondary">
      <NavPanel navState={selected} setNavState={setSelected} navList={navList} />
      {/* {selected === 0 && <PurchaseCertificate />}
      {selected === 1 && <DutyExemptionForm setStep={setSelected} pl={6} />}
      {selected === 2 && <CertificateLog />} */}
      {renderTabs()}
    </Box>
  );
};

export default DutyExemption;
