export const PHONE_NO_REGEX = /^(?:\+88|01)?(?:\d{11}|\d{13})$/;
export const USERNAME_REGEX = /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/;
export const FULL_NAME_REGEX = /^[a-z A-Z]+$/;

/* password: min 8 chars, max 64 chars, one lowercase letter, one uppercase letter, one number, special chars (optional) */
export const PASSWORD_REGEX = (len) => {
  switch (len) {
    case 8:
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,64}$/;
    default:
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,64}$/;
  }
};
