import { Box, Stack, Text } from '@chakra-ui/react';
import React from 'react';

function Footer() {
  return (
    <Box
      mx="auto"
      bg="white"
      as="footer"
      maxH="80px"
      minH="60px"
      height="7vh"
      display="flex"
      role="contentinfo"
      alignItems="center"
      justifyContent="center">
      <Stack direction="row" align="center" justify="center" pr={5}>
        <Text fontSize="sm">&copy; Copyright by BTMA</Text>
      </Stack>
    </Box>
  );
}

export default Footer;
