import React from 'react';
import * as yup from 'yup';
import PurchaseSection from '../../../../common/certificateComponents/PurchaseFields';

const purchaseInformationFields = [
  {
    fieldType: 'radio',
    label: 'What type of certificate do you need?',
    colSpan: 1,
    id: 'radio',
    name: 'type',
    choices: [
      { name: 'Cash Assistance', value: 'Cash' },
      { name: 'GSP', value: 'GSP' },
    ],
    isRequired: true,
  },
  {
    colSpan: 1,
    id: 'numOfCert',
    label: 'How many certificates do you need?',
    name: 'numOfCert',
    isRequired: true,
    type: 'text',
  },
];

export const purchaseInformationValidation = {
  [purchaseInformationFields[0].name]: yup.string().required('please select a type of certificate'),
  [purchaseInformationFields[1].name]: yup.number().required(),
};

const PurchaseInformationSection = ({ register, control, errors }) => {
  return (
    <PurchaseSection
      formFields={purchaseInformationFields}
      register={register}
      control={control}
      errors={errors}
    />
  );
};

export default PurchaseInformationSection;
