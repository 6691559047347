import axios from './axios';

export const signup = async (data) => {
  const {
    fullname: fullName,
    username,
    phone: phoneNo,
    email,
    password,
    confirm_password: rePassword,
  } = data;
  const sendData = {
    fullName,
    username,
    phoneNo,
    email,
    password,
    rePassword,
  };

  return await axios.post('/auth/sign-up', sendData);
};

export const resendVerificationOtp = async (data) => {
  const { username, phoneNo } = data;
  return await axios.post(`/auth/otp-request/${username}/${phoneNo}`);
};

export const accountVerificationOtp = async (data) => {
  const { phone, username, otp: otpToken } = data;
  const sendData = { otpToken };
  return await axios.post(`auth/phone-verify/${username}/${phone}`, sendData);
};

export const login = async (data) => {
  return await axios.post('/auth/sign-in', data);
};

export const logout = async () => {
  return await axios.post('/auth/logout');
};

export const triggerPasswordReset = async (data) => {
  return await axios.post('/auth/password-reset/trigger', data);
};

export const verifyPasswordReset = async (data) => {
  return await axios.post(`/auth/password-reset/verify-otp/${data?.username}/${data?.phoneNo}`, {
    otpToken: data?.otpToken,
  });
};

export const resetPassword = async (data) => {
  return await axios.post(`/auth/password-reset/update/${data?.username}/${data?.phoneNo}`, {
    password: data?.password,
    otpToken: data?.otpToken,
  });
};

export const resetPasswordViaLink = async (data) => {
  return await axios.post('auth/reset-password', data);
};
