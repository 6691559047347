//modules
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Divider, Box, Grid, GridItem, Stack, useDisclosure } from '@chakra-ui/react';

//common components
import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import CheckBoxField from '../../../common/hookFormComponents/CheckBoxField';

//form sections
import ItemsInformation, {
  defaultItemFieldValues,
  itemsInformationValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/ItemsInformation';
import CerGeneralInformation, {
  generalInformationValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/CerGeneralInformation';
import MillInformationSection, {
  millInformationSectionValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/MillInformationSection';
import AdditionalDocumentsSection, {
  additionalDocumentsValidation,
} from '../../CompleteCertificates/CertificateApplicationForm/AdditionalDocumentsSection';

//utility & actions
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { patchProcessingCertificate } from '../../../../api/certificate';
import _ from 'lodash';

const ApplicationPatchForm = ({ refresh, closeModal }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [applicationData, setApplicationData] = useState();
  const data = useSelector((state) => state.certificate.currentCert);

  const validationSchema = yup.object().shape({
    ...itemsInformationValidation,
    ...generalInformationValidation,
    ...additionalDocumentsValidation,
    ...millInformationSectionValidation,
    agree: yup.bool().oneOf([true], 'This field must be checked'),
  });

  const getDefaultFiles = () => {
    return {
      amendments: data?.amendments,
      lcBankDocuments: data?.lcBankDocuments,
      proformaInvoice: data?.proformaInvoice,
      commercialInvoice: data?.commercialInvoice,
    };
  };

  const defaultFields = {
    //general information
    bank: data?.bank,
    btbLc: data?.btbLc,
    masterLc: data?.masterLc,
    dateOfLc: data?.dateOfLc,
    btbLcDate: data?.btbLcDate,
    bankBranch: data?.bankBranch,
    valueInUSD: data?.valueInUSD,
    totalQuantity: data?.totalQuantity,
    exciseGatePassNum: data?.exciseGatePassNum,
    exciseGatePassDate: data?.exciseGatePassDate,

    //invoice
    invoice: data?.invoice,
    invoiceDate: data?.invoiceDate,
    deliveryDate: data?.deliveryDate,

    //millInfo
    suppliedTo: data?.suppliedTo,
    producedBy: data?.producedBy,
    processedBy: data?.processedBy,
    videCertificateNo: data?.videCertificateNo,
    videCertificateDate: data?.videCertificateDate,
  };

  const resolver = useYupValidationResolver(validationSchema);
  const {
    watch,
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      items:
        data?.productDetails?.length > 0 ? [...data.productDetails] : [...defaultItemFieldValues],
    },
  });

  const watchAllFields = watch();

  const {
    // eslint-disable-next-line no-unused-vars
    agree,
    items,
    amendments,
    proformaInvoice,
    lcBankDocuments,
    commercialInvoice,
    ...rest
  } = watchAllFields;

  const isFileUpdated = () => {
    let fileUpdateFlag = false;
    let files = { lcBankDocuments, proformaInvoice, commercialInvoice, amendments };
    for (const key in files) {
      if (files?.[key]?.length > 0) {
        fileUpdateFlag = true;
      }
    }
    return fileUpdateFlag;
  };

  const checkUpdate = () => {
    return (
      _.isEqual({ ...rest }, { ...defaultFields }) &&
      _(items).differenceWith(data?.productDetails, _.isEqual).isEmpty() &&
      !isFileUpdated()
    );
  };

  const onSubmit = (formData) => {
    if (!checkUpdate()) {
      setApplicationData({ ...formData, product: data?.productType });
      onOpen();
    }
  };

  const confirmSubmit = async () => {
    const res = await patchProcessingCertificate(
      { ...applicationData },
      {
        certId: data.certificateNum,
      },
    );
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
    }
    closeModal();
  };

  return (
    <Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack bg="white" p={2} borderRadius="sm">
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <GridItem colSpan={2}>
              <CerGeneralInformation
                register={register}
                control={control}
                errors={errors}
                getValues={getValues}
              />
            </GridItem>
          </Grid>
        </Stack>

        <Stack bg="white" p={2} borderRadius="sm">
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <GridItem colSpan={4}>
              <ItemsInformation
                register={register}
                control={control}
                errors={errors}
                section={data?.productType === 'FABRIC' ? 'Fabric Information' : 'Yarn Information'}
              />
            </GridItem>
          </Grid>
        </Stack>

        <Stack bg="white" p={2} borderRadius="sm">
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <GridItem colSpan={2}>
              <MillInformationSection
                watch={watch}
                register={register}
                errors={errors}
                getValues={getValues}
                control={control}
              />
            </GridItem>
          </Grid>
        </Stack>

        <Stack bg="white" p={2} borderRadius="sm">
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <GridItem colSpan={2}>
              <Divider />
            </GridItem>
            <GridItem colSpan={2}>
              <AdditionalDocumentsSection
                watch={watch}
                register={register}
                errors={errors}
                getValues={getValues}
                defaultFiles={getDefaultFiles()}
              />
            </GridItem>
          </Grid>
        </Stack>

        <Stack bg="white" p={2} borderRadius="sm">
          <Divider />
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <GridItem colSpan={1} px={3}>
              <CheckBoxField
                control={control}
                errorMessage={errors['agree']?.message}
                name="agree"
                showText="I, the undersigned, Secretary General of Bangladesh Textile Mills Association (BTMA) Dhaka hereby certify that the above declaration was made before me. As far as it can be ascertained from documentary proof submitted, the above goods have been produced/manufactured in Bangladesh."
              />
            </GridItem>
          </Grid>
        </Stack>

        <Stack bg="white" flexDir="column" alignItems="flex-end" p={4}>
          <FormButton type="submit" isLoading={isSubmitting} isDisabled={checkUpdate()} mt={3}>
            Re-Submit
          </FormButton>
        </Stack>
      </Box>

      <DialogueBox
        mountControl={isOpen}
        close={onClose}
        alertTitle="Confirm"
        alertMessage="Are you sure you want to re-submit this application?"
        performAction={confirmSubmit}
      />
    </Box>
  );
};

export default ApplicationPatchForm;
