import React from 'react';
import {
  ModalHeader,
  ModalBody,
  Stack,
  Text,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import Loader from '../../../snippets/Loader';
import InfoSection from '../../../common/InfoSection';
import { renderDate } from '../../../../util/formatUtils';

const CertificatePreview = ({ data }) => {
  const generalInfoFields = [
    {
      header: 'Master L/C No.',
      value: data?.masterLc,
    },
    {
      header: 'Date of L/C',
      value: renderDate(data?.dateOfLc),
    },
    {
      header: 'Total Quantity & Item',
      value: data?.totalQuantity,
    },
    {
      header: 'Total Value (In US$)',
      value: data?.valueInUsd,
    },
    {
      header: 'Back to Back L/C No.',
      value: data?.btbLc,
    },
    {
      header: 'Date of Back to Back L/C',
      value: renderDate(data?.btbLcDate),
    },
    {
      header: 'Vat Challan No.',
      value: data?.exciseGatePassNum,
    },
    {
      header: 'Vat Challan Date',
      value: renderDate(data?.exciseGatePassDate),
    },
    {
      header: 'Name of L/C Opening Bank',
      value: data?.bank?.split(',')?.[0],
    },
    {
      header: 'Bank Branch',
      value: data?.bank?.split(',')?.[1],
    },
  ];

  const millInfoFields = [
    {
      header: 'Produced By',
      value: data?.producedBy,
    },
    {
      header: 'Processed By',
      value: data?.processedBy,
    },
    {
      header: 'Vide Certificate No',
      value: data?.videCertificateNo,
    },
    {
      header: 'Vide Certificate Date',
      value: renderDate(data?.videCertificateDate),
    },
    {
      header: 'Buyer',
      value: data?.suppliedTo,
    },
  ];

  return (
    <>
      <ModalHeader fontSize="lg" fontWeight="bold" textTransform="uppercase">
        <Text fontSize="lg" fontWeight="bold">
          Certificate of Processing
        </Text>
        <Divider />
      </ModalHeader>
      {data ? (
        <ModalBody>
          <InfoSection
            highlight
            infoFields={[{ header: 'Product Type', value: data?.productType }]}
          />
          <InfoSection sectionName="General Information" infoFields={generalInfoFields} />
          <InfoSection sectionName="Mill Information" infoFields={millInfoFields} />
          <Stack spacing={2} p={4} backgroundColor="white" borderRadius="6px">
            <Text fontSize="20px" fontWeight="bold" mb={2}>
              Product Details
            </Text>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Description</Th>
                  <Th>Quantity</Th>
                  <Th>Delivery Date</Th>
                  <Th>Invoice No.</Th>
                  <Th>Invoice Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.productDetails?.length > 0 &&
                  data?.productDetails?.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item?.product}</Td>
                      <Td>{item?.productQuantity}</Td>
                      <Td>{renderDate(item?.deliveryDate)}</Td>
                      <Td>{item?.invoice}</Td>
                      <Td>{renderDate(item?.invoiceDate)}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Stack>
        </ModalBody>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CertificatePreview;
