//modules
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Box, useDisclosure } from '@chakra-ui/react';

//common components
import Modal from '../../../common/BasicModal';
import CertificateRework from './CertificateRework';
import CertificatePreview from './CertificatePreview';
import ProcessingPrintPreview from './ProcessingPrintPreview';
import DataTable from '../../../common/certificateComponents/DataTable';

//utility & actions
import {
  FETCH_PROCESSING_CERTIFICATE,
  FETCH_PROCESSING_CERTIFICATE_LOGS,
} from '../../../../store/actions/actionTypes';
import {
  CERTIFICATE_LOGS_STATUS,
  LOGS_TABLE_DISPLAY_SIZE,
} from '../../../../constants/certificateConstant';
import { useDispatch, useSelector } from 'react-redux';
import { BADGE_STATE_COLORS } from '../../../../constants/dataTableConstants';
import { getProcessingCertificate, getProcessingCertLogs } from '../../../../api/certificate';

const CertificateLogTable = () => {
  const dispatch = useDispatch();
  const processingCertPrintRef = useRef();
  const [printData, setPrintData] = useState();
  const mill = useSelector((state) => state.mill.mill);
  const [modalComponent, setModalComponent] = useState();
  const data = useSelector((state) => state.certificate.processingCertLogs);
  const currentCert = useSelector((state) => state.certificate.currentCert);
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for modal

  //configure columns
  const columns = [
    {
      header: 'Date',
      accessor: 'usedOn',
      isDate: true,
    },
    {
      header: 'Number',
      accessor: 'certificateNum',
    },
    {
      header: 'Export Lc',
      accessor: 'masterLc',
    },
    {
      header: 'Value (In USD)',
      accessor: 'valueInUsd',
    },
    {
      header: 'Product',
      accessor: 'productType',
    },
    {
      header: 'Status',
      accessor: 'applicationStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['remarks'],
    },
    {
      header: 'Action',
      accessor: 'applicationStatus',
      isButton: true,
      actionScheme: [
        { group: 'APPLIED', variant: 'read', action: 'VIEW' },
        { group: 'IN REVIEW', variant: 'read', action: 'VIEW' },
        { group: 'APPROVED', variant: 'print', action: 'PRINT' },
        { group: 'REWORK', variant: 'write', action: 'EDIT' },
      ],
    },
  ];

  const handleView = async ({ rowObject }) => {
    setModalComponent(<CertificatePreview data={rowObject} />);
    onOpen();
  };

  const handleEdit = async ({ rowObject }) => {
    if (currentCert?.certificateNum != rowObject.certificateNum) {
      const res = await getProcessingCertificate(rowObject.certificateNum);
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_PROCESSING_CERTIFICATE, payload: res.data.certificate });
      }
    }
    setModalComponent(
      <CertificateRework
        close={onClose}
        updateTable={() => handleSearch({ page: 1, pageSize: 10 })}
      />,
    );
    onOpen();
  };

  const handlePagination = async ({ page, pageSize, searchKey, selected, startDate, endDate }) => {
    dispatch({ type: FETCH_PROCESSING_CERTIFICATE_LOGS, payload: null });
    const params = {
      page,
      pageSize,
      searchKey,
      status: selected?.['Application Status'],
      startDate,
      endDate,
    };
    const res = await getProcessingCertLogs(params);
    if (res.data) {
      dispatch({ type: FETCH_PROCESSING_CERTIFICATE_LOGS, payload: res.data });
    }
  };

  const handleSearch = async ({ searchKey, type, selected, startDate, endDate }) => {
    dispatch({ type: FETCH_PROCESSING_CERTIFICATE_LOGS, payload: null });
    const params = {
      searchKey,
      type,
      status: selected?.['Application Status'],
      startDate,
      endDate,
    };
    const res = await getProcessingCertLogs(params);
    if (res.data) {
      dispatch({ type: FETCH_PROCESSING_CERTIFICATE_LOGS, payload: res.data });
    }
  };

  const printProcessingCertificate = useReactToPrint({
    documentTitle: `BTMA-Processing-Certificate-${printData?.millName}-${printData?.certData?.certificateNum}`,
    content: () => processingCertPrintRef.current,
  });

  const handlePrint = ({ rowObject }) => {
    setPrintData({
      millName: mill?.millName,
      type: rowObject?.productType,
      certData: rowObject,
    });
    setTimeout(() => {
      printProcessingCertificate();
    }, 500);
  };

  //assign actions to each button
  const actionObject = {
    VIEW: handleView,
    EDIT: handleEdit,
    PRINT: handlePrint,
  };

  return (
    <Box mt={6}>
      <DataTable
        caption="Certificate Logs"
        columns={columns}
        data={data?.certificates}
        topPanel={true}
        actions={actionObject}
        isPaginated
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        entries={data?.totalEntries}
        currentPage={data?.page}
        currentPageSize={data?.pageSize}
        keySearch
        selectOptions={[CERTIFICATE_LOGS_STATUS]}
        dateRange
        paginate={handlePagination}
        search={handleSearch}
      />
      <Modal mountControl={isOpen} close={onClose}>
        {modalComponent}
      </Modal>
      <Box display="none">
        <ProcessingPrintPreview ref={processingCertPrintRef} data={printData} />
      </Box>
    </Box>
  );
};

export default CertificateLogTable;
