import React from 'react';
import { imageValidation } from '../../../util/yupUtils';
import FormSection from '../../common/hookFormComponents/FormSection';

const fileFields = [
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'employeeImage',
    type: 'file',
    label: 'Photo of Employee',
  },
];

export const imageFieldValidation = {
  [fileFields[0].name]: imageValidation.optional,
};

const LabourerImage = (props) => {
  return <FormSection sectionColumn={1} formFields={fileFields} {...props} />;
};

export default LabourerImage;
