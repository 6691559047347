import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import FormButton from '../../common/FormButton';
import DialogueBox from '../../common/DialogueBox';
import { addNewLabour } from '../../../api/labourer';
import { formatDate } from '../../../util/formatUtils';
import { notify } from '../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../util/yupUtils';
import LabourerImage, { imageFieldValidation } from './LabourerImage';
import { labourKeys } from '../../../constants/labourDatabaseConstant';
import { Box, Grid, GridItem, Stack, useDisclosure } from '@chakra-ui/react';
import LabourerInfoSection, { labourerInfoValidation } from './LabourerInfoSection';

const formValidatonSchema = yup.object().shape(
  {
    ...labourerInfoValidation,
    ...imageFieldValidation,
  },
  ['nid', 'birthCertNum'],
);

const AddLabourerForm = ({ refresh, closeModal }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [applicationData, setApplicationData] = useState();

  const resolver = useYupValidationResolver(formValidatonSchema);
  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  const onSubmit = (data) => {
    let formData = new FormData();
    for (let key in labourKeys) {
      labourKeys[key].dbKey === 'status'
        ? null
        : formData.append(
            labourKeys[key].dbKey,
            labourKeys[key].isDate
              ? formatDate(data?.[labourKeys[key].dbKey])
              : data?.[labourKeys[key].dbKey],
          );
    }
    formData.append('employeeImage', data?.employeeImage[0]);
    setApplicationData(formData);
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await addNewLabour(applicationData);
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
    }
    closeModal();
  };

  return (
    <>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack bg="white" p={4} borderRadius="sm">
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <GridItem colSpan={2}>
              <LabourerInfoSection register={register} control={control} errors={errors} />
              <LabourerImage
                watch={watch}
                errors={errors}
                control={control}
                setValue={setValue}
                register={register}
                sectionName="Upload Image (optional)"
              />
            </GridItem>
          </Grid>
        </Stack>

        <Stack bg="white" flexDir="column" alignItems="center" p={4}>
          <FormButton type="submit" isLoading={isSubmitting}>
            SUBMIT
          </FormButton>
        </Stack>
      </Box>

      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmSubmit}
        alertMessage="Are you sure you want submit these information"
      />
    </>
  );
};

export default AddLabourerForm;
