import React, { useEffect } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { useToast } from '@chakra-ui/toast';
import { useSelector } from 'react-redux';

import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

import PrivateRoute from './PrivateRoute';

// component attached to public route
import SignUp from '../components/auth/SignUp';
import LateAccountVerification from '../components/auth/LateAccountVerification';
import AccountVerficationOTP from '../components/auth/AccountVerficationOTP';
import Login from '../components/auth/Login';
import ForgetPassword from '../components/auth/ForgetPassword';
import ResetPasswordOTP from '../components/auth/ResetPasswordOTP';
import ResetPassword from '../components/auth/ResetPassword';

// component attached to private route
import DashBoardHome from '../components/dashboard/Home';
import Membership from '../components/membership/Membership';
import CertificateProduction from '../components/certificate-production/CertificateProduction';
import CertificateProcessing from '../components/certificate-processing/CertificateProcessing';
import DutyExemption from '../components/duty-exemption/DutyExemption';
import LabourDatabase from '../components/labourDatabase/LabourDatabase';
import VerificationRequestForm from '../components/externalVerification/VerificationRequestForm';
import DataPreview from '../components/externalVerification/DataPreview';
import Settings from '../components/settings/Settings';

// redux
import { logIn } from '../store/actions/authActions';
import { useDispatch } from 'react-redux';
import { getCurrentUserData } from '../util/localStorageUtils';

import {
  SIGN_UP_URL,
  ACCOUNT_VERIFICATION_URL,
  LOG_IN_URL,
  PASSWORD_RESET_URL,
  RESET_PASSWORD_URL,
  PASSWORD_RESET_VERIFICATION_URL,
  DASHBOARD_URL,
  SETTINGS_URL,
  MEMBER_DETAILS_URL,
  CERTIFICATE_PROCESSING_URL,
  CERTIFICATE_PRODUCTION_URL,
  DUTY_EXEMPTION_URL,
  ROOT_URL,
  LATE_ACCOUNT_VERIFICATION_URL,
  LABOUR_DATABASE_URL,
  EXTERNAL_VERIFICATION_URL,
  EXTERNAL_DATA_URL,
} from '../constants/routerUrl';
import ScrollToTop from './ScrollToTop';

const AppRouter = () => {
  const toast = useToast();
  const { notification } = useSelector((state) => state.global);

  useEffect(() => {
    const { id, title, description, status, duration, isClosable, position } = notification;
    if (title) {
      if (!toast.isActive(id) && description !== 'No mills asscociated with user!') {
        toast({
          id,
          title,
          description,
          status,
          duration,
          isClosable,
          position,
        });
      }
    }
  }, [notification]);

  const dispatch = useDispatch();
  const { user, accessToken } = getCurrentUserData();
  useEffect(async () => {
    if (user && accessToken) {
      dispatch(logIn(user));
    }
  }, []);

  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        {/*Public Routes*/}
        <Route exact path={ROOT_URL} render={() => <Redirect to={LOG_IN_URL} />} />
        <Route exact={true} path={LOG_IN_URL} component={Login} />
        <Route exact={true} path={SIGN_UP_URL} component={SignUp} />
        <Route
          exact={true}
          path={LATE_ACCOUNT_VERIFICATION_URL}
          component={LateAccountVerification}
        />
        <Route exact={true} path={PASSWORD_RESET_URL} component={ForgetPassword} />
        <Route exact={true} path={PASSWORD_RESET_VERIFICATION_URL} component={ResetPasswordOTP} />
        <Route exact={true} path={`${RESET_PASSWORD_URL}/:tokenId`} component={ResetPassword} />
        <Route exact={true} path={ACCOUNT_VERIFICATION_URL} component={AccountVerficationOTP} />
        <Route exact path={EXTERNAL_VERIFICATION_URL} component={VerificationRequestForm} />
        <Route exact path={EXTERNAL_DATA_URL} component={DataPreview} />

        {/*Private Routes*/}
        <PrivateRoute exact={true} path={DASHBOARD_URL} component={DashBoardHome} />
        <PrivateRoute exact={true} path={MEMBER_DETAILS_URL} component={Membership} />
        <PrivateRoute
          exact={true}
          path={CERTIFICATE_PRODUCTION_URL}
          component={CertificateProduction}
        />
        <PrivateRoute
          exact={true}
          path={CERTIFICATE_PROCESSING_URL}
          component={CertificateProcessing}
        />
        <PrivateRoute exact={true} path={DUTY_EXEMPTION_URL} component={DutyExemption} />
        <PrivateRoute exact={true} path={LABOUR_DATABASE_URL} component={LabourDatabase} />
        <PrivateRoute exact={true} path={SETTINGS_URL} component={Settings} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
