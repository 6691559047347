import React from 'react';
import { Flex, Stack, Spinner, Text } from '@chakra-ui/react';

const Loader = ({ internal }) => {
  return (
    <Flex
      minH={internal ? 'unset' : 'calc(100vh - 140px)'}
      flexDirection="column"
      backgroundColor="secondary"
      justifyContent="center"
      alignSelf="center"
      alignItems="center">
      <Stack flexDir="column" mb="12" mt="12" justifyContent="center" alignItems="center">
        <Spinner thickness="4px" emptyColor="primary.50" color="primary.700" size="xl" />
        <Text color="primary.700" fontSize="lg">
          Loading...
        </Text>
      </Stack>
    </Flex>
  );
};

export default Loader;
