import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 2,
    name: 'nameOfMillAndAddress',
    type: 'text',
    placeholder: 'Name & Address of the Mill',
    label: 'Produced By',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'annualProdCapacity',
    type: 'text',
    label: 'Annual Production/Manufacturing Capacity',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'suppliedTo',
    type: 'text',
    placeholder: 'Name & Address of the Mill',
    label: 'Supplied To',
    isRequired: true,
  },
];

export const millInformationSectionValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
};

const MillInformationSection = (props) => {
  return (
    <FormSection sectionName="Mill Information" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default MillInformationSection;
