export const ROOT_URL = '/';
export const LOG_IN_URL = '/login';
export const PASSWORD_RESET_URL = '/password-reset';
export const PASSWORD_RESET_VERIFICATION_URL = '/password-reset-otp';
export const RESET_PASSWORD_URL = '/reset-password';

export const EXTERNAL_VERIFICATION_URL = '/verification';
export const EXTERNAL_OTP_URL = '/verification/otp';
export const EXTERNAL_DATA_URL = '/verification/preview';

export const SIGN_UP_URL = '/signup';
export const LATE_ACCOUNT_VERIFICATION_URL = '/late-account-verification';
export const ACCOUNT_VERIFICATION_URL = '/account-verification-otp';

export const DASHBOARD_URL = '/dashboard';
export const SETTINGS_URL = '/settings';
export const MEMBER_DETAILS_URL = '/member-details';
export const CERTIFICATE_PRODUCTION_URL = '/certificate-production';
export const CERTIFICATE_PROCESSING_URL = '/certificate-processing';
export const DUTY_EXEMPTION_URL = '/exemption-certificate';
export const LABOUR_DATABASE_URL = '/labour-database';
