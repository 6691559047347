import React from 'react';
import { RadioGroup, Radio, Text, Box } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
const RadioField = ({ label, control, name, choices, errorMessage, defaultValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name, ref } }) => (
        <RadioGroup
          ref={ref}
          onChange={onChange}
          value={value}
          name={name}
          spacing={4}
          defaultValue={defaultValue}>
          <Box
            display="flex"
            flexDir="row"
            width="container.sm"
            justifyContent="space-between"
            alignItems="center">
            <Text fontSize="1xl" fontWeight="bold">
              {label}
            </Text>
            <Box
              display="flex"
              flexDir="row"
              alignItems="center"
              justifyContent="flex-end"
              h="40px">
              {choices.map((c, index) => (
                <Radio
                  ml="25px"
                  colorScheme="primary"
                  key={index}
                  value={c.value}
                  isChecked={c.value === defaultValue}>
                  {c.name}
                </Radio>
              ))}
            </Box>
          </Box>
          <Text color="invalid" fontSize="15px" mt="6px">
            {errorMessage}
          </Text>
        </RadioGroup>
      )}
    />
  );
};
export default RadioField;
