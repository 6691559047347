import React from 'react';
import { fileValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'declarationOne',
    type: 'file',
    label: 'Declaration -1 (PDF/JPG/JPEG-Max 5mb)',
    isRequired: true,
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'declarationThree',
    type: 'file',
    label: 'Declaration -3 (PDF/JPG/JPEG-Max 5mb)',
    isRequired: true,
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'proformaInvoice',
    type: 'file',
    label: 'Proforma Invoice (PDF/JPG/JPEG-Max 5mb)',
    isRequired: true,
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'lcBankDocuments',
    type: 'file',
    label: 'L/C Bank Documents (PDF/JPG/JPEG-Max 5mb)',
    isRequired: true,
  },
];

let obj1 = {};
let obj2 = {};

fields.forEach(
  (field, index) =>
    (obj1[field.name] = index === 0 ? fileValidation.required : fileValidation.optional),
);

fields.forEach((field) => (obj2[field.name] = fileValidation.optional));

export const cashAdditionalDocumentValidation = obj1;
export const cashAdditionalDocumentValidationAlt = obj2;

const CashAdditionalDocumentsSection = (props) => {
  return (
    <FormSection
      sectionName="Upload Additional Documents"
      sectionColumn={1}
      formFields={fields}
      {...props}
    />
  );
};

export default CashAdditionalDocumentsSection;
