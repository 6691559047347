import React from 'react';
import RadioField from './CertificateRadio';
import InputField from './CertificateInput';
import { Box, Grid, GridItem } from '@chakra-ui/react';

const renderField = (index, control, register, errors, field) => {
  const modifyErrorMessage = (message, name) => {
    let actualMessage = message;
    let modifiedMessage = '';
    if (actualMessage) {
      modifiedMessage = actualMessage.split(',')[0];
      modifiedMessage = modifiedMessage.replace(name, 'input value');
    }

    return modifiedMessage;
  };

  switch (field.fieldType) {
    case 'radio':
      return (
        <RadioField
          key={index}
          control={control}
          id={field.id}
          name={field.name}
          label={field.label}
          choices={field.choices}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
    default:
      return (
        <InputField
          key={index}
          id={field.id}
          name={field.name}
          label={field.label}
          type={field.type}
          isRequired={field.isRequired}
          register={register}
          isInvalid={errors[field.id]}
          errorMessage={modifyErrorMessage(errors[field.id]?.message, field.name)}
          placeholder={field.placeholder}
        />
      );
  }
};

function PurchaseFields({ formFields, control, register, errors }) {
  return (
    <Box>
      <Grid>
        {formFields.map((field, index) => (
          <GridItem colSpan={field.colSpan} key={index} mt={2} mb={2}>
            {renderField(index, control, register, errors, field)}
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
}

export default PurchaseFields;
