import React from 'react';
import { Stack, Grid, GridItem, Text, Stat, StatLabel, StatNumber, HStack } from '@chakra-ui/react';

const InfoSection = ({ highlight, sectionName, sectionColumn = 4, infoFields }) => {
  return (
    <Stack spacing={2} p={4} backgroundColor="white" borderRadius="6px">
      <Text fontSize="20px" fontWeight="bold" mb={2}>
        {sectionName}
      </Text>
      <Grid templateColumns={`repeat(${sectionColumn}, 1fr)`} gap={4}>
        {infoFields?.map((field, index) =>
          highlight ? (
            <GridItem key={index} overflowX="hidden">
              <Stat
                py={4}
                px={2}
                border="1px"
                borderColor="primary.300"
                borderRadius="md"
                bg="secondary">
                <HStack w="100%" justifyContent="space-between">
                  <StatLabel
                    fontWeight="bold"
                    fontSize="sm"
                    color="textSecondary"
                    whiteSpace="normal">
                    {field.header}
                  </StatLabel>

                  <StatNumber fontSize="xl" whiteSpace="normal">
                    {field.value || 'N/A'}
                  </StatNumber>
                </HStack>
              </Stat>
            </GridItem>
          ) : (
            <GridItem
              key={index}
              borderRadius="sm"
              p="3"
              w="100%"
              bg="secondary"
              overflowX="hidden">
              <Text fontSize="small" color="textSecondary" whiteSpace="normal">
                {field.header}
              </Text>
              <Text fontSize="md" whiteSpace="normal">
                {field.value || 'N/A'}
              </Text>
            </GridItem>
          ),
        )}
      </Grid>
    </Stack>
  );
};

export default InfoSection;
