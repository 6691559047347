import React, { useEffect, useState } from 'react';
import Loader from '../../../snippets/Loader';
import { getMembershipFormData } from '../../../../api/membership';
import TextileProductProcessorForm from './TextileProductProcessorForm';
import { TEXTILE_PRODUCT_PROCESSOR_FORM_SECTION } from '../../../../constants/membershipFormConstant';

const TextileProductProcessor = (props) => {
  const [note, setNote] = useState();
  const [defaultFields, setDefaultFields] = useState();

  useEffect(() => {
    (async () => {
      const { data: formData, error: getFormDataError } = await getMembershipFormData(
        TEXTILE_PRODUCT_PROCESSOR_FORM_SECTION,
      );
      if (formData) {
        if (formData.textileProductProcessors?.note) {
          const { note, ...rest } = formData.textileProductProcessors;
          setNote(note);
          setDefaultFields(rest || {});
        } else {
          setNote();
          setDefaultFields(formData.textileProductProcessors || {});
        }
      } else {
        console.error(getFormDataError);
      }
    })();
  }, []);

  return defaultFields ? (
    <TextileProductProcessorForm defaultFields={defaultFields} note={note} {...props} />
  ) : (
    <Loader />
  );
};

export default TextileProductProcessor;
