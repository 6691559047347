import React from 'react';
import ApplicationPatchForm from './ApplicationPatchForm';
import { ModalHeader, ModalBody, Text, Divider } from '@chakra-ui/react';

const CertificateRework = ({ close, updateTable }) => {
  return (
    <>
      <ModalHeader fontSize="lg" fontWeight="bold" textTransform="uppercase">
        <Text fontSize="lg" fontWeight="bold">
          Edit Application
        </Text>
        <Divider />
      </ModalHeader>
      <ModalBody>
        <ApplicationPatchForm closeModal={close} refresh={updateTable} />
      </ModalBody>
    </>
  );
};

export default CertificateRework;
