import React from 'react';
import { fileValidation } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'fireLicense',
    type: 'file',
    label: 'Fire License',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'tinCert',
    type: 'file',
    label: 'Tin Certificate',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'vatRegistration',
    type: 'file',
    label: 'Vat Registration',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'tradeLicense',
    type: 'file',
    label: 'Valid Trade License',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'groupInsurancePolicy',
    type: 'file',
    label: 'Group Insurance Policy',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'registrationCert',
    type: 'file',
    label: 'Registration Certificate',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'incorporationCert',
    type: 'file',
    label: 'Incorporation Certificate',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'moa',
    type: 'file',
    label: 'Memorandum of Association',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'leaseDocument',
    type: 'file',
    label: 'Lease Documents (If Rented)',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'utilityBill',
    type: 'file',
    label: 'Electricity/Gas Bill For Last 3 Months',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'environmentCert',
    type: 'file',
    label: 'Environment Certificate (related government organization)',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'machineryProcurement',
    type: 'file',
    label: 'Machinery Procurement Proof Documents (LC, Invoice, Bill of Entry)',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'difeCert',
    type: 'file',
    label: 'Certificate of the Department of Inspection for Factories and Establishments (DIFE)',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'managingDirector',
    type: 'file',
    label: 'Colored Image of The Managing Director',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'additional',
    type: 'file',
    label: 'Additional Document (If any)',
  },
];

export const additionalDocumentsValidation = {
  [fields[0].name]: fileValidation.optional,
  [fields[1].name]: fileValidation.optional,
  [fields[2].name]: fileValidation.optional,
  [fields[3].name]: fileValidation.optional,
  [fields[4].name]: fileValidation.optional,
  [fields[5].name]: fileValidation.optional,
  [fields[6].name]: fileValidation.optional,
  [fields[7].name]: fileValidation.optional,
  [fields[8].name]: fileValidation.optional,
  [fields[9].name]: fileValidation.optional,
  [fields[10].name]: fileValidation.optional,
  [fields[11].name]: fileValidation.optional,
  [fields[12].name]: fileValidation.optional,
  [fields[13].name]: fileValidation.optional,
  [fields[14].name]: fileValidation.optional,
};

const AdditionalDocumentsSection = (props) => {
  return (
    <FormSection
      sectionName="Upload Required Documents"
      sectionColumn={1}
      formFields={fields}
      {...props}
    />
  );
};

export default AdditionalDocumentsSection;
