import React, { useState, useEffect } from 'react';
import Loader from '../../../snippets/Loader';
import FabricManufacturerForm from './FabricManufacturerForm';
import { getMembershipFormData } from '../../../../api/membership';
import { FABRIC_MANUFACTURER_FORM_SECTION } from '../../../../constants/membershipFormConstant';

const FabricManufacturer = (props) => {
  const [note, setNote] = useState();
  const [defaultFields, setDefaultFields] = useState();

  useEffect(() => {
    (async () => {
      const { data: formData, error: getFormDataError } = await getMembershipFormData(
        FABRIC_MANUFACTURER_FORM_SECTION,
      );
      if (formData) {
        if (formData.fabricsManufactuer?.note) {
          const { note, ...rest } = formData.fabricsManufactuer;
          setNote(note);
          setDefaultFields(rest || {});
        } else {
          setNote();
          setDefaultFields(formData.fabricsManufactuer || {});
        }
      } else {
        console.error(getFormDataError);
      }
    })();
  }, []);

  return defaultFields ? (
    <FabricManufacturerForm note={note} defaultFields={defaultFields} {...props} />
  ) : (
    <Loader />
  );
};

export default FabricManufacturer;
