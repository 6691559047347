import React from 'react';
import { fileValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'amendments',
    type: 'file',
    isRequired: true,
    label: 'Amendments (PDF/JPG/JPEG-Max 5mb)',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'proformaInvoice',
    type: 'file',
    isRequired: true,
    label: 'Proforma Invoice (PDF/JPG/JPEG-Max 5mb)',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'commercialInvoice',
    type: 'file',
    isRequired: true,
    label: 'Commercial Invoice (PDF/JPG/JPEG-Max 5mb)',
  },
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'lcBankDocuments',
    type: 'file',
    isRequired: true,
    label: 'L/C Bank Documents (PDF/JPG/JPEG-Max 5mb)',
  },
];

let obj1 = {};
let obj2 = {};

fields.forEach(
  (field, index) =>
    (obj1[field.name] = index === 0 ? fileValidation.required : fileValidation.optional),
);

fields.forEach((field) => (obj2[field.name] = fileValidation.optional));

export const additionalReqDocumentsValidation = obj1;
export const additionalDocumentsValidation = obj2;

const AdditionalDocumentsSection = (props) => {
  return (
    <FormSection
      sectionName="Upload Additional Documents"
      sectionColumn={1}
      formFields={fields}
      {...props}
    />
  );
};

export default AdditionalDocumentsSection;
