import axios from './axios';

export const getLabourData = async (data) => {
  try {
    const res = await axios.get('/labour', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const addNewLabour = async (labourData) => {
  try {
    const res = await axios.post('/labour/new', labourData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const addLabourData = async (data) => {
  try {
    const res = await axios.post('/labour/', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateLabourData = async (data) => {
  try {
    const res = await axios.put(`/labour/${data?.labourId}`, data?.applicationData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteLabourData = async (data) => {
  try {
    const res = await axios.delete('/labour', { data });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
