import {
  SET_MILL,
  REMOVE_MILL,
  FETCH_TRANSACTION_HISTORY,
  FETCH_INFORMATION_UPDATE_HISTORY,
} from './actionTypes';

export const setMill = (mill) => ({
  type: SET_MILL,
  payload: {
    mill,
  },
});

export const removeMill = () => ({
  type: REMOVE_MILL,
});

export const FETCH_TRANSACTIONS = (data) => ({
  type: FETCH_TRANSACTION_HISTORY,
  payload: data,
});

export const FETCH_UPDATE_REQUESTS = (data) => ({
  type: FETCH_INFORMATION_UPDATE_HISTORY,
  payload: data,
});
