import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, HStack, Text } from '@chakra-ui/react';

const CheckBoxField = ({ control, id, name, showText, errorMessage }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field: { onChange, value, ref } }) => (
        <>
          <HStack alignItems="flex-start">
            <Checkbox
              mt={1}
              id={id}
              size="md"
              ref={ref}
              name={name}
              isChecked={value}
              onChange={onChange}
              colorScheme="primary"
            />
            <Text fontSize="sm" textAlign="justify">
              {showText}
            </Text>
          </HStack>
          <Text ml={6} mt={1.5} fontSize="sm" color="invalid">
            {errorMessage}
          </Text>
        </>
      )}
    />
  );
};
export default CheckBoxField;
