import React from 'react';
import { Grid, Button } from '@chakra-ui/react';

const NavPanel = ({ navState, setNavState, navList, disabledState, gap = 1 }) => {
  return (
    <Grid templateColumns={`repeat(${navList.length}, 1fr)`} gap={gap}>
      {navList.map((nav, index) => (
        <Button
          key={index}
          isActive={navState === index}
          isDisabled={disabledState === index}
          variant="tabBarTab"
          onClick={() => {
            setNavState(index);
          }}>
          {nav}
        </Button>
      ))}
    </Grid>
  );
};

export default NavPanel;
