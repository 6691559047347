import React from 'react';
import {
  Grid,
  Box,
  Text,
  Divider,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

const MembershipInfoWrapper = (props) => {
  return (
    <AccordionItem>
      <AccordionButton _expanded={{ bg: 'primary.50' }} _focus={{ boxShadow: 'none' }}>
        <Box flex="1" textAlign="left">
          <Text fontSize="xl" fontWeight="bold" pt={4} pb={4} pl={3}>
            {props.name}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <Divider />
      <AccordionPanel>
        <Grid templateColumns="repeat(1, 1fr)" gap={2} pl={4}>
          {props.children}
        </Grid>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default MembershipInfoWrapper;
