import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Box, Stack, Divider, Text, useDisclosure } from '@chakra-ui/react';

import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import PurchaseInformationSection, {
  purchaseInformationValidation,
} from './PurchaseInformatioSection/PurchaseInformationSection';

import {
  purchaseProcessingCertificate,
  getProcessingCertHistory,
} from '../../../../api/certificate';
import { useDispatch } from 'react-redux';
import { notify } from '../../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { FETCH_PROCESSING_CERTIFICATE_DATA } from '../../../../store/actions/actionTypes';

const PurchaseInformationForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for alert modal

  const validatonSchema = yup.object().shape({
    ...purchaseInformationValidation,
  });

  const resolver = useYupValidationResolver(validatonSchema);

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  //updates table upon new certificate
  const updateTable = async () => {
    const res = await getProcessingCertHistory({ page: 1 });
    if (res.data) {
      dispatch({ type: FETCH_PROCESSING_CERTIFICATE_DATA, payload: res.data });
    }
  };

  const onSubmit = (data) => {
    onOpen();
    setFormData(data);
  };

  const confirmSubmit = async () => {
    const res = await purchaseProcessingCertificate(formData);
    if (res.data) {
      setFormData({});
      reset({ numOfCert: null });
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      updateTable();
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} mt={6}>
      <Text fontSize="2xl">Purchase Certificate of Processing</Text>
      <Divider mb={2} />
      <Stack flexDir="row" bg="white" p={4} borderRadius="4px" maxW="680px">
        <PurchaseInformationSection register={register} errors={errors} control={control} />
      </Stack>
      <Stack flexDir="column" alignItems="start" mb={6}>
        <FormButton type="submit" isLoading={isSubmitting}>
          CONFIRM
        </FormButton>
      </Stack>

      {/* alert modal using chakra ui */}
      <DialogueBox
        mountControl={isOpen}
        close={onClose}
        alertTitle="Confirm"
        alertMessage={`Are you sure you want to purchase ${formData.numOfCert} certificates?`}
        performAction={confirmSubmit}
      />
    </Box>
  );
};

export default PurchaseInformationForm;
