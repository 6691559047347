import React from 'react';
import {
  MdHome,
  MdAccountCircle,
  MdSettings,
  MdInsertDriveFile,
  MdInsertChart,
  MdToys,
  MdSupervisorAccount,
} from 'react-icons/md';
import { Icon } from '@chakra-ui/react';
import * as routerUrl from '../constants/routerUrl';

export const sideNavConfig = [
  {
    key: 0,
    title: 'Home',
    url: routerUrl.DASHBOARD_URL,
    icon: <Icon as={MdHome} />,
  },
  {
    key: 1,
    title: 'Member Details',
    url: routerUrl.MEMBER_DETAILS_URL,
    icon: <Icon as={MdAccountCircle} />,
  },
  {
    key: 2,
    title: 'Certificate of Production',
    url: routerUrl.CERTIFICATE_PRODUCTION_URL,
    icon: <Icon as={MdInsertDriveFile} />,
  },
  {
    key: 3,
    title: 'Certificate of Processing',
    url: routerUrl.CERTIFICATE_PROCESSING_URL,
    icon: <Icon as={MdInsertChart} />,
  },
  {
    key: 4,
    title: 'Duty Exemption Certificate',
    url: routerUrl.DUTY_EXEMPTION_URL,
    icon: <Icon as={MdToys} />,
  },
  {
    key: 5,
    title: 'Labour Database',
    url: routerUrl.LABOUR_DATABASE_URL,
    icon: <Icon as={MdSupervisorAccount} />,
  },
  {
    key: 6,
    title: 'Settings',
    url: routerUrl.SETTINGS_URL,
    icon: <Icon as={MdSettings} />,
  },
];
