import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDutyCertLogs } from '../../../api/certificate';
import { FETCH_DUTY_CERTIFICATE_LOGS } from '../../../store/actions/actionTypes';
import CertificateLogTable from './CertificateLogTable/CertificateLogTable';

function LogsDutyExemption() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getDutyCertLogs({ page: 1, pageSize: 10 });
      if (res.data) {
        dispatch({ type: FETCH_DUTY_CERTIFICATE_LOGS, payload: res.data });
        FETCH_DUTY_CERTIFICATE_LOGS;
      }
    };

    fetchData();
  }, []);

  return <CertificateLogTable />;
}

export default LogsDutyExemption;
