export const CERTIFICATE_NAVIGATION_TABS = [
  'Request Certificate',
  'Complete Certificates',
  'Certificate Log',
];
export const LOGS_TABLE_DISPLAY_SIZE = [10, 20, 30, 40, 50];
export const CERTIFICATE_LOGS_STATUS = {
  name: 'Application Status',
  choices: [
    { name: 'APPLIED', value: 'APPLIED' },
    { name: 'IN REVIEW', value: 'IN REVIEW' },
    { name: 'REWORK', value: 'REWORK' },
    { name: 'APPROVED', value: 'APPROVED' },
    { name: 'REJECTED', value: 'REJECTED' },
  ],
};
export const CERTIFICATE_OF_PRODUCTION_TYPES = {
  name: 'Certificate Type',
  choices: [
    { name: 'CASH', value: 'CASH' },
    { name: 'GSP', value: 'GSP' },
  ],
};

export const DUTY_CERT_CATEGORIES = {
  A: 'Textile Spare Parts',
  B: 'Dyes Chemicals',
};

export const PRODUCTION_PRODUCT_TYPES = ['Yarn', 'Fabric'];
export const PROCESSING_PRODUCT_TYPES = ['Fabric', 'Textile'];
