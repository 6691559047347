import React from 'react';
import { Box } from '@chakra-ui/react';
import PurchaseHistoryTable from './PurchaseHistoryTable/PurchaseHistoryTable';
import PurchaseInformationForm from './PurchaseInformationForm/PurchaseInformationForm';

const PurchaseCertificate = () => {
  return (
    <Box>
      <PurchaseInformationForm />
      <PurchaseHistoryTable />
    </Box>
  );
};

export default PurchaseCertificate;
