import { NOTIFY } from './actionTypes';
import { SUCCESS } from '../../constants/toastConstants';

export const notify = ({
  title,
  description = null,
  status = SUCCESS,
  duration = 5000,
  isClosable = true,
  position = 'top',
}) => ({
  type: NOTIFY,
  payload: {
    title,
    description,
    status,
    duration,
    isClosable,
    position,
  },
});
