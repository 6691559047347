import React, { useState } from 'react';
import { Box, Grid, GridItem, Stack } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import AdditionalDocumentSection, {
  additionalDocumentValidation,
} from '../../ApplyForDutyExemption/sections/AdditionalDocumentSection';
import RequiredInformationSection, {
  requiredInfoFieldsValidation,
} from '../../ApplyForDutyExemption/sections/RequiredInformationSection';
import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import { formatDate } from '../../../../util/formatUtils';
import { patchDutyCertificate } from '../../../../api/certificate';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../../../store/actions/globalActions';

const DutyExemptionValidatonSchema = yup.object().shape({
  ...additionalDocumentValidation,
  ...requiredInfoFieldsValidation,
});

const ApplicationPatchForm = ({ refresh, closeModal }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for alert modal
  const [formData, setFormData] = useState({});
  const resolver = useYupValidationResolver(DutyExemptionValidatonSchema);
  const [alertString, setAlertString] = useState('');
  const [actionText, setActionText] = useState('');
  const [cancelText, setCancelText] = useState('');
  const [restrictEdit, setRestrictEdit] = useState(true);
  const data = useSelector((state) => state.certificate.currentCert);

  const defaultFields = {
    millName: data?.millName,
    millAddress: data?.millAddress,
    masterLc: data?.masterLc,
    dateOfLc: new Date(data?.dateOfLc),
    invoice: data?.invoice,
    invoiceDate: new Date(data?.invoiceDate),
    blNo: data?.blNo,
    blDate: new Date(data?.blDate),
  };

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm({
    resolver,
    defaultValues: { ...defaultFields },
  });

  const isDateUpdated = (formData) => {
    let dateUpdateFlag = false;
    for (const key in formData) {
      if (typeof formData[key]?.getMonth === 'function') {
        if (formatDate(formData[key]) !== formatDate(defaultFields[key])) {
          dateUpdateFlag = true;
        }
      }
    }
    return dateUpdateFlag;
  };

  const onSubmit = (formData) => {
    if (Object.keys(touchedFields).length > 0 || isDateUpdated(formData)) {
      setFormData(formData);
      setAlertString('Are you sure you want to re-submit this application?');
      setActionText('');
      setCancelText('');
      setRestrictEdit(false);
      onOpen();
    } else {
      setAlertString(
        'You did not edit any information, please edit your information and submit again.',
      );
      setActionText('Okay');
      setCancelText('Close');
      setRestrictEdit(true);
      onOpen();
    }
  };

  const confirmSubmit = async () => {
    const { millName, millAddress, masterLc, dateOfLc, invoice, invoiceDate, blNo, blDate } =
      formData;

    const sendData = {
      millName,
      millAddress,
      masterLc,
      dateOfLc: formatDate(dateOfLc),
      invoice,
      invoiceDate: formatDate(invoiceDate),
      blNo,
      blDate: formatDate(blDate),
    };

    const res = await patchDutyCertificate(sendData, data?.certificateId);
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
    }
    closeModal();
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack bg="white" p={3} mt={2} borderRadius="4px">
        <Grid templateColumns="repeat(1, 1fr)" colSpan={2} mt={1} ml={2} mr={2} gap={2}>
          <GridItem colSpan={1}>
            <RequiredInformationSection register={register} errors={errors} control={control} />
          </GridItem>
          <GridItem colSpan={1}>
            <AdditionalDocumentSection
              register={register}
              errors={errors}
              control={control}
              watch={watch}
            />
          </GridItem>
        </Grid>
      </Stack>
      <Stack flexDir="column" alignItems="flex-end">
        <FormButton type="submit" rightIcon={null} isLoading={isSubmitting}>
          Re-Submit
        </FormButton>
      </Stack>
      <DialogueBox
        mountControl={isOpen}
        close={onClose}
        alertTitle="Confirm"
        alertMessage={alertString}
        actionButtonText={actionText}
        cancelButtonText={cancelText}
        performAction={restrictEdit ? onClose : confirmSubmit}
      />
    </Box>
  );
};

export default ApplicationPatchForm;
