import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Input, FormControl, Text, Box, Tooltip } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerField.css';

const DatePickerField = ({ control, name, label, isInvalid, isRequired, errorMessage, width }) => {
  const [labelTooltip, setLabelTooltip] = useState(false);
  const [errorTooltip, setErrorTooltip] = useState(false);

  useEffect(() => {
    setLabelTooltip(
      document?.getElementById(name)?.offsetWidth < document?.getElementById(name)?.scrollWidth,
    );
    setErrorTooltip(
      document?.getElementById(`${name}err`)?.offsetWidth <
        document?.getElementById(`${name}err`)?.scrollWidth,
    );
  });
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl isInvalid={isInvalid}>
          <Box maxW={`${width}px`} overflow="hidden">
            <Tooltip label={labelTooltip ? label : ''} area-label="A Tooltip" placement="top-start">
              <Text
                mb={1.5}
                id={name}
                fontSize="sm"
                overflow="hidden"
                whiteSpace="nowrap"
                color="textSecondary"
                textOverflow="ellipsis">
                {isRequired ? <b style={{ color: '#E53E3E' }}>* </b> : null}
                {label}
              </Text>
            </Tooltip>
          </Box>

          <div className="customDatePickerWidth">
            <ReactDatePicker
              ref={(elem) => {
                elem && field.ref(elem.input);
              }}
              id="published-date"
              customInput={
                <Input
                  pr={20}
                  fontSize="md"
                  bg="formInputBG"
                  variant="outline"
                  borderRadius="sm"
                  focusBorderColor="primary.300"
                  h={['40px', '40px', '40px', '45px', '50px']}
                  inputref={field.ref}
                />
              }
              isClearable
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              showPopperArrow={false}
              dateFormat="dd/MM/yyyy"
              onChange={(e) => field.onChange(e)}
              selected={field.value ? new Date(field.value) : undefined}
            />
          </div>

          <Box maxW={`${width}px`} overflow="hidden">
            <Tooltip
              label={errorTooltip ? errorMessage : ''}
              area-label="A Tooltip"
              placement="bottom-start">
              <Text
                id={`${name}err`}
                mb={1.5}
                fontSize="xs"
                color="invalid"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis">
                {errorMessage}
              </Text>
            </Tooltip>
          </Box>
        </FormControl>
      )}
    />
  );
};
export default DatePickerField;
