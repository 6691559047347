import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Box, Text } from '@chakra-ui/react';

const TopNav = () => {
  const { mill } = useSelector((state) => state.mill);
  return (
    <Flex
      px={6}
      h="7vh"
      as="nav"
      w="100%"
      bg="white"
      maxH="80px"
      minH="50px"
      wrap="wrap"
      align="center"
      justify="space-between">
      <Box>
        <Text fontSize="lg" color="text">
          {mill?.millName}
        </Text>
      </Box>
    </Flex>
  );
};

export default TopNav;
