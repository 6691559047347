import axios from './axios';

export const getDashboardHome = async () => {
  try {
    const res = await axios.get('/mill');
    if (res.status === 200) return { data: res.data, error: null };
    if (res.status === 204) return { data: res, error: null };
  } catch (error) {
    return { data: null, error };
  }
};
