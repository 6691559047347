import { FETCH_LABOUR_DATA } from '../actions/actionTypes';

const initialState = {
  current: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LABOUR_DATA:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
}
