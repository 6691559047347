import React from 'react';
import { Grid, GridItem, Stack, Text, Divider, HStack, Button } from '@chakra-ui/react';

import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { useYupValidationResolver } from '../../../../util/yupUtils';

import _ from 'lodash';

// wrapper
import MembershipFormWrapper from '../../MembershipFormWrapper';
// Form Sections
import {
  SizingAndDesizing,
  SizingAndDesizingFieldsValidation,
  Shearing,
  ShearingFieldsValidation,
  ScouringAndBleaching,
  ScouringAndBleachingFieldsValidation,
  Drying,
  DryingFieldsValidation,
  Mercerizing,
  MercerizingFieldsValidation,
  Washing,
  WashingFieldsValidation,
  Stentering,
  StenteringFieldsValidation,
  Raising,
  RaisingFieldsValidation,
  Dyeing,
  DyeingFieldsValidation,
  Printing,
  PrintingFieldsValidation,
  Curing,
  CuringFieldsValidation,
  Steaming,
  SteamingFieldsValidation,
  JetDyeing,
  JetDyeingFieldsValidation,
  Jigger,
  JiggerFieldsValidation,
  Sanding,
  SandingFieldsValidation,
  Comfit,
  ComfitFieldsValidation,
  Sanforizing,
  SanforizingFieldsValidation,
  Decatizing,
  DecatizingFieldsValidation,
  Shrinkage,
  ShrinkageFieldsValidation,
  AnnualCapacitySection,
  AnnualCapacityFieldsValidation,
  RawMaterialRequirementSection,
  RawMaterialRequirementFieldsValidation,
  OtherRawMaterialInfoSection,
  OtherRawMaterialFieldsValidation,
  defaultOtherRawMaterialFieldValues,
} from './sections';

// membership api
import { patchMembership } from '../../../../api/membership';
import { TEXTILE_PRODUCT_PROCESSOR_FORM_SECTION } from '../../../../constants/membershipFormConstant';

// yup validation schema
const TextileProductProcessorsValidatonSchema = yup.object().shape({
  ...DryingFieldsValidation,
  ...DyeingFieldsValidation,
  ...CuringFieldsValidation,
  ...JiggerFieldsValidation,
  ...ComfitFieldsValidation,
  ...WashingFieldsValidation,
  ...RaisingFieldsValidation,
  ...SandingFieldsValidation,
  ...ShearingFieldsValidation,
  ...PrintingFieldsValidation,
  ...SteamingFieldsValidation,
  ...JetDyeingFieldsValidation,
  ...ShrinkageFieldsValidation,
  ...StenteringFieldsValidation,
  ...DecatizingFieldsValidation,
  ...MercerizingFieldsValidation,
  ...SanforizingFieldsValidation,
  ...AnnualCapacityFieldsValidation,
  ...OtherRawMaterialFieldsValidation,
  ...SizingAndDesizingFieldsValidation,
  ...ScouringAndBleachingFieldsValidation,
  ...RawMaterialRequirementFieldsValidation,
});

const TextileProductProcessorForm = ({ note, currentStep, setStep, defaultFields }) => {
  const resolver = useYupValidationResolver(TextileProductProcessorsValidatonSchema);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      otherRawMaterials: defaultFields.otherRawMaterials
        ? [...defaultFields.otherRawMaterials]
        : [{ ...defaultOtherRawMaterialFieldValues }],
    },
  });

  const onForwardSubmit = async (data) => {
    const cleanData = _.pickBy(data, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanData, cleanDefaultFields) === false) {
      await patchMembership(TEXTILE_PRODUCT_PROCESSOR_FORM_SECTION, { ...data, note });
      setStep(currentStep + 1);
    } else {
      setStep(currentStep + 1);
    }
  };

  const onBackwardSubmit = async (data) => {
    const cleanData = _.pickBy(data, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanData, cleanDefaultFields) === false) {
      await patchMembership(TEXTILE_PRODUCT_PROCESSOR_FORM_SECTION, { ...data, note });
      setStep(currentStep - 1);
    } else {
      setStep(currentStep - 1);
    }
  };

  return (
    <MembershipFormWrapper formName="Textile Product Processing">
      <Grid templateColumns="repeat(1, 1fr)" gap={2}>
        <GridItem colSpan={1}>
          <Text fontSize="xl" fontWeight="bold">
            Machineries
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <SizingAndDesizing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Shearing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <ScouringAndBleaching register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Drying register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Mercerizing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Washing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Stentering register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Raising register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Dyeing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Printing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Curing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Steaming register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <JetDyeing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Jigger register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Sanding register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Comfit register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Sanforizing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Decatizing register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Shrinkage register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1}>
          <AnnualCapacitySection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1}>
          <RawMaterialRequirementSection register={register} control={control} errors={errors} />
          <OtherRawMaterialInfoSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1}>
          <Text>
            <b>Note: </b>
            {note}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Divider />
        </GridItem>
      </Grid>
      <Stack flexDir="column" alignItems="flex-end">
        <HStack mt={3}>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onBackwardSubmit)();
            }}
            isLoading={isSubmitting}>
            PREVIOUS
          </Button>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onForwardSubmit)();
            }}
            isLoading={isSubmitting}>
            NEXT
          </Button>
        </HStack>
      </Stack>
    </MembershipFormWrapper>
  );
};

export default TextileProductProcessorForm;
