import React, { useEffect, useState } from 'react';
import { Box, Stack, Text, Badge, useDisclosure } from '@chakra-ui/react';
import InfoSection from '../../common/InfoSection';
import FormButton from '../../common/FormButton';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../snippets/Loader';
import DialogueBox from '../../common/DialogueBox';
import DataTable from '../../common/certificateComponents/DataTable';
import { membershipRequestStatus } from '../../../constants/membershipFormConstant';
import { BADGE_STATE_COLORS, LOGS_TABLE_DISPLAY_SIZE } from '../../../constants/dataTableConstants';
import { getMillTransactions, requestMembershipRenewal } from '../../../api/membership';
import { FETCH_TRANSACTIONS, setMill } from '../../../store/actions/millActions';
import { renderDate } from '../../../util/formatUtils';

import SubscriptionCost from '../ApplyForMembership/SubscriptionCost';
import { notify } from '../../../store/actions/globalActions';
import { getDashboardHome } from '../../../api/dashboard';

const MembershipRenewal = () => {
  const dispatch = useDispatch();
  const [renew, setRenew] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector((state) => state.mill);
  const trxData = useSelector((state) => state.mill?.transactions);

  useEffect(() => {
    let date = new Date(data?.mill?.membershipExpiryDate);
    let month = date.getMonth();
    date.setMonth(date.getMonth() - 1);

    if (date.getMonth() == month) date.setDate(0);

    if (new Date().setHours(0, 0, 0, 0) >= date.setHours(0, 0, 0, 0)) setRenew(true);
  }, [data?.mill]);

  const basicInformationFields = [
    { header: 'Represented By', value: data?.mill?.millName },
    { header: 'Category', value: data?.mill?.billingData?.summary?.category },
    { header: 'Membership No', value: data?.mill?.membershipNo },
    { header: 'Membership Type', value: data?.mill?.membershipType },
  ];

  const paymentSummaryFields = [
    { header: 'Expiry Date', value: renderDate(data?.mill?.membershipExpiryDate) },
    { header: 'Last Renewal', value: renderDate(data?.mill?.lastRenewalDate) },
    { header: 'Outstanding Due', value: `৳${data?.mill?.billingData?.costs?.previousDue}` },
    {
      header: 'Last Paid Amount',
      value: `৳${trxData?.allTrx?.[0]?.paidAmount || 0}`,
    },
  ];

  const columns = [
    { header: 'Trx Id', accessor: 'trxId' },
    { header: 'Date', accessor: 'createdAt', isDate: true },
    { header: 'Arrears', accessor: 'previousDue', currency: '৳' },
    { header: 'Current', accessor: 'currentAmount', currency: '৳' },
    { header: 'Total', accessor: 'totalReceivable', currency: '৳' },
    { header: 'Paid', accessor: 'paidAmount', currency: '৳' },
    { header: 'Due', accessor: 'currentDue', currency: '৳' },
    {
      header: 'Method',
      accessor: 'paymentMethod',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['chequeNo as ID', 'chequeDate as Date'],
    },
    { header: 'Remarks', accessor: 'remarks' },
    {
      header: 'Status',
      accessor: 'paymentStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
  ];

  const paymentStatus = {
    name: 'Payment Status',
    choices: [
      { name: 'PAID', value: 'PAID' },
      { name: 'UNPAID', value: 'UNPAID' },
    ],
  };

  const paymentMethod = {
    name: 'Payment Method',
    choices: [
      { name: 'CASH', value: 'CASH' },
      { name: 'CHEQUE', value: 'CHEQUE' },
    ],
  };

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate, selected }) => {
    dispatch(FETCH_TRANSACTIONS(null));
    const params = {
      page,
      pageSize,
      searchKey,
      startDate,
      endDate,
      status: selected?.['Payment Status'],
      paymentMethod: selected?.['Payment Method'],
    };
    const res = await getMillTransactions(params);
    if (res.data?.status === 200) {
      dispatch(FETCH_TRANSACTIONS(res.data));
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate, selected }) => {
    dispatch(FETCH_TRANSACTIONS(null));
    const params = {
      searchKey,
      startDate,
      endDate,
      status: selected?.['Payment Status'],
      paymentMethod: selected?.['Payment Method'],
    };
    const res = await getMillTransactions(params);
    if (res.data?.status === 200) {
      dispatch(FETCH_TRANSACTIONS(res.data));
    }
  };

  const handleSubmit = async () => {
    const res = await requestMembershipRenewal();
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      const { data } = await getDashboardHome();
      if (data) {
        dispatch(setMill(data.mill));
      }
    }
  };

  return data ? (
    data?.mill?.membershipStatus === membershipRequestStatus.APPROVED ? (
      <Box mt={6}>
        <Stack bg="white" borderRadius="4px" p={4}>
          <InfoSection sectionName="Membership Information" infoFields={basicInformationFields} />
        </Stack>

        <Box mt={6}>
          <InfoSection
            highlight
            sectionName="Payment Summary"
            sectionColumn={4}
            infoFields={paymentSummaryFields}
          />
        </Box>

        <Box mt={6}>
          <DataTable
            caption="Transaction History"
            columns={columns}
            data={trxData?.allTrx}
            topPanel
            isPaginated
            pageSizes={LOGS_TABLE_DISPLAY_SIZE}
            entries={trxData?.totalEntries}
            currentPage={trxData?.page}
            currentPageSize={trxData?.pageSize}
            keySearch
            dateRange
            selectOptions={[paymentStatus, paymentMethod]}
            paginate={handlePagination}
            search={handleSearch}
          />
        </Box>

        {data?.mill?.billingData?.summary?.renewalStatus === 'PENDING' ? (
          <Box mt={4}>
            <SubscriptionCost />
          </Box>
        ) : (
          <Box mt={6} position="relative">
            {!renew ? (
              <Badge left={2} colorScheme="blue" position="absolute" zIndex={2}>
                available 1 month prior to expiry
              </Badge>
            ) : null}
            <FormButton mt={2} isDisabled={!renew} onClick={onOpen}>
              REQUEST FOR MEMBERSHIP RENEWAL
            </FormButton>
          </Box>
        )}
        <DialogueBox
          mountControl={isOpen}
          alertTitle="Confirm"
          alertMessage="Are you sure you want to request for membership renewal?"
          close={onClose}
          performAction={handleSubmit}
        />
      </Box>
    ) : (
      <Box mt={6}>
        <Stack bg="white" borderRadius="4px" px={4} py={10}>
          <Text textAlign="center" fontSize="lg">
            Not an approved member yet. Membership renewal options are available for approved
            members only.
          </Text>
        </Stack>
      </Box>
    )
  ) : (
    <Loader />
  );
};

export default MembershipRenewal;
