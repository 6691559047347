export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';

export const SET_MILL = 'SET_MILL';
export const REMOVE_MILL = 'REMOVE_MILL';

export const FETCH_TRANSACTION_HISTORY = 'FETCH_TRANSACTION_HISTORY';
export const FETCH_INFORMATION_UPDATE_HISTORY = 'FETCH_INFORMATION_UPDATE_HISTORY';

export const FETCH_PRODUCTION_CERTIFICATE_DATA = 'FETCH_PRODUCTION_CERTIFICATE_DATA';
export const FETCH_PROCESSING_CERTIFICATE_DATA = 'FETCH_PROCESSING_CERTIFICATE_DATA';
export const FETCH_UNUSED_PRODUCTION_CERTIFICATES = 'FETCH_UNUSED_PRODUCTION_CERTIFICATES';
export const FETCH_UNUSED_PROCESSING_CERTIFICATES = 'FETCH_UNUSED_PROCESSING_CERTIFICATES';
export const FETCH_UNUSED_DUTY_CERTIFICATES = 'FETCH_UNUSED_DUTY_CERTIFICATES';
export const FETCH_PRODUCTION_CERTIFICATE_LOGS = 'FETCH_PRODUCTION_CERTIFICATE_LOGS';
export const FETCH_PRODUCTION_CERTIFICATE = 'FETCH_PRODUCTION_CERTIFICATE';
export const FETCH_PROCESSING_CERTIFICATE_LOGS = 'FETCH_PROCESSING_CERTIFICATE_LOGS';
export const FETCH_PROCESSING_CERTIFICATE = 'FETCH_PROCESSING_CERTIFICATE';

export const FETCH_DUTY_CERTIFICATE_LOGS = 'FETCH_DUTY_CERTIFICATE_LOGS';
export const FETCH_DUTY_CERTIFICATE = 'FETCH_DUTY_CERTIFICATE';
export const FETCH_DUTY_CERTIFICATE_ALL = 'FETCH_DUTY_CERTIFICATE_ALL';

export const FETCH_LABOUR_DATA = 'FETCH_LABOUR_DATA';

export const NOTIFY = 'NOTIFY';
