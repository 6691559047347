import React from 'react';
import { Box } from '@chakra-ui/react';

import DutyExemptionForm from './DutyExemptionForm';

const ApplyCertificate = ({ setNavState }) => {
  return (
    <Box>
      <DutyExemptionForm setNavState={setNavState} />
    </Box>
  );
};

export default ApplyCertificate;
