import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';
import {
  dateValidation,
  mobileNoValidation,
  websiteValidation,
} from '../../../../../util/yupUtils';

const fields = [
  {
    colSpan: 2,
    name: 'millName',
    type: 'text',
    isRequired: true,
    label: 'Name of the Mill',
  },
  {
    colSpan: 1,
    name: 'website',
    type: 'text',
    isRequired: true,
    label: 'Website Address',
  },
  {
    colSpan: 1,
    name: 'millEmail',
    type: 'email',
    isRequired: true,
    label: 'Email Address',
  },
  {
    colSpan: 1,
    name: 'millPhoneNo',
    type: 'text',
    isRequired: true,
    label: 'Phone',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'dateOfEstablishment',
    isRequired: true,
    label: 'Establishment Date',
  },
  {
    colSpan: 1,
    name: 'yearOfOperation',
    type: 'text',
    isRequired: true,
    label: 'Year of Operation',
  },
];

export const millInformationFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: websiteValidation.required().label(fields[1].label),
  [fields[2].name]: yup.string().email().required().label(fields[2].label),
  [fields[3].name]: mobileNoValidation.required().label(fields[3].label),
  [fields[4].name]: dateValidation.required().label(fields[4].label),
  [fields[5].name]: yup.string().required().label(fields[5].label),
};

const MillInformationSection = (props) => {
  return (
    <FormSection sectionName="Mill Information" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default MillInformationSection;
