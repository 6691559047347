import React, { useState } from 'react';
import * as yup from 'yup';
import AuthForm from './AuthForm';
import AuthWrapper from './AuthWrapper';
import { Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { LOG_IN_URL } from '../../constants/routerUrl';
import { PASSWORD_REGEX } from '../../constants/regex';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { notify } from '../../store/actions/globalActions';
import { resetPassword, resetPasswordViaLink } from '../../api/auth';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { tokenId } = useParams();
  const [showPass, setShowPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);

  const onSubmit = async (data) => {
    if (location?.state) {
      const { username, phoneNo, otp } = location.state;
      const sendData = {
        phoneNo,
        username,
        otpToken: otp,
        password: data.password,
      };
      try {
        const res = await resetPassword(sendData);
        if (res.status === 200) {
          dispatch(
            notify({
              title: 'Success',
              description: res.data.msg,
              status: 'success',
              duration: 5000,
            }),
          );
          history.replace(LOG_IN_URL);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const res = await resetPasswordViaLink({ tokenId, password: data.password });
        if (res.status === 200) {
          dispatch(
            notify({
              title: 'Success',
              description: res.data.msg,
              status: 'success',
              duration: 5000,
            }),
          );
          history.replace(LOG_IN_URL);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const ResetPasswordValidationSchema = yup.object().shape({
    password: yup
      .string()
      .matches(PASSWORD_REGEX(location?.state?.minPassLength), {
        message: `Password must be ${
          location?.state?.minPassLength || 8
        } to 64 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&)`,
      })
      .required(),
    rePassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required(),
  });

  const formFields = [
    {
      id: 'password',
      name: 'password',
      isRequired: true,
      label: 'New Password',
      type: showPass ? 'text' : 'password',
      placeholder: 'Enter your new password',
      rightElement: showPass ? (
        <ViewIcon onClick={() => setShowPass(false)} cursor="pointer" mt={1.5} />
      ) : (
        <ViewOffIcon onClick={() => setShowPass(true)} cursor="pointer" mt={1.5} />
      ),
    },
    {
      id: 'rePassword',
      name: 'rePassword',
      isRequired: true,
      label: 'Confirm Password',
      placeholder: 'Confirm new password',
      type: showRePass ? 'text' : 'password',
      rightElement: showRePass ? (
        <ViewIcon onClick={() => setShowRePass(false)} cursor="pointer" mt={1.5} />
      ) : (
        <ViewOffIcon onClick={() => setShowRePass(true)} cursor="pointer" mt={1.5} />
      ),
    },
  ];

  return (
    <AuthWrapper>
      <AuthForm
        columns={1}
        onSubmit={onSubmit}
        formSubmitName="RESET"
        formFields={formFields}
        formName="Reset Password"
        validationSchema={ResetPasswordValidationSchema}
        extraField={
          <Link to={LOG_IN_URL} style={{ maxWidth: 'fit-content' }}>
            <Text
              fontSize="sm"
              opacity={0.45}
              transition="0.5s"
              color="primary.700"
              _hover={{ opacity: 1 }}>
              Back to Login?
            </Text>
          </Link>
        }
      />
    </AuthWrapper>
  );
};

export default ResetPassword;
