import React from 'react';
import { fileValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fileFields = [
  {
    fieldType: 'file',
    colSpan: 1,
    name: 'additionalDocuments',
    type: 'file',
    isRequired: true,
    label: 'Additional Documents (PDF/JPG/JPEG-Max 5mb)',
  },
];

export const additionalDocumentValidation = {
  [fileFields[0].name]: fileValidation.required,
};

const AdditionalDocumentSection = (props) => {
  return (
    <FormSection
      sectionName="Upload Additional Documents"
      sectionColumn={1}
      formFields={fileFields}
      {...props}
    />
  );
};

export default AdditionalDocumentSection;
