import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'cottonYarn',
    type: 'text',
    label: 'Cotton Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'syntheticYarn',
    type: 'text',
    label: 'Synthetic Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'polyesterYarn',
    type: 'text',
    label: 'Polyester Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'viscousYarn',
    type: 'text',
    label: 'Viscous Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'tencelYarn',
    type: 'text',
    label: 'Tencel Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'flaxYarn',
    type: 'text',
    label: 'Flax Yarn (Tons)',
  },
];

export const RawMaterialFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[3].name]: yup.string(),
  [fields[4].name]: yup.string(),
  [fields[5].name]: yup.string(),
};

const RawMaterialSection = (props) => {
  return (
    <FormSection
      sectionName="Raw Material Requirement"
      sectionColumn={3}
      formFields={fields}
      {...props}
    />
  );
};

export default RawMaterialSection;
