import React from 'react';
import * as yup from 'yup';
import FormSection from '../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'certificateNum',
    type: 'text',
    isRequired: true,
    label: 'Certificate Num',
  },
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'certificateType',
    isRequired: true,
    label: 'Certificate Type',
    choices: [
      // { name: 'Production-CASH', value: 'CASH' },
      // { name: 'Production-GSP', value: 'GSP' },
      // { name: 'Processing', value: 'PROCESSING' },
      { name: 'Duty-Exemption', value: 'DUTY' },
    ],
  },
];

export const verificationFormFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
};

const VerificationFormSection = (props) => {
  return <FormSection secondary sectionColumn={1} formFields={fields} {...props} />;
};

export default VerificationFormSection;
