import React, { useEffect, useState } from 'react';
import AuthWrapper from './AuthWrapper';
import { verifyPasswordReset } from '../../api/auth';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { OTP_VALIDITY_TIME_IN_SECONDS } from '../../constants/authConstant';
import { PinInputField, PinInput, Text, Box, Button, HStack, Stack } from '@chakra-ui/react';
import { RESET_PASSWORD_URL, LOG_IN_URL, PASSWORD_RESET_URL } from '../../constants/routerUrl';

const OTPinput = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      history.replace(LOG_IN_URL);
    } else {
      const { username, phoneNo, minPassLength } = location.state;
      if (!username || !phoneNo || !minPassLength) {
        history.replace(LOG_IN_URL);
      }
    }
  }, []);

  const handleComplete = async (otp) => {
    const { username, phoneNo, minPassLength } = location.state;
    const data = { otpToken: otp, username, phoneNo };
    try {
      const res = await verifyPasswordReset(data);
      if (res.status === 200) {
        const { username, phoneNo } = res.data;
        history.replace({
          pathname: `${RESET_PASSWORD_URL}/u?0`,
          state: {
            otp,
            username,
            minPassLength,
            phoneNo: phoneNo[0] === '+' ? phoneNo.substring(3) : phoneNo,
          },
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // timer stuff start
  const [[minutes, seconds], setTime] = useState([
    parseInt(OTP_VALIDITY_TIME_IN_SECONDS / 60),
    OTP_VALIDITY_TIME_IN_SECONDS % 60,
  ]);
  const [otpValidity, setOtpValidity] = useState(true);

  const tickTimer = () => {
    if (minutes === 0 && seconds === 0) {
      setOtpValidity(false);
    } else if (seconds === 0) {
      setTime([minutes - 1, 59]);
    } else {
      setTime([minutes, seconds - 1]);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tickTimer(), 1000);
    return () => clearInterval(timerId);
  });
  // timer stuff end

  const handleTryAgain = () => {
    history.replace(PASSWORD_RESET_URL);
  };

  return (
    <AuthWrapper>
      <Stack spacing={4} p={10} backgroundColor="white" boxShadow="md" borderRadius="md">
        {otpValidity && (
          <>
            <Text fontSize="xl" color="textSecondary" textAlign="center">
              Enter the OTP sent to {location.state.phoneNo}
            </Text>
            <HStack justify="center">
              <PinInput
                otp
                autoFocus
                onComplete={handleComplete}
                focusBorderColor="primary.300"
                type="alphanumeric"
                size="lg">
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
              </PinInput>
            </HStack>
          </>
        )}
        <HStack justify="center">
          {otpValidity ? (
            <Text fontSize="2xl">
              {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
            </Text>
          ) : (
            <Stack>
              <Text fontSize="xl" textAlign="center">
                OTP Expired!
              </Text>
              <Box>
                <Text fontSize="xl" textAlign="center">
                  <Text color="primary.700">
                    <Button borderRadius={2} onClick={handleTryAgain}>
                      Try again
                    </Button>
                  </Text>
                </Text>
              </Box>
            </Stack>
          )}
        </HStack>

        <HStack mt={18} mb={6} justify="center">
          <Link to={LOG_IN_URL} style={{ maxWidth: 'fit-content' }}>
            <Text
              fontSize="sm"
              opacity={0.45}
              transition="0.5s"
              color="primary.700"
              _hover={{ opacity: 1 }}>
              Back to Login?
            </Text>
          </Link>
        </HStack>
      </Stack>
    </AuthWrapper>
  );
};

export default OTPinput;
