import React, { useRef, useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';
import Modal from '../../../common/BasicModal';
import DataTable from '../../../common/certificateComponents/DataTable';
import {
  // CERTIFICATE_LOGS_STATUS,
  LOGS_TABLE_DISPLAY_SIZE,
} from '../../../../constants/certificateConstant';
import CertificatePreview from './CertificatePreview';
import CertificateRework from './CertificateRework';
import { getDutyCertificate, getDutyCertLogs } from '../../../../api/certificate';
import {
  FETCH_DUTY_CERTIFICATE,
  FETCH_DUTY_CERTIFICATE_LOGS,
} from '../../../../store/actions/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { BADGE_STATE_COLORS } from '../../../../constants/dataTableConstants';
import CertificateBill from '../../../common/certificateComponents/CertificateBill';
import { renderDate } from '../../../../util/formatUtils';

function CertificateLogTable() {
  const dispatch = useDispatch();
  const [billData, setBillData] = useState();
  const [costData, setCostData] = useState();
  const certificateBillRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for modal
  const [modalComponent, setModalComponent] = useState();
  const data = useSelector((state) => state.certificate.dutyCertLogs);
  const mill = useSelector((state) => state.mill.mill);

  const checkUnassignedCert = () => {
    let certificates = data?.certificates;
    for (let cert of certificates) {
      if (cert.certificateNum === '0') {
        cert.certificateNum = 'Not Assigned';
      }
    }
    return certificates;
  };

  //configure columns
  const columns = [
    { header: 'Invoice', accessor: 'orderId' },
    {
      header: 'Date',
      accessor: 'issueDate',
      isDate: true,
    },
    {
      header: 'Certificate No',
      accessor: 'certificateNum',
    },
    {
      header: 'Total',
      accessor: 'total',
      currency: '৳',
    },
    {
      header: 'Discount',
      accessor: 'discount',
      currency: '৳',
    },
    {
      header: 'Dues',
      accessor: 'dues',
      currency: '৳',
    },
    {
      header: 'Adjustments',
      accessor: 'adjustments',
      currency: '৳',
    },
    {
      header: 'Payment',
      accessor: 'status',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['paymentMethod', 'chequeNo as ID', 'chequeDated as Date'],
    },
    {
      header: 'Status',
      accessor: 'applicationStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['remarks'],
    },
    {
      header: 'Action',
      accessor: 'applicationStatus',
      isButton: true,
      actionScheme: [
        { group: 'APPLIED', variant: 'read', action: 'VIEW' },
        { group: 'IN REVIEW', variant: 'read', action: 'VIEW' },
        { group: 'APPROVED', variant: 'print', action: 'PRINT' },
        { group: 'REWORK', variant: 'write', action: 'EDIT' },
      ],
    },
  ];

  const handleView = async ({ rowObject }) => {
    const res = await getDutyCertificate(rowObject.certificateId);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_DUTY_CERTIFICATE, payload: res.data.certificate });
    }
    setModalComponent(<CertificatePreview type="DUTY EXEMPTION" />);
    onOpen();
  };

  const handleEdit = async ({ rowObject }) => {
    const res = await getDutyCertificate(rowObject.certificateId);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_DUTY_CERTIFICATE, payload: res.data.certificate });
    }
    setModalComponent(
      <CertificateRework
        close={onClose}
        updateTable={() => handleSearch({ page: 1, pageSize: 10 })}
      />,
    );
    onOpen();
  };

  const handlePagination = async ({ page, pageSize, searchKey, selected, startDate, endDate }) => {
    dispatch({ type: FETCH_DUTY_CERTIFICATE_LOGS, payload: null });
    const params = {
      page,
      pageSize,
      searchKey,
      status: selected?.['Application Status'],
      startDate,
      endDate,
    };
    const res = await getDutyCertLogs(params);
    if (res.data) {
      dispatch({ type: FETCH_DUTY_CERTIFICATE_LOGS, payload: res.data });
    }
  };

  const handleSearch = async ({ searchKey, selected, startDate, endDate }) => {
    dispatch({ type: FETCH_DUTY_CERTIFICATE_LOGS, payload: null });
    const params = { searchKey, status: selected?.['Application Status'], startDate, endDate };
    const res = await getDutyCertLogs(params);
    if (res.data) {
      dispatch({ type: FETCH_DUTY_CERTIFICATE_LOGS, payload: res.data });
    }
  };

  const printBill = useReactToPrint({
    documentTitle: `BTMA-Bill-Duty-Exemption-Certificate-${renderDate(new Date())}`,
    content: () => certificateBillRef.current,
  });

  const handlePrint = ({ rowObject }) => {
    setBillData({
      billNo: rowObject?.orderId,
      millName: mill?.millName,
      millAddress: mill?.hoInfo?.hoName,
    });
    setCostData({
      certOf: 'duty',
      dutyCertCost: rowObject?.dutyCertCost,
      discount: rowObject?.discount,
      adjustments: rowObject?.adjustments,
    });
    setTimeout(() => {
      printBill();
    }, 500);
  };

  //assign actions to each button
  const actionObject = {
    VIEW: handleView,
    EDIT: handleEdit,
    PRINT: handlePrint,
  };

  return (
    <Box mt={6}>
      <DataTable
        caption="Certificate Logs"
        columns={columns}
        data={data ? checkUnassignedCert() : null}
        actions={actionObject}
        topPanel
        isPaginated
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        entries={data?.totalEntries}
        currentPage={data?.page}
        currentPageSize={data?.pageSize}
        keySearch
        // selectOptions={[CERTIFICATE_LOGS_STATUS]}
        dateRange
        paginate={handlePagination}
        search={handleSearch}
      />
      <Modal mountControl={isOpen} close={onClose}>
        {modalComponent}
      </Modal>
      <Box display="none">
        <CertificateBill
          ref={certificateBillRef}
          title="Certificate"
          data={billData}
          costs={costData}
        />
      </Box>
    </Box>
  );
}

export default CertificateLogTable;
