import {
  Box,
  Button,
  CloseButton,
  Divider,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

//common components
import DialogueBox from '../../../common/DialogueBox';
import FormButton from '../../../common/FormButton';
import CheckBoxField from '../../../common/hookFormComponents/CheckBoxField';
import SelectProductSection from './SelectProductSection';

//form sections
import CashAdditionalDocumentsSection, {
  cashAdditionalDocumentValidation,
} from './CashAdditionalDocuments';
import CashExpiryInformation, {
  cashExpInformationSectionValidation,
} from './CashExpiryInformation';
import CerGeneralInformation, { cerGenInfoSectionValidation } from './CerGeneralInformation';
import DocumentSection, { documentSectionValidation } from './DocumentSection';
import GspAdditionalDocumentsSection, {
  gspAdditionalDocumentValidation,
} from './GspAdditionalDocuments';
import GspExpiryInformation, { gspExpInformationSectionValidation } from './GspExpiryInformation';
import ItemsInformation, {
  defaultItemFieldValues,
  itemsInformationValidation,
} from './ItemsInformation';
import MillInformationSection, { millInformationSectionValidation } from './MillInformationSection';
import YarnCashAssistanceSection, {
  yarnCashAssistanceFieldsValidation,
} from './YarnCashAssistanceSection';

//utility & action
import _ from 'lodash';
import { applyForProductionCertificate } from '../../../../api/certificate';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import SelectCertificateSectionProduction from './SelectCertificateSectionProduction';
import SelectCertificateType from './SelectCertificateType';

const CertificateApplicationForm = ({ setNavState, refresh }) => {
  const toast = useToast();
  const toastIdRef = useRef();
  const [product, setProduct] = useState();
  const [certificate, setCertificate] = useState();
  const [certificateType, setCertificateType] = useState();
  const [certErrorMsg, setCertErrorMsg] = useState('');
  const [prodErrorMsg, setProdErrorMsg] = useState('');
  const [certTypeErrorMsg, setCertTypeErrorMsg] = useState('');
  const [applicationData, setApplicationData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for alert modal

  const getValidationSchema = () => {
    if (certificate?.type === 'CASH' && product === 'Yarn') {
      return yup.object().shape({
        ...documentSectionValidation,
        ...itemsInformationValidation,
        ...cerGenInfoSectionValidation,
        ...millInformationSectionValidation,
        ...cashAdditionalDocumentValidation,
        ...yarnCashAssistanceFieldsValidation,
        ...cashExpInformationSectionValidation,
        agree: yup.bool().oneOf([true], 'This field must be checked'),
      });
    } else if (certificate?.type === 'CASH') {
      return yup.object().shape({
        ...documentSectionValidation,
        ...itemsInformationValidation,
        ...cerGenInfoSectionValidation,
        ...millInformationSectionValidation,
        ...cashAdditionalDocumentValidation,
        ...cashExpInformationSectionValidation,
        agree: yup.bool().oneOf([true], 'This field must be checked'),
      });
    } else if (certificate?.type === 'GSP') {
      return yup.object().shape({
        ...documentSectionValidation,
        ...itemsInformationValidation,
        ...cerGenInfoSectionValidation,
        ...gspAdditionalDocumentValidation,
        ...millInformationSectionValidation,
        ...gspExpInformationSectionValidation,
        agree: yup.bool().oneOf([true], 'This field must be checked'),
      });
    }
  };

  useEffect(() => {
    if (certificate?.certNum && certificate?.type && product && certificateType) {
      setCertErrorMsg('');
      setProdErrorMsg('');
      setCertTypeErrorMsg('');
    } else if (product && !certificate?.certNum && !certificate?.type) {
      setProdErrorMsg('');
    } else if (certificate?.certNum && certificate?.type && !product) {
      setCertErrorMsg('');
    }
  }, [certificate, product, certificateType]);

  const resolver = useYupValidationResolver(getValidationSchema());
  const {
    watch,
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  useEffect(() => {
    setCertificate(null);
  }, [certificateType]);

  const onSubmit = (data) => {
    setApplicationData(data);
    onOpen();
  };

  const CustomToast = () => (
    <HStack color="white" p={3} bg="green.500" borderRadius="md">
      <Text>Successfully submitted!</Text>
      <Button
        variant="outline"
        fontSize="md"
        height="35px"
        _hover={{ bg: 'green.400' }}
        onClick={() => {
          setNavState(2);
          toast.closeAll();
        }}>
        View Details
      </Button>

      <CloseButton size="sm" onClick={() => toast.closeAll()} alignSelf="flex-start" />
    </HStack>
  );

  const showCustomToast = () => {
    toastIdRef.current = toast({
      position: 'bottom-right',
      duration: null,
      render: CustomToast,
    });
  };

  const confirmSubmit = async () => {
    const res = await applyForProductionCertificate(
      { ...applicationData, product },
      {
        certId: certificate?.certID,
      },
    );
    if (res.data?.status == 200) {
      let resetObj = {};
      _.mapKeys(applicationData, (value, key) => (resetObj[key] = ''));
      reset({
        ...resetObj,
        items: defaultItemFieldValues,
      });
      setProduct();
      setCertificate();
      showCustomToast();
      refresh();
    }
  };

  return (
    <Box>
      <Text fontSize="2xl" mt={6} mb={2}>
        Select Certificate & Product
      </Text>

      <Stack bg="white" pt={6} pb={4} px={4} borderRadius="sm" mb={4}>
        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
          <GridItem colSpan={2}>
            <HStack px={3} spacing={3}>
              <SelectCertificateType
                setCertificateType={setCertificateType}
                error={certTypeErrorMsg}
              />
              <SelectCertificateSectionProduction
                certificate={certificate}
                certificateType={certificateType}
                setCertificate={setCertificate}
                error={certErrorMsg}
              />
              <SelectProductSection setProductType={setProduct} error={prodErrorMsg} />
            </HStack>
          </GridItem>
        </Grid>
      </Stack>

      {certificate?.certID && product ? (
        <>
          <Text fontSize="2xl" mt={6} mb={2}>
            Certificate of Production Form
          </Text>

          <Box as="form" onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Stack bg="white" px={4} pb={4} borderRadius="sm">
                <Grid templateColumns="repeat(2, 1fr)" gap={2}></Grid>
              </Stack>

              <Stack bg="white" p={4}>
                <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                  <GridItem colSpan={2}>
                    <CerGeneralInformation register={register} control={control} errors={errors} />
                    {certificate?.type === 'GSP' ? (
                      <GspExpiryInformation register={register} control={control} errors={errors} />
                    ) : certificate?.type === 'CASH' ? (
                      <CashExpiryInformation
                        register={register}
                        control={control}
                        errors={errors}
                      />
                    ) : null}
                  </GridItem>
                </Grid>
              </Stack>

              <Stack bg="white" p={4}>
                <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                  <GridItem colSpan={2}>
                    <DocumentSection register={register} control={control} errors={errors} />
                  </GridItem>
                </Grid>
              </Stack>

              <Stack bg="white" p={4}>
                <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                  <GridItem colSpan={2}>
                    <ItemsInformation
                      sectionName={`${product} Information`}
                      register={register}
                      control={control}
                      errors={errors}
                    />
                  </GridItem>
                </Grid>
              </Stack>

              {certificate?.type === 'CASH' && product === 'Yarn' ? (
                <Stack bg="white" p={4}>
                  <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                    <GridItem colSpan={2}>
                      <YarnCashAssistanceSection
                        register={register}
                        control={control}
                        errors={errors}
                      />
                    </GridItem>
                  </Grid>
                </Stack>
              ) : null}

              <Stack bg="white" p={4}>
                <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                  <GridItem colSpan={2}>
                    <MillInformationSection register={register} errors={errors} control={control} />
                  </GridItem>
                </Grid>
              </Stack>

              {certificate?.type === 'CASH' ? (
                <Stack bg="white" p={4}>
                  <Divider />
                  <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                    <GridItem colSpan={2}>
                      <CashAdditionalDocumentsSection
                        watch={watch}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    </GridItem>
                  </Grid>
                </Stack>
              ) : certificate?.type === 'GSP' ? (
                <Stack bg="white" p={4}>
                  <Divider />
                  <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                    <GridItem colSpan={2}>
                      <GspAdditionalDocumentsSection
                        watch={watch}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    </GridItem>
                  </Grid>
                </Stack>
              ) : null}

              <Stack bg="white" p={4}>
                <Divider />
                <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                  <GridItem colSpan={1}>
                    <CheckBoxField
                      name="agree"
                      control={control}
                      errorMessage={errors['agree']?.message}
                      showText="I, the undersigned, Secretary General of Bangladesh Textile Mills Association (BTMA) Dhaka hereby certify that the above declaration was made before me. As far as it can be ascertained from documentary proof submitted, the above goods have been produced/manufactured in Bangladesh."
                    />
                  </GridItem>
                </Grid>
              </Stack>

              <Stack bg="white" flexDir="column" alignItems="flex-end" px={7} pb={7}>
                <FormButton type="submit" isLoading={isSubmitting} mt={3}>
                  SUBMIT
                </FormButton>
              </Stack>
            </Box>

            <DialogueBox
              mountControl={isOpen}
              close={onClose}
              alertTitle="Confirm"
              alertMessage="Are you sure you want to submit this application?"
              performAction={confirmSubmit}
            />
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default CertificateApplicationForm;
