import React from 'react';

import { Grid, GridItem, Stack, Divider, HStack, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { useYupValidationResolver } from '../../../../util/yupUtils';

import _ from 'lodash';

// wrapper
import MembershipFormWrapper from '../../MembershipFormWrapper';

// Form Sections
import {
  MachineInformationSection,
  defaultMachineInformationFieldValues,
  MachineInformationFieldsValidation,
  TypesOfFabricSection,
  TypesOfFabricFieldsValidation,
  defaultTypesOfFabricFieldValues,
  RawMaterialSection,
  RawMaterialFieldsValidation,
  OtherRawMaterialInfoSection,
  OtherRawMaterialFieldsValidation,
  defaultOtherRawMaterialFieldValues,
  AnnualCapacitySection,
  AnnualCapacityFieldsValidation,
} from './sections';

// Membership api
import { patchMembership } from '../../../../api/membership';
import { KNIT_FABRIC_FORM_SECTION } from '../../../../constants/membershipFormConstant';

// yup validation schema
const KnitFabricValidatonSchema = yup.object().shape({
  ...RawMaterialFieldsValidation,
  ...TypesOfFabricFieldsValidation,
  ...AnnualCapacityFieldsValidation,
  ...OtherRawMaterialFieldsValidation,
  ...MachineInformationFieldsValidation,
});

const KnitFabricForm = ({ currentStep, setStep, defaultFields }) => {
  const resolver = useYupValidationResolver(KnitFabricValidatonSchema);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      machineInfo: defaultFields.machineInfo
        ? [...defaultFields.machineInfo]
        : [{ ...defaultMachineInformationFieldValues }],
      typesOfFabric: defaultFields.typesOfFabric
        ? [...defaultFields.typesOfFabric]
        : [{ ...defaultTypesOfFabricFieldValues }],
      otherRawMaterials: defaultFields.otherRawMaterials
        ? [...defaultFields.otherRawMaterials]
        : [{ ...defaultOtherRawMaterialFieldValues }],
    },
  });

  const onForwardSubmit = async (data) => {
    const cleanData = _.pickBy(data, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanData, cleanDefaultFields) === false) {
      await patchMembership(KNIT_FABRIC_FORM_SECTION, data);
      setStep(currentStep + 1);
    } else {
      setStep(currentStep + 1);
    }
  };

  const onBackwardSubmit = async (data) => {
    const cleanData = _.pickBy(data, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanData, cleanDefaultFields) === false) {
      await patchMembership(KNIT_FABRIC_FORM_SECTION, data);
      setStep(currentStep - 1);
    } else {
      setStep(currentStep - 1);
    }
  };
  return (
    <MembershipFormWrapper formName="Knit Fabric Manufacturer">
      <Grid templateColumns="repeat(1, 1fr)" gap={2}>
        <GridItem colSpan={1}>
          <MachineInformationSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <AnnualCapacitySection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1}>
          <TypesOfFabricSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Divider />
        </GridItem>
        <GridItem colSpan={1}>
          <RawMaterialSection register={register} control={control} errors={errors} />
          <OtherRawMaterialInfoSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={1}>
          <Divider />
        </GridItem>
      </Grid>
      <Stack flexDir="column" alignItems="flex-end">
        <HStack mt={3}>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onBackwardSubmit)();
            }}
            isLoading={isSubmitting}>
            PREVIOUS
          </Button>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onForwardSubmit)();
            }}
            isLoading={isSubmitting}>
            NEXT
          </Button>
        </HStack>
      </Stack>
    </MembershipFormWrapper>
  );
};

export default KnitFabricForm;
