import React from 'react';
import { Grid, GridItem, Stack, Text, Divider, HStack, Button } from '@chakra-ui/react';

import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { useYupValidationResolver } from '../../../../util/yupUtils';

import _ from 'lodash';

// wrapper
import MembershipFormWrapper from '../../MembershipFormWrapper';

// Form Sections and Field Array Form Sections
import {
  InstalledCapacitySection,
  InstalledCapacityFieldsValidation,
  OtherInstalledCapacitySection,
  defaultOtherInstalledCapacityFieldValues,
  OtherInstalledCapacityFieldsValidation,
  BlowRoomSection,
  BlowRoomFieldsValidation,
  CardingSection,
  CardingFieldsValidation,
  DrawFrameSection,
  DrawFrameFieldsValidation,
  FlyFrameSection,
  FlyFrameFieldsValidation,
  RingFrameSection,
  RingFrameFieldsValidation,
  ConeWindingSection,
  ConeWindingFieldsValidation,
  ComberSection,
  ComberFieldsValidation,
  LapFormerSection,
  LapFormerFieldsValidation,
  AutoconeSection,
  AutoconeFieldsValidation,
  AnnualCapacitySection,
  AnnualCapacityFieldsValidation,
  RawMaterialRequirementInfoSection,
  RawMaterialRequirementInfoFieldsValidation,
  YarnTypeAndCountSection,
  YarnTypeAndCountFieldsValidation,
  defaultYarnTypeAndCountFieldValues,
  OtherRawMaterialInfoSection,
  defaultOtherRawMaterialFieldValues,
  OtherRawMaterialFieldsValidation,
} from './sections';

// Membershipi Api
import { patchMembership } from '../../../../api/membership';
import { YARN_MANUFACTURER_FORM_SECTION } from '../../../../constants/membershipFormConstant';

// Yup validationi schema
const YarnManufacturerValidatonSchema = yup.object().shape({
  ...ComberFieldsValidation,
  ...CardingFieldsValidation,
  ...BlowRoomFieldsValidation,
  ...FlyFrameFieldsValidation,
  ...AutoconeFieldsValidation,
  ...DrawFrameFieldsValidation,
  ...RingFrameFieldsValidation,
  ...LapFormerFieldsValidation,
  ...ConeWindingFieldsValidation,
  ...AnnualCapacityFieldsValidation,
  ...YarnTypeAndCountFieldsValidation,
  ...OtherRawMaterialFieldsValidation,
  ...InstalledCapacityFieldsValidation,
  ...OtherInstalledCapacityFieldsValidation,
  ...RawMaterialRequirementInfoFieldsValidation,
});

const YarnManufacturerForm = ({ note, currentStep, setStep, defaultFields }) => {
  const resolver = useYupValidationResolver(YarnManufacturerValidatonSchema);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      yarnTypeAndCount: defaultFields.yarnTypeAndCount
        ? [...defaultFields.yarnTypeAndCount]
        : [{ ...defaultYarnTypeAndCountFieldValues }],
      otherRawMaterials: defaultFields.otherRawMaterials
        ? [...defaultFields.otherRawMaterials]
        : [{ ...defaultOtherRawMaterialFieldValues }],
      otherInstalledCapacity: defaultFields.otherInstalledCapacity
        ? [...defaultFields.otherInstalledCapacity]
        : [{ ...defaultOtherInstalledCapacityFieldValues }],
    },
  });

  const onForwardSubmit = async (data) => {
    const cleanData = _.pickBy(data, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanData, cleanDefaultFields) === false) {
      await patchMembership(YARN_MANUFACTURER_FORM_SECTION, { ...data, note });
      setStep(currentStep + 1);
    } else {
      setStep(currentStep + 1);
    }
  };

  const onBackwardSubmit = async (data) => {
    const cleanData = _.pickBy(data, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanData, cleanDefaultFields) === false) {
      await patchMembership(YARN_MANUFACTURER_FORM_SECTION, { ...data, note });
      setStep(currentStep - 1);
    } else {
      setStep(currentStep - 1);
    }
  };

  return (
    <MembershipFormWrapper formName="Yarn Manufacturer">
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem colSpan={1}>
          <InstalledCapacitySection register={register} control={control} errors={errors} />
          <OtherInstalledCapacitySection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2} mt={4}>
          <Text fontSize="xl" fontWeight="bold">
            Machineries
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <BlowRoomSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <CardingSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <DrawFrameSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <FlyFrameSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <RingFrameSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <ConeWindingSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <ComberSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <LapFormerSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <AutoconeSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <AnnualCapacitySection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2} mt={4}>
          <Text fontSize="xl" fontWeight="bold">
            Main Product
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <YarnTypeAndCountSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <RawMaterialRequirementInfoSection
            register={register}
            control={control}
            errors={errors}
          />
          <OtherRawMaterialInfoSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
        <GridItem colSpan={2}>
          <Text>
            <b>Note: </b>
            {note}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
      </Grid>
      <Stack flexDir="column" alignItems="flex-end">
        <HStack mt={3}>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onBackwardSubmit)();
            }}
            isLoading={isSubmitting}>
            PREVIOUS
          </Button>
          <Button
            variant="formNext"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onForwardSubmit)();
            }}
            isLoading={isSubmitting}>
            NEXT
          </Button>
        </HStack>
      </Stack>
    </MembershipFormWrapper>
  );
};

export default YarnManufacturerForm;
